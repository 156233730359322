import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Organizador, Predio, Amistoso } from '../../models/interfaces.model';
import { PruebaService } from '../../services/abm.service';
import { Observable } from 'rxjs';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-nuevoamistoso',
  templateUrl: './nuevoamistoso.component.html',
  styleUrls: ['./nuevoamistoso.component.scss']
})
export class NuevoamistosoComponent implements OnInit {

  @Input() amistoso: Amistoso; // Recibimos amistoso por parámetro
  
  Tipos: string [] = ['Simplemente Inscribirse'];
  public Elegido: string;
  public organizadores: Observable<Organizador[]>;
  predios: Observable<Predio[]>;
  minDate = new Date();
  PhotoURL: string;
  add: boolean

  pruebaForm = this.fb.group(
    {
      fechaCreacion: [new Date()],
      ID: [''],
      id: [''],
      Abierto: [],
      Organizador: [],
      Juego: [''],
      Evento: [],
      Fecha:[],
      Predio: [],
      Deporte: [''],
      Sexo: [''],
      Contacto: [''],
      Telefono: [''],
      WhatsApp: [],
      PhotoURL: [''],
      Directo: []
    });
  valueDeporte: string;

  constructor(
    public pruebaSvc: PruebaService,
    public fb: UntypedFormBuilder,
    private dateAdapter: NgxMatDateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('es-AR');
   }

  ngOnInit() {
    this.add = true;
    this.organizadores = this.pruebaSvc.getOrganizadores();
    this.predios = this.pruebaSvc.getPredios('TN');
    
    this.iniciarForm();
  }

  addPrueba(amistoso: Amistoso) {
    const FormCompleto = Boolean(amistoso.Juego && amistoso.Organizador && (amistoso.Fecha || amistoso.Evento) && amistoso.Predio);
    if (!FormCompleto) {
      this.MensajePantallaError('Faltan Datos','Existen Errores en la carga de la información. Vuelva a cargarlo');
    } else {
        if (this.pruebaForm.get('id').value === '') {
          amistoso.PhotoURL = amistoso.Organizador.photoURL;
          amistoso.Organizador = amistoso.Organizador.nombre;
          amistoso.Fecha = amistoso.Evento;
          this.pruebaSvc.addAmistoso(amistoso);
        } else {
          amistoso.Evento = amistoso.Fecha;
          this.pruebaSvc.editAmistoso(amistoso);
        }
      }
    }

  iniciarForm() {
    if (this.amistoso){
      const today = moment(this.amistoso.Evento.toDate()).format('MM/DD/YYYY HH:mm');
      this.amistoso.Fecha = new Date(today);  
    }
    this.pruebaForm.patchValue({ ...this.amistoso});
  }

  async MensajePantallaError(titulo: string, texto: string){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'error',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { });
  }
  
}

