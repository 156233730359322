import { Component, OnInit, Input } from '@angular/core';
import { PruebaService } from 'src/app/services/abm.service';

@Component({
  selector: 'app-altacaptador',
  templateUrl: './altacaptador.component.html',
  styles: [
  ]
})
export class AltacaptadorComponent implements OnInit {

  @Input() data: any; // Recibimos una prueba por parámetro

  constructor(public abm: PruebaService) { 
    
  }

  ngOnInit() {
    console.log(this.data);
    const clubes = this.abm.getClubes();
  }

}
