import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PruebaService } from '../../services/abm.service';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { Amistoso } from '../../models/interfaces.model';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { DialogtenisamistosoComponent } from '../shared/dialogtenisamistoso/dialogtenisamistoso.component';

@Component({
  selector: 'app-tabla-tenis-amistosos',
  templateUrl: './tabla-tenis-amistosos.component.html',
  styleUrls: ['./tabla-tenis-amistosos.component.scss']
})
export class TablaTenisAmistososComponent implements OnInit {

  displayedColumns: string[] = ['Abierto', 'Juego', 'Organizador', 'Fecha', 'Tipo de Deporte', 'Predio', 'Acciones','Activo','Inscriptos'];
  dataSource = new MatTableDataSource();
  isLoading = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  items: AngularFirestoreCollection<any>;
  public tabla: any;
  public estado: boolean;
  TitAmistoso: string;
  tipoentidad: string;
  perfiladmin: boolean;

  constructor(
    private amistosoSvc: PruebaService,
    public dialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore
    ) { }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Amistosos por página';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  ngOnInit() {
    this.amistosos(true,false);
    this.estado = true;
  }

  amistosos(estado: boolean,vencidos: boolean){
    if (estado && !vencidos) {
      this.TitAmistoso = 'Amistosos Activos';
    } else if (!estado && !vencidos) {
      this.TitAmistoso = 'Amistosos Inactivos';
    } else if (vencidos){
      this.TitAmistoso = 'Amistosos Vencidos en el tiempo';
    }
    this.dataSource.data = [];
    this.amistosoSvc.getAmistosos(estado,vencidos).subscribe(
      amistosos => {
        amistosos.forEach(amistoso => {
          this.items = this.afs.collection<any>(`Amistosos/${amistoso.ID}/Anotados`);
          this.tabla = this.items.valueChanges().subscribe(
            resp => {
              amistoso.Inscriptos = resp.length;
            }
          );
        }); 
        this.isLoading = false;
        this.dataSource.data = amistosos;
        this.estado = estado; 
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editar(amistoso: Amistoso) {
    this.openDialog(amistoso);
  }

  inscriptos(amistoso: Amistoso) {
    this.listadoinscriptos(amistoso.id);
  }

  borrar(amistoso: Amistoso) {
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Esta accion no se puede revertir',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then(
      aceptar => {
        if (aceptar.value) {
          this.amistosoSvc.deleteAmistoso(amistoso)
          .then( () => {
            Swal.fire('Amistoso eliminado', 'El amistoso ha sido eliminado', 'success');
          })
          .catch((err) => {
            Swal.fire('Error!', 'El amistoso no ha sido eliminado', 'error');
          });
        }
      });
  }

  openDialog(amistoso?: Amistoso): void {
    const config = {
      data: {
        message: amistoso ? 'Editar Amistoso' : 'Nuevo Amistoso',
        content: amistoso
      }
    };
    const dialogRef = this.dialog.open(DialogtenisamistosoComponent, config);
    dialogRef.afterClosed().subscribe(
      res => { }
    );
  }

  listadoinscriptos(id: string){
    this.router.navigateByUrl(`tabla-inscriptos-tenis/${id}`);
  }

  activar(amistoso: Amistoso){
    this.amistosoSvc.estadoAmistoso(amistoso, amistoso.Activo);
  }

  nuevoAmistoso() {
    this.openDialog();
  }

  genqr(amistoso: Amistoso){
    let predio = amistoso.Predio.replace(/()\s/g,'€');
    let datos = `amistoso_Cancha@${predio}_${amistoso.ID}`;
    this.router.navigateByUrl(`gqr/${datos}`);
  }

}
