<main id="home-producto" class="producto-centros-entrenamiento">

    <section class="hero is-fullheight">
        <div class="container is-marginless">
            <div class="column is-6-desktop is-5-widescreen is-offset-1">
                <h1 class="title has-text-white">Centros de entrenamiento, academias, centro de captación de talentos y escuelas de fútbol</h1>
                <h2 class="is-size-1 has-text-white">
                    ¿Quieres qué todos los jugadores de fútbol conozcan tu centro?
                </h2>
            </div>
        </div>
    </section>

    <section class="section">
        <div class="container is-fluid">

            <div class="beneficios-section">
                <div class="columns is-multiline is-centered">
                    <div class="column is-full has-text-centered">
                        <h3>¿CUALES SON LOS BENEFICIOS?</h3>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../../assets/images/icon-mdp_CE_01.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">Promociona tu espacio</h5>
                                        <p class="is-size-5">Tendrás el beneficio de realizar un anuncio gratuito mensual, seleccionando el público objetivo al cual quieres dirigirte.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../../assets/images/icon-mdp_CE_02.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">Ofrece jugadores</h5>
                                        <p class="is-size-5">Podrás subir las fichas técnicas de jugadores con sus características, adjuntar videos y fotos. ¡Esto te permitirá llegar a clubes de todo el mundo!</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../../assets/images/icon-mdp_CE_03.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">Capta talentos</h5>
                                        <p class="is-size-5">Mercado de Pases te brinda la posibilidad de crear alianzas estratégicas con clubes profesionales, con captadores de talentos y representantes de futbolistas. Te vinculamos de forma directa y sencilla.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../../assets/images/icon-mdp_CE_04.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">Inscripciones de jugadores</h5>
                                        <p class="is-size-5">A través de nuestro sistema único, nuestros usuarios registrados podrán inscribirse en el centro de entrenamiento o escuela de fútbol que seleccionen. ¡Por este motivo es importante qué sumes tu espacio a Mercado
                                            de Pases!</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../../assets/images/icon-mdp_CE_05.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">Pruebas de jugadores</h5>
                                        <p class="is-size-5">Podrás anunciar, promocionar y difundir la pruebas de jugadores que organices, incluyendo los filtros que consideres necesarios.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../../assets/images/icon-mdp_CE_06.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">Partidos amistosos</h5>
                                        <p class="is-size-5">Podrás organizar partidos de carácter amistoso con otros centros y clubes del mundo. La vinculación se genera de modo automático y a través de nuestro chat privado.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</main>