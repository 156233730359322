import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { ActivatedRoute } from '@angular/router';
import { AlertasService } from 'src/app/services/alertas.service';
import { Mensaje } from 'src/app/models/chat.model';
import Swal from 'sweetalert2';
import { PruebaService } from 'src/app/services/abm.service';
import { Beneficiados } from 'src/app/models/interfaces.model';

@Component({
  selector: 'app-tabla-beneficiados',
  templateUrl: './tabla-beneficiados.component.html',
  styleUrls: ['./tabla-beneficiados.component.scss']
})
export class TablaBeneficiadosComponent implements OnInit {

  private items: AngularFirestoreCollection<any>;
  public itembenef: AngularFirestoreCollection<Beneficiados>;
  public itemusu: AngularFirestoreCollection<any>
  tabla: any;
  tablausu: any;
  displayedColumns: string[] = ['usuario','nombre','apellido', 'tipodoc','nrodoc', 'nacionalidad','telefono', 'fechanac', 'aceptado', 'idbeneficiado'];
  dataSource = new MatTableDataSource();
  isLoading = true;
  public id: string;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  perfilActividad: string;
  perfilPuesto: string;
  Titulo: string;
  perfilDeporte: any;
  categoria: any;
  perfilsexo: any;
    
    constructor  ( private afs: AngularFirestore,
                   public alertaSvc: AlertasService,
                   private route: ActivatedRoute,
                   private svcabm: PruebaService
                  ) {}
                
  
    ngAfterViewInit() {
      this.paginator._intl.itemsPerPageLabel = 'Usuarios por página';
      this.dataSource.paginator = this.paginator;
      this.dataSource.data.splice(0);
      this.dataSource.sort = this.sort;
    }
  
    ngOnInit() {
      this.id = this.route.snapshot.paramMap.get('id');
      this.items = this.afs.collection('Beneficiados', ref => ref
                                                  .where('idbi','==',this.id)
                                                  .orderBy('usuario', 'desc'));
      this.Titulo = `Beneficio ID: ${this.id}`;
      this.items.valueChanges().subscribe(
        usuarios => {
          let i = 0;
          usuarios.forEach(usuario => {
            this.itemusu = this.afs.collection<Mensaje>('Usuarios', ref => ref
                                                .where('correoelectronico','==', usuario.usuario));
            this.tablausu = this.itemusu.valueChanges().subscribe(
              respusu => {
                if (respusu.length > 0) {
                  this.itembenef = this.afs.collection<Beneficiados>('Beneficiados', ref => ref
                                                      .where('idbi','==', usuario.idbi)
                                                      .where('usuario','==', usuario.usuario));
                  this.tabla = this.itembenef.snapshotChanges().subscribe(
                    resp => {
                      usuario.idbeneficiado = resp[0].payload.doc.ref.id;
                    }
                  );
                  usuario.usuario = respusu[0].correoelectronico;
                  usuario.nombre = respusu[0].nombre;
                  usuario.apellido = respusu[0].apellido;
                  usuario.tipodoc = respusu[0].tipodoc;
                  usuario.nrodoc = respusu[0].nrodoc;
                  usuario.nacionalidad = respusu[0].nacionalidad;
                  usuario.telefono = respusu[0].telefono;
                  usuario.fechanac = respusu[0].fechanac;
                  usuarios.splice(0 , 1);
                  usuarios.push(usuario);
                } 
              }
            );                           
            i = i + 1;
          });
          // if (this.tabla){
          //   this.tabla.unsubscribe();
          //   this.tablausu.unsubscribe();
          // }
          setTimeout(() => {
            this.isLoading = false;
            this.dataSource.data = usuarios;
          }, 1000);
          
        });
        
    }
  
    applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  
    async MensajePantallaInfo(titulo: string, texto: string){
      Swal.fire({
        title: titulo,
        text: texto,
        icon: 'info',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
      }).then(() => { });
    }
  
    exportexcel(){
      this.svcabm.exportexcel(this.Titulo,[10,11]);
    }

    aceptar(beneficiado: Beneficiados){
      beneficiado.aceptado = false;
      this.svcabm.estadoBeneficiado(beneficiado, true);
    }

    async volveratras(beneficiado: Beneficiados){
        Swal.fire({
          title: '¿Está seguro?',
          text: 'Esta acción vuelve atrás un beneficio ya tomado',
          icon: 'warning',
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
        }).then(
          aceptar => {
            if (aceptar.value) {
              this.svcabm.estadoBeneficiado(beneficiado, false).then( () => {
                Swal.fire('Beneficio libre de usarse', 'El Beneficio se liberó para ser usado nuevamente por el usuario', 'success');
              })
              .catch((err) => {
                Swal.fire('Error!', 'No se pudo realizar la acción', 'error');
              });
            }
          });
      }
  }
