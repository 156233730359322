import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jg',
  templateUrl: './jg.component.html',
  styleUrls: ['./jg.component.scss','../../../css/mystyles.css', '../../../css/custom.css']
})
export class JgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
