<ng-container matColumnDef="Filtro-Botones">
    <button class="boton-menu" color="secondary" (click)="nuevaPrueba()">
        <mat-icon>library_add</mat-icon><br>Crear una Prueba
    </button>
    <button class="boton-menu" *ngIf="this.estado" color="secondary" (click)="pruebas(false,false)">
        <mat-icon>explore_off</mat-icon><br>Pruebas Inactivas
    </button>
    <button class="boton-menu" *ngIf="!this.estado" color="secondary" (click)="pruebas(true,false)">
        <mat-icon>explore</mat-icon><br>Pruebas Activas
    </button>
    <button class="boton-menu" color="secondary" (click)="pruebas(true,true)">
        <mat-icon>hourglass_disabled</mat-icon><br>Pruebas Vencidas
    </button>
    <br>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar Pruebas">
    </mat-form-field>
</ng-container>

<div class="mat-elevation-z8">
    <h2>{{TitPrueba}}</h2>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="Fecha" matSortDirection="asc" slot="fixed">

        <!-- Categoria Column -->
        <ng-container matColumnDef="Categoria">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoria</th>
            <td mat-cell *matCellDef="let row">{{row.Categoria}}</td>
        </ng-container>

        <!-- Club Column -->
        <ng-container matColumnDef="Club">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Club</th>
            <td mat-cell *matCellDef="let row">{{row.Club}}</td>
        </ng-container>

        <!-- Deporte Column -->
        <ng-container matColumnDef="Deporte">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Deporte</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="row.Deporte==='F11'"> FUTBOL 11 </td>
                <td mat-cell *ngIf="row.Deporte==='FP'"> FUTBOL PLAYA </td>
                <td mat-cell *ngIf="row.Deporte==='FS'"> FUTBOL SALA </td>
                <td mat-cell *ngIf="row.Deporte==='ES'"> E-SPORTS </td>
            </ng-container>
        </ng-container>

        <!-- Tipo de Deporte Column -->
        <ng-container matColumnDef="Tipo de Deporte">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de Deporte</th>
            <ng-container *matCellDef="let row">
                <td style="align-items: center" mat-cell *ngIf="row.Sexo==='M'"> MASCULINO </td>
                <td style="align-items: center" mat-cell *ngIf="row.Sexo==='F'"> FEMENINO </td>
                <td style="align-items: center" mat-cell *ngIf="row.Sexo==='A'"> CUALQUIERA </td>
            </ng-container>
        </ng-container>

        <!-- Estado Column -->
        <ng-container matColumnDef="Estado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
            <td mat-cell *matCellDef="let row">
                <div>
                    <mat-chip *ngIf="row.Estado === 'T'" selected color="accent" (click)="activar(row)">Terminada</mat-chip>
                    <mat-chip *ngIf="row.Estado === 'A'" selected color="primary" (click)="activar(row)">Activa</mat-chip>
                    <mat-chip *ngIf="row.Estado === 'N'" selected color="accent" (click)="activar(row)">Nueva</mat-chip>
                    <mat-chip *ngIf="row.Estado === 'S'" selected color="primary" (click)="activar(row)">En Curso</mat-chip>
                    <mat-chip *ngIf="row.Estado === 'P'" selected color="accent" (click)="activar(row)">Pausada</mat-chip>
                </div>
            </td>
        </ng-container>

        <!-- Fecha Column -->
        <ng-container matColumnDef="Fecha">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
            <td mat-cell *matCellDef="let row">{{row.Evento.toDate() | date: 'dd/MM/yyyy HH:mm'}}</td>
        </ng-container>

        <!-- Predio Column -->
        <ng-container matColumnDef="Predio">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Predio</th>
            <td mat-cell *matCellDef="let row">{{row.Predio}}</td>
        </ng-container>

        <!-- Herramientas Column -->
        <ng-container matColumnDef="QR">
            <th style="align-items: center" mat-header-cell *matHeaderCellDef mat-sort-header>QR</th>
            <td mat-cell *matCellDef="let row">
                <button mat-stroked-button color="warn" (click)="genqr(row)">
                    <mat-icon>qr_code_2</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="Editar">
            <th style="align-items: center" mat-header-cell *matHeaderCellDef mat-sort-header>Editar</th>
            <td mat-cell *matCellDef="let row">
                <button mat-stroked-button color="accent" (click)="editar(row)">
                    <mat-icon>edit</mat-icon>
                </button>
            </td>
        </ng-container>
        <ng-container matColumnDef="Lista Inscriptos">
            <th style="align-items: center" mat-header-cell *matHeaderCellDef mat-sort-header>Lista de <br>Inscriptos</th>
            <td mat-cell *matCellDef="let row">
                <button mat-stroked-button color="primary" (click)="inscriptos(row)">
                    <mat-icon>grading</mat-icon>
                </button>
            </td>
        </ng-container>


        <!-- Activa Column -->
        <!-- <ng-container matColumnDef="Activa">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Activa</th>
            <td mat-cell *matCellDef="let row">
                <mat-chip-list>
                    <span *ngIf="row.Activa; else noActiva">
                    <mat-chip selected color="primary" (click)="activar(row)">SI</mat-chip>
                </span>
                    <ng-template #noActiva>
                        <mat-chip selected color="accent" (click)="activar(row)">NO</mat-chip>
                    </ng-template>
                </mat-chip-list>
            </td>
        </ng-container> -->

        <!-- Inscriptos Column -->
        <ng-container matColumnDef="Inscriptos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Inscriptos</th>
            <td mat-cell *matCellDef="let row">
                <div>
                    <span>
                        <mat-chip color="primary">{{row.Inscriptos}}</mat-chip>
                    </span>
                </div>
            </td>
        </ng-container>


        <ng-container matColumnDef="Eliminar">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Eliminar</th>
            <td mat-cell *matCellDef="let row">
                <button mat-stroked-button color="error" (click)="borrar(row)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </mat-card>

    <mat-paginator [pageSizeOptions]="[30, 100]" showFirstLastButtons></mat-paginator>

</div>