import { Component, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnChanges {
  signInForm: UntypedFormGroup;
  public hide = true;
  public muestro = true;

  constructor(
    public fb: UntypedFormBuilder,
    public authSvc: AuthService,
  ) {
    // this.authSvc.isLoggin();

    this.signInForm = this.fb.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.required]],
    });
   }

  ngOnChanges(){
    if (sessionStorage.getItem('admao.@uhg523sodhfgvin')){
      this.muestro = false;
    } else if (sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh') && sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')){
      this.muestro = false;
    }
  }

  get email() {
    return this.signInForm.get('email');
  }

  get password() {
    return this.signInForm.get('password');
  }

  signIn() {
    let credenciales = this.authSvc.emailSignIn(this.email.value, this.password.value);
    credenciales.then( data => {
      if (data){
        this.authSvc.cargarperfil(data.user.email);
      }
    });
  }

}
