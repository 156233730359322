<ng-container matColumnDef="Filtro-Botones">
    <button color="secondary" (click)="exportexcel()">
    <mat-icon>file_download</mat-icon><br>Exportar a Excel
</button>
    <br>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar Usuarios">
    </mat-form-field>
</ng-container>
<div>
    <h2>Listado de Ofrecidos</h2>

    <table id="excel-table" mat-table [dataSource]="dataSource" matSort>

        <!-- Usuario Column -->
        <ng-container matColumnDef="usuario">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
            <ng-container *matCellDef="let row">
                <td width="20%" mat-cell *ngIf="!row.usuario"> Cargando... </td>
                <td width="20%" mat-cell (click)="verdatos(row.usuario)" *ngIf="row.usuario" style="text-align: left;"> {{row.usuario}}</td>
            </ng-container>
        </ng-container>

        <!-- Club Column -->
        <ng-container matColumnDef="club">
            <th width="15%" mat-header-cell *matHeaderCellDef mat-sort-header>Club</th>
            <td width="15%" mat-cell (click)="verdatos(row.usuario)" *matCellDef="let row">{{row.club}}</td>
        </ng-container>

        <!-- Actividad Column -->
        <ng-container matColumnDef="actividad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actividad</th>
            <ng-container *matCellDef="let row">
                <td width="10%" mat-cell *ngIf="!row.actividad"> Cargando... </td>
                <td width="10%" mat-cell (click)="verdatos(row.usuario)" *ngIf="row.actividad==='AM'"> AMATEUR </td>
                <td width="10%" mat-cell (click)="verdatos(row.usuario)" *ngIf="row.actividad==='PR'"> PROFESIONAL </td>
            </ng-container>
        </ng-container>

        <!-- Tipo Licencia Column -->
        <ng-container matColumnDef="tipolicencia">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Licencia</th>
            <ng-container *matCellDef="let row">
                <td width="5%" mat-cell *ngIf="!row.tipolicencia"> Cargando... </td>
                <td width="5%" mat-cell (click)="verdatos(row.usuario)" *ngIf="row.tipolicencia=='CQ'"> Sin Licencia </td>
                <td width="5%" mat-cell (click)="verdatos(row.usuario)" *ngIf="row.tipolicencia=='CB'"> CB </td>
                <td width="5%" mat-cell (click)="verdatos(row.usuario)" *ngIf="row.tipolicencia=='AA'"> A </td>
                <td width="5%" mat-cell (click)="verdatos(row.usuario)" *ngIf="row.tipolicencia=='PR'"> PRO </td>
            </ng-container>
        </ng-container>

        <!-- Deporte Column -->
        <ng-container matColumnDef="deporte">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Deporte</th>
            <ng-container *matCellDef="let row">
                <td width="10%" mat-cell (click)="verdatos(row.usuario)" *ngIf="row.deporte==='F11'"> FUTBOL 11 </td>
                <td width="10%" mat-cell (click)="verdatos(row.usuario)" *ngIf="row.deporte==='FP'"> FUTBOL PLAYA </td>
                <td width="10%" mat-cell (click)="verdatos(row.usuario)" *ngIf="row.deporte==='FS'"> FUTBOL SALA </td>
                <td width="10%" mat-cell (click)="verdatos(row.usuario)" *ngIf="row.deporte==='ES'"> E-SPORTS </td>
            </ng-container>
        </ng-container>


        <!-- Tipo de Deporte Column -->
        <ng-container matColumnDef="sexo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de Deporte</th>
            <ng-container *matCellDef="let row">
                <td width="8%" mat-cell (click)="verdatos(row.usuario)" *ngIf="row.sexo==='M'"> MASCULINO </td>
                <td width="8%" mat-cell (click)="verdatos(row.usuario)" *ngIf="row.sexo==='F'"> FEMENINO </td>
                <td width="8%" mat-cell (click)="verdatos(row.usuario)" *ngIf="row.sexo==='A'"> MASC/FEM </td>
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="datosadicionales">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Datos Adicionales</th>
            <td width="30%" mat-cell *matCellDef="let row">
                <a *ngIf="row.vinculo" [href]="row.datosadicionales">{{row.datosadicionales}}</a>
                <a *ngIf="!row.vinculo">{{row.datosadicionales}}</a>
            </td>
        </ng-container>

        <!-- Herramientas Column -->
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
            <td width="2%" mat-cell *matCellDef="let row">
                <button mat-stroked-button class="acciones" color="accent" (click)="considerar(row)">
                    <mat-icon>search</mat-icon>
                </button>
                <button mat-stroked-button class="acciones" color="error" (click)="borrar(row)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </mat-card>

    <mat-paginator [pageSizeOptions]="[25, 50, 100, 150]" showFirstLastButtons></mat-paginator>

</div>