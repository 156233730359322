import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private apiUrl = 'https://mdp.up.railway.app/api/system/verificarCodigoValidacion'; // Reemplaza con la URL de tu backend en Railway
  private controlUrl = 'https://mdp.up.railway.app/api/system/controlusu'; // URL para el control adicional

  constructor(private http: HttpClient) {}

  verifyValidationCode(email: string, validationCode: string) {
    const params = new HttpParams()
      .set('email', email)
      .set('validationCode', validationCode);
    return this.http.get<{ success: boolean, message?: string }>(`${this.apiUrl}`, { params });
  }

  runControl() {
    return this.http.get(this.controlUrl);
  }
}
