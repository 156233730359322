<main id="home-producto" class="producto-cuerpo-tecnico">
    <section class="hero is-fullheight">
        <div class="column is-6-desktop is-5-widescreen is-offset-1">
            <h1 class="hero-body has-text-white">LA PUERTA DE ENTRADA AL FÚTBOL MUNDIAL Y PROFESIONAL.</h1>
            <h2 class="hero-body is-size-1 has-text-white">
                Podrás entrar en contacto con los mejores clubes del mundo.
            </h2>
        </div>
    </section>

    <section class="section">

        <div class="beneficios-section">
            <div class="columns is-multiline is-centered">
                <div class="column is-full has-text-centered">
                    <h3>¿CUALES SON LOS BENEFICIOS?</h3>
                </div>

                <div class="column is-9 side-padding-mobile">
                    <div class="box beneficios-box">
                        <article class="media vertical-align is-flex-touch">
                            <div class="media-left">
                                <figure class="image is-128x128">
                                    <img src="../../../assets/images/icon-mdp_CT_01.svg" alt="Image">
                                </figure>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <h5 class="is-size-3">¡Entra en contacto con los mejores clubes del mundo!</h5>
                                    <p class="is-size-5">Creando y configurando tu perfil, podrás interactuar con ellos, enterarte de sus búsquedas y aplicar a ellas.</p>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
                <div class="column is-9 side-padding-mobile">
                    <div class="box beneficios-box">
                        <article class="media vertical-align is-flex-touch">
                            <div class="media-left">
                                <figure class="image is-128x128">
                                    <img src="../../../assets/images/icon-mdp_CT_03.svg" alt="Image">
                                </figure>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <h5 class="is-size-3">¡Crea vínculos estratégicos con colegas!</h5>
                                    <p class="is-size-5">A través de nuestro chat seguro, podrás interactuar con otros directores técnicos también registrados en Mercado de Pases.</p>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
                <div class="column is-9 side-padding-mobile">
                    <div class="box beneficios-box">
                        <article class="media vertical-align is-flex-touch">
                            <div class="media-left">
                                <figure class="image is-128x128">
                                    <img src="../../../assets/images/icon-mdp_CT_06.svg" alt="Image">
                                </figure>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <h5 class="is-size-3">¡Los clubes a tu alcance!</h5>
                                    <p class="is-size-5">Estarás a un clic de encontrar los clubes que buscas y hacer que te conozcan.</p>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
                <div class="column is-9 side-padding-mobile">
                    <div class="box beneficios-box">
                        <article class="media vertical-align is-flex-touch">
                            <div class="media-left">
                                <figure class="image is-128x128">
                                    <img src="../../../assets/images/icon-mdp_CT_08.svg" alt="Image">
                                </figure>
                            </div>
                            <div class="media-content">
                                <div class="content">
                                    <h5 class="is-size-3">Beneficios y promociones para nuestros usuarios.</h5>
                                    <p class="is-size-5">Registrándote en Mercado de Pases podrás acceder numerosos beneficios exclusivos aplicables a aéreos, hotelería, capacitaciones, indumentaria y más.</p>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns is-multiline is-centered call-to-action">
            <div class="column is-full has-text-centered">
                <h3>ES TU OPORTUNIDAD</h3>
                <p class="is-size-1">Accedé a todos los beneficios ahora!</p>
            </div>
        </div>
    </section>
</main>