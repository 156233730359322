<mat-card *ngIf="this.cargando" style="width:90%;display: flex; justify-content: center; align-items: center">
    <mat-progress-spinner color="primary" mode="indeterminate">
    </mat-progress-spinner>
</mat-card>
<mat-card *ngIf="!this.cargando" style="width:90%;align-self: center;" class="nueva-prueba-container">
    <form autocomplete="off" [formGroup]="forma">
        <table style="width:100%">
            <tr>
                <td align=center>
                    <h2><u>FICHA DEL USUARIO</u>:</h2><br><span style="font-size: 12px;">{{jugador.correoelectronico}}</span>
                </td>
                <td align=center>
                    <button *ngIf="this.perfiladmin" mat-stroked-button style="color:green" [formGroup]="forma" (click)="chatpersonal(jugador.correoelectronico)">
                        <mat-icon>chat</mat-icon>
                    </button>
                    <button mat-stroked-button style="color:gray" [formGroup]="forma" (click)="listadeinscripciones(jugador.correoelectronico)" matTooltip="Ver pruebas" matTooltipPosition="below">
                        <mat-icon>assignment</mat-icon>
                    </button>
                    <button mat-stroked-button style="color:gray" [formGroup]="forma" (click)="listavideos(jugador.correoelectronico)">
                        <mat-icon>video_library</mat-icon>
                    </button>
                    <button mat-stroked-button style="color:gray" [formGroup]="forma" (click)="serResponsableClub(jugador.correoelectronico)">
                        <mat-icon>supervisor_account</mat-icon>
                    </button>
                </td>
            </tr>
            <tr>
                <td style="width:40%;padding: 2px;vertical-align: top;text-align: center;">
                    <br><br>
                    <mat-chip-avatar>
                        <img style="object-fit: cover;width: 100px; height:100px; border: 0.25px solid; border-radius: 50%;align-content: center;" [src]="jugador.photoURL">
                    </mat-chip-avatar>
                    <div *ngIf="(jugador.Longitud || jugador.Latitud)" [formGroup]="forma">
                        <p>A {{ this.distancia }} km suyo</p>
                    </div>
                </td>
                <td style="width:40%;padding: 2px;vertical-align: top;">
                    <mat-form-field *ngIf="jugador.activacion" [formGroup]="forma">
                        <input matInput placeholder="Fecha de Alta del Usuario" [value]="activacion.toDate() | date: 'dd/MM/yyyy HH:mm'" readonly>
                    </mat-form-field>
                    <mat-form-field *ngIf="jugador.ultimologin" [formGroup]="forma">
                        <input matInput placeholder="Ultimo Ingreso a la Aplicación" [value]="ultimologin.toDate() | date: 'dd/MM/yyyy HH:mm'" readonly>
                    </mat-form-field>
                    <mat-form-field *ngIf="jugador.Latitud" [formGroup]="forma">
                        <input matInput placeholder="Latitud" [value]="jugador.Latitud" readonly>
                    </mat-form-field>
                    <mat-form-field *ngIf="jugador.Longitud" [formGroup]="forma">
                        <input matInput placeholder="Longitud" [value]="jugador.Longitud" readonly>
                    </mat-form-field>
                    <div *ngIf="!(jugador.Longitud || jugador.Latitud)" [formGroup]="forma">
                        <p style="color:red">GPS DESHABILITADO</p>
                    </div>
                </td>
            </tr>
            <br><br>
            <tr>
                <td style="width:40%;padding: 2px;vertical-align: top;">
                    <mat-form-field [formGroup]="forma">
                        <input matInput placeholder="Nombres" [value]="jugador.nombre" readonly>
                    </mat-form-field>
                    <mat-form-field [formGroup]="forma">
                        <input matInput placeholder="Apellidos" [value]="jugador.apellido" readonly>
                    </mat-form-field>
                    <mat-form-field [formGroup]="forma">
                        <input matInput placeholder="Tipo de Documento" [value]="jugador.tipodoc" readonly>
                    </mat-form-field>
                    <mat-form-field [formGroup]="forma">
                        <input matInput placeholder="Nro de Documento" [value]="jugador.nrodoc" readonly>
                    </mat-form-field>
                    <mat-form-field [formGroup]="forma">
                        <input matInput placeholder="Fecha de Nacimiento" [value]="jugador.fechanac | date: 'dd/MM/yyyy'" readonly>
                    </mat-form-field>
                    <mat-form-field [formGroup]="forma">
                        <input matInput placeholder="Nacionalidad" [value]="jugador.nacionalidad" readonly>
                    </mat-form-field>
                    <mat-form-field [formGroup]="forma">
                        <input matInput placeholder="Nro de Telefono" [value]="jugador.telefono" readonly>
                    </mat-form-field>
                    <mat-form-field [formGroup]="forma">
                        <input matInput placeholder="Version de la APP" [value]="jugador.version" readonly>
                    </mat-form-field>
                </td>
                <td style="width:40%;padding: 2px;vertical-align: top;">
                    <mat-form-field [formGroup]="forma">
                        <input matInput placeholder="Perfil Deportivo" [value]="jugador.tipoperfil" readonly>
                    </mat-form-field>
                    <div *ngIf="this.jugador.tipoperfil !== 'Representante' && this.jugador.tipoperfil !== 'Captador' && this.jugador.tipoperfil !== 'Padre Futura Promesa'">
                        <mat-form-field [formGroup]="forma">
                            <input matInput placeholder="Tipo de Deporte" [value]="jugador.sexo" readonly>
                        </mat-form-field>
                        <mat-form-field [formGroup]="forma">
                            <input matInput placeholder="Deporte" [value]="jugador.deporte" readonly>
                        </mat-form-field>
                        <mat-form-field [formGroup]="forma">
                            <input matInput placeholder="Calidad Deportiva" [value]="jugador.actividad" readonly>
                        </mat-form-field>
                    </div>

                    <!-- VER TIPO DE PERFIL POR DATOS -->
                    <div *ngIf="this.jugador.tipoperfil !== 'Representante' && this.jugador.tipoperfil !== 'Captador' && this.jugador.tipoperfil !== 'Padre Futura Promesa'">
                        <mat-form-field *ngIf="jugador.chequeado" [formGroup]="forma">
                            <input matInput placeholder="Chequeado" [value]="jugador.chequeado" readonly>
                        </mat-form-field>                        
                        <mat-form-field *ngIf="jugador.tipoperfil==='Jugador' || jugador.tipoperfil==='Futura Promesa'" [formGroup]="forma">
                            <input matInput placeholder="Posición" [value]="jugador.posicion" readonly>
                        </mat-form-field>
                        <mat-form-field *ngIf="jugador.tipoperfil==='Jugador' || jugador.tipoperfil==='Futura Promesa'" [formGroup]="forma">
                            <input matInput placeholder="Pierna Hábil" [value]="jugador.pierna" readonly>
                        </mat-form-field>
                        <mat-form-field *ngIf="jugador.tipoperfil==='Cuerpo Técnico'" [formGroup]="forma">
                            <input matInput placeholder="Especialidad" [value]="jugador.posicion" readonly>
                        </mat-form-field>
                        <mat-form-field *ngIf="jugador.tipoperfil==='Director Técnico' && jugador.posicion" [formGroup]="forma">
                            <input matInput placeholder="Cargo / Funcion" [value]="jugador.posicion" readonly>
                        </mat-form-field>
                        <mat-form-field *ngIf="jugador.tipoperfil==='Director Técnico'" [formGroup]="forma">
                            <input matInput placeholder="Tipo de Licencia" [value]="jugador.tipolicencia" readonly>
                        </mat-form-field>
                        <mat-form-field *ngIf="jugador.tipoperfil==='Director Técnico'" [formGroup]="forma">
                            <input matInput placeholder="Nro de Licencia" [value]="jugador.licencia" readonly>
                        </mat-form-field>
                        <mat-form-field [formGroup]="forma">
                            <input *ngIf="jugador.antecedentes" matInput placeholder="Tiene Antecedentes Deportivos" value="SI" readonly>
                            <input *ngIf="!jugador.antecedentes" matInput placeholder="Tiene Antecedentes Deportivos" value="NO" readonly>
                        </mat-form-field>
                        <mat-form-field [formGroup]="forma">
                            <input *ngIf="jugador.pportecomun" matInput placeholder="Posee Pasaporte Comunitario Europeo" value="SI" readonly>
                            <input *ngIf="!jugador.pportecomun" matInput placeholder="Posee Pasaporte Comunitario Europeo" value="NO" readonly>
                        </mat-form-field>
                    </div>

                    <div *ngIf="this.jugador.tipoperfil === 'Padre Futura Promesa'">
                        <mat-form-field *ngIf="jugador.chequeado" [formGroup]="forma">
                            <input matInput placeholder="Chequeado" [value]="jugador.chequeado" readonly>
                        </mat-form-field>                        
                    </div>
                </td>
            </tr>
        </table>
        <table *ngIf="this.hayvideos" style="width: 100%;width:50%;padding: 2%;vertical-align: top;">
            <tr>
                <th colspan="2">
                    <h2>VIDEOS SUBIDOS POR EL USUARIO </h2>
                </th>
            </tr>
            <tr *ngFor="let video of videos">
                <td style="width: 40%;padding: 2px; text-align:left">
                    <li> {{video.fechasubida | date: 'dd/MM/yyyy HH:mm'}} </li>
                </td>
                <td style="width: 60%;padding: 2px; text-align:left" *ngFor="let video of videos">
                    <a [href]="video.video" target="_blank"><b>{{video.video}}</b></a>
                </td>
            </tr>
        </table>
    </form>
</mat-card>