import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PruebaService } from '../../services/abm.service';

import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { Beneficio } from '../../models/interfaces.model';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { DialogbeneficiosComponent } from '../shared/dialogbeneficios/dialogbeneficios.component';


@Component({
  selector: 'app-tabla-beneficios',
  templateUrl: './tabla-beneficios.component.html',
  styleUrls: ['./tabla-beneficios.component.scss']
})
export class TablaBeneficiosComponent implements OnInit , AfterViewInit {
  displayedColumns: string[] = ['Beneficio', 'ID','Tipo', 'Activo','Acciones','Beneficiados'];
  dataSource = new MatTableDataSource();
  isLoading = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  items: AngularFirestoreCollection<any>;
  public tabla: any;
  public estado: boolean;

  constructor(
    private pruebaSvc: PruebaService,
    public dialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore
    ) { }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Beneficios por página';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  ngOnInit() {
    this.beneficios();
    this.estado = true;
  }

  beneficios(){
    this.dataSource.data = [];
    this.pruebaSvc.getBeneficios(true).subscribe(
      gifts => {
        gifts.forEach((gift: Beneficio) => {
          this.items = this.afs.collection<any>('Beneficiados', ref => ref
                                              .where('idbi','==', gift.id));
          this.tabla = this.items.valueChanges().subscribe(
            resp => {
              gift.cantbeneficiados = resp.length;
            }
          );
        }); 
        this.isLoading = false;
        this.dataSource.data = gifts;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editar(beneficio: Beneficio) {
    this.openDialog(beneficio);
  }

  beneficiados(beneficio: Beneficio) {
    this.listadobeneficiados(beneficio.id);
  }

  borrar(beneficio: Beneficio) {
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Esta accion no se puede revertir',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then(
      aceptar => {
        if (aceptar.value) {
          this.pruebaSvc.deleteBeneficio(beneficio)
          .then( () => {
            Swal.fire('Beneficio eliminado', 'El beneficio ha sido eliminado', 'success');
          })
          .catch((err) => {
            Swal.fire('Error!', 'El beneficio no ha sido eliminado', 'error');
          });
        }
      });
  }

  openDialog(beneficio?: Beneficio): void {
    const config = {
      data: {
        message: beneficio ? 'Editar Beneficio' : 'Nuevo Beneficio',
        content: beneficio
      }
    };
    const dialogRef = this.dialog.open(DialogbeneficiosComponent, config);
    dialogRef.afterClosed().subscribe(
      res => { }
    );
  }

  listadobeneficiados(id: string){
    this.router.navigateByUrl(`tabla-beneficiados/${id}`);
  }

  activar(beneficio: Beneficio){
    this.pruebaSvc.estadoBeneficio(beneficio, beneficio.activo);
  }

  nuevoBeneficio() {
    this.openDialog();
  }

  genqr(beneficio:Beneficio){
    let nombre = beneficio.nombre.replace(/()\s/g,'€');
    let datos = `bi_${nombre}_${beneficio.id}`;
    this.router.navigateByUrl(`gqr/${datos}`);
  }
}