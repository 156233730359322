<ng-container matColumnDef="Filtro-Botones">
    <button color="secondary" (click)="nuevo()">
        <mat-icon>add_circle</mat-icon><br>Agregar un Equipo
    </button>
    <br>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar Equipos">
    </mat-form-field>
</ng-container>


<div class="mat-elevation-z8">
    <!-- <button mat-fab class="fab-bottom-right"><mat-icon>add</mat-icon></button> -->
    <table mat-table [dataSource]="dataSource" matSort>

        <!-- PhotoURL Column -->
        <ng-container matColumnDef="Escudo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Escudo</th>
            <td mat-cell *matCellDef="let row">
                <mat-icon mat-card-avatar>
                    <img [src]="row.photoURL" width="75%" height="75%" />
                </mat-icon>
            </td>
        </ng-container>

        <!-- ID Equipo Column -->
        <ng-container matColumnDef="IDEquipo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID Equipo</th>
            <td mat-cell *matCellDef="let row">{{row.idequipo}}</td>
        </ng-container>

        <!-- Equipo Column -->
        <ng-container matColumnDef="Equipo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Equipo</th>
            <td mat-cell *matCellDef="let row">{{row.nombre}}</td>
        </ng-container>

        <!-- Categoria Column -->
        <ng-container matColumnDef="Categoria">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Categoría</th>
            <td mat-cell *matCellDef="let row">{{row.categoria}}</td>
        </ng-container>

        <!-- Delegado Column -->
        <ng-container matColumnDef="Delegado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Delegado</th>
            <td mat-cell *matCellDef="let row">{{row.delegado}}</td>
        </ng-container>

        <!-- Organizador Column -->
        <ng-container matColumnDef="Organizador">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Organizador</th>
            <td mat-cell *matCellDef="let row">{{row.organizador}}</td>
        </ng-container>

        <!-- Aprobado Column -->
        <ng-container matColumnDef="Aprobado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Aprobado</th>
            <td mat-cell *matCellDef="let row">{{row.aprobado}}</td>
        </ng-container>

        <!-- Fecha Ultima Modificación Column -->
        <ng-container matColumnDef="Fecha Ultima Modificación">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Ultima Modificación</th>
            <td mat-cell *matCellDef="let row">{{row.fechaultimamod | date: 'dd/MM/yyyy HH:mm'}}</td>
        </ng-container>

        <!-- Acciones Column -->
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
            <td mat-cell *matCellDef="let row">
                <button class="acciones" mat-stroked-button (click)="editar(row)">
                  <mat-icon style="color:gold">edit</mat-icon>
                </button>
                <button class="acciones" mat-stroked-button (click)="borrar(row)">
                  <mat-icon style="color: red;">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </mat-card>

    <mat-paginator [pageSizeOptions]="[150, 300]" showFirstLastButtons></mat-paginator>

</div>