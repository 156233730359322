import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { Amistoso, Beneficiados, Beneficio, CatEquiposAmateurs, Club, Equipo, JugadorModel, Ofrecido, Organizador, Pedido, Predio, Prueba, VideoModel } from '../models/interfaces.model';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { Mensaje } from '../models/chat.model';

@Injectable({
  providedIn: 'root'
})

export class PruebaService {

  // Declaracion del puntero
  private jugadoresCollection: AngularFirestoreCollection<JugadorModel>;
  private organizadoresCollection: AngularFirestoreCollection<Organizador>;
  private pruebasCollection: AngularFirestoreCollection<Prueba>;
  private clubesCollection: AngularFirestoreCollection<Club>;
  private equiposCollection: AngularFirestoreCollection<Equipo>;
  private prediosCollection: AngularFirestoreCollection<Predio>;
  private pedidosCollection: AngularFirestoreCollection<Pedido>;
  private ofrecidosCollection: AngularFirestoreCollection<any>;
  private InscriptosCollection: AngularFirestoreCollection<any>;
  private catequiposCollection: AngularFirestoreCollection<CatEquiposAmateurs>;
  private videosCollection: AngularFirestoreCollection<VideoModel>;
  private beneficiosCollection: AngularFirestoreCollection<Beneficio>;
  private amistososCollection: AngularFirestoreCollection<any>;

  amistosos: Observable<Amistoso[]>;
  pruebas: Observable<Prueba[]>;
  clubes: Observable<Club[]>;
  equipos: Observable<Equipo[]>;
  predios: Observable<Predio[]>;
  pedidos: Observable<Pedido[]>;
  ofrecidos: Observable<any[]>;
  jugadores: Observable<JugadorModel[]>;
  videos: Observable<VideoModel[]>;
  organizadores: Observable<Organizador[]>
  catequipos: Observable<CatEquiposAmateurs[]>
  cant: number;
  total: any;
  filename: string;
  public TitPrueba: string;
  public TitAmistoso: string;
  beneficios: any;
  public est: string;
  
  constructor(private afs: AngularFirestore) { }

  // CATEGORIAS DE EQUIPOS //
  
  public getCatEquipos() {
    this.catequiposCollection = this.afs.collection<CatEquiposAmateurs>('CategoriasAmateurs',ref => ref.where('estado','==',true).orderBy('tipo', 'asc'));
    this.catequipos = this.catequiposCollection.valueChanges();
    return this.catequipos;
  }

  // ORGANIZADORES //

  public getOrganizadores() {
    this.organizadoresCollection = this.afs.collection<Organizador>('Organizadores',ref => ref.orderBy('nombre', 'asc'));
    this.organizadores = this.organizadoresCollection.valueChanges();
    return this.organizadores;
  }

  addOrganizador( organizador: Organizador ) {
    this.organizadoresCollection.doc(organizador.ID).set(organizador);
  }

  editOrganizador( organizador: Organizador ) {
    return this.organizadoresCollection.doc(organizador.ID).update(organizador); 
  }

  public deleteOrganizador( organizador: Organizador ) {
    return this.organizadoresCollection.doc(organizador.ID).delete(); 
  }

  public estadoOrganizador ( organizador: Organizador, dato: boolean ){
    return this.organizadoresCollection.doc(organizador.ID).update({ estado: !dato });
  }

  // USUARIOS //

  public getUsuarios() {
    this.jugadoresCollection = this.afs.collection<JugadorModel>('Usuarios',ref => ref.orderBy('apellido', 'asc'));
    this.jugadores = this.jugadoresCollection.valueChanges();
    return this.jugadores;
  }

  // PRUEBAS //

  public getPruebas(tipo: boolean, vencidas: boolean) {
    let ahora = moment(Date.now()).toDate();
    if (!vencidas){
      if (sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')==='CB') {
        this.pruebasCollection = this.afs.collection<Prueba>('Pruebas', ref => ref
                                    .where('Club','==',sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh'))
                                    .where('Activa','==', tipo)
                                    .where('Evento', '>=', ahora)
                                    .orderBy('Evento', 'asc'));
      } else if (sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')==='CE') {
        this.pruebasCollection = this.afs.collection<Prueba>('Pruebas', ref => ref
                                    .where('Predio','==',sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh'))
                                    .where('Activa','==', tipo)
                                    .where('Evento', '>=', ahora)
                                    .orderBy('Evento', 'asc'));
      } else {
        this.pruebasCollection = this.afs.collection<Prueba>('Pruebas', ref => ref
                                    .where('Activa','==', tipo)
                                    .where('Evento', '>=', ahora)
                                    .orderBy('Evento', 'asc'));
      }
    } else {
      if (sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')==='CB') {
        this.pruebasCollection = this.afs.collection<Prueba>('Pruebas', ref => ref
                                    .where('Club','==',sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh'))
                                    .where('Evento', '<', ahora)
                                    .orderBy('Evento', 'desc'));
      } else if (sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')==='CE') {
        this.pruebasCollection = this.afs.collection<Prueba>('Pruebas', ref => ref
                                    .where('Predio','==',sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh'))
                                    .where('Evento', '<', ahora)
                                    .orderBy('Evento', 'desc'));
      } else {
        this.pruebasCollection = this.afs.collection<Prueba>('Pruebas', ref => ref
                                    .where('Evento', '<', ahora)
                                    .orderBy('Evento', 'desc'));
      }
    }
    this.pruebas = this.pruebasCollection.valueChanges({ idField: 'id' });
    return this.pruebas;
  }

  public deletePrueba( prueba: Prueba ) {
    this.InscriptosCollection = this.afs.collection('Inscripciones', ref => ref.where('id','==',prueba.id));
    this.InscriptosCollection.get().toPromise().then( resplist => { 
      this.cant = 0;
      resplist.forEach(doc => {
        this.cant = this.cant + 1;
        doc.ref.delete();
      })
      this.total = resplist.size;
      if (this.cant === resplist.size) {
        this.MensajePantalla('Inscriptos', `Se eliminaron ${this.cant} inscripciones de la prueba seleccionada`);
      }
    })
    return this.pruebasCollection.doc(prueba.id).delete();
  }

  async MensajePantalla(titulo: string, texto: string){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'success',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { });
  }

  public editPrueba( prueba: Prueba ) {
    this.MensajePantallaOK('Prueba Actualizada',`Club: ${prueba.Club} - Horario: ${prueba.Evento} - Categoria: ${prueba.Categoria}`);
    return this.pruebasCollection.doc(prueba.id).update(prueba);
  }

  async CambioEstado(){

  }

  public async estadoPrueba( prueba: Prueba, dato: boolean ) {
    // await Swal.fire({
    //   title: 'CAMBIO DE ESTADO',
    //   text: '¿Cual es el Estado al que desea cambiar la prueba?',
    //   icon: 'question',
    //   showDenyButton: true,
    //   showCancelButton: true,
    //   showCloseButton: true,
    //   showConfirmButton: true,
    //   confirmButtonText: 'Terminar',
    //   confirmButtonColor: '#d33',
    //   denyButtonText: 'Comenzar',
    //   denyButtonColor: '#3085d6',
    //   cancelButtonText: 'Pausar/Reanudar'
    // }).then((result) => {
    //     console.log(result, result.value);
    //       if (result.isConfirmed){
    //         dato = true;
    //         this.est = 'T';
    //       }
    //       if (result.isDenied){
    //         dato = true;
    //         this.est = 'S';
    //       }
    //       if (result.isDismissed){
    //         this.est = 'ANP';
    //       }
    //  });
    // if (this.est === 'ANP' && dato) {
    //   dato = false;
    //   this.est = 'P';
    // } else if (this.est === 'ANP' && !dato) {
    //   dato = true;
    //   this.est = 'A';
    // } else {
    //   dato = true;
    // }

    const { value: string } = await Swal.fire({
      title: 'Seleccione un Estado para la Prueba',
      input: 'select',
      inputOptions: {
        'Estados': {
          S: 'Comenzar',
          T: 'Terminar',
          P: 'Pausar',
          A: 'Activar'
        }
      },
      inputPlaceholder: 'Seleccione un Estado',
      confirmButtonColor: '#009975',
      confirmButtonText: 'Aceptar',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            resolve(this.est = value);
            if (this.est === 'P'){
              dato = false;
            } else {
              dato = true;
            }
            this.InscriptosCollection = this.afs.collection('Inscripciones', ref => ref.where('id','==',prueba.id));
            this.InscriptosCollection.get().toPromise().then( resplist => { 
              this.cant = 0;
              resplist.forEach(doc => {
                this.cant = this.cant + 1;
                doc.ref.update({inscripto: dato});
              })
              this.total = resplist.size;
              if (this.cant === resplist.size) {
                if (dato) {
                  this.MensajePantalla('Inscriptos', `Se activaron ${this.cant} inscripciones de la prueba seleccionada`); 
                } else {
                  this.MensajePantalla('Inscriptos', `Se desactivaron ${this.cant} inscripciones de la prueba seleccionada`);
                }
              }
            })
            return this.pruebasCollection.doc(prueba.id).update({ Activa: dato, Estado: this.est});
          }
        })
      }
    })
  }

  public deletePruebaById( id: string ) {
    return this.pruebasCollection.doc(`${id}`).delete();
  }

  public getPruebaById( id: string ) {
    return this.pruebasCollection.doc(`${id}`).valueChanges();
  }

  public addPrueba(prueba: Prueba) {

    const pruebaObj = {
      Activa: false,
      fechaCreacion: prueba.fechaCreacion,
      ID: prueba.ID,
      id: prueba.id,
      Club: prueba.Club,
      Categoria: prueba.Categoria,
      Evento: prueba.Evento,
      Predio: prueba.Predio,
      Latitud: prueba.Latitud,
      Longitud: prueba.Longitud,
      Provincia: prueba.Provincia,
      Pais: prueba.Pais,
      Entrenamiento: prueba.Entrenamiento,
      Pasaporte: prueba.Pasaporte,
      Pierna: prueba.Pierna,
      Posicion: prueba.Posicion,
      Deporte: prueba.Deporte,
      Sexo: prueba.Sexo,
      PhotoURL: prueba.PhotoURL,
      Antecedentes: prueba.Antecedentes,
      Llamar: prueba.Llamar,
      WhatsApp: prueba.WhatsApp,
      Contacto: prueba.Contacto,
      Telefono: prueba.Telefono,
      limite: prueba.limite,
      Estado: prueba.Estado
    };
    this.pruebasCollection.add(pruebaObj)
    .then(pruebaDoc => {
      this.pruebasCollection.doc(`${pruebaDoc.id}`).update({ID: pruebaDoc.id});
    });
    this.MensajePantallaOK('Nueva Prueba Guardada',`Club: ${pruebaObj.Club} - Horario: ${pruebaObj.Evento} - Categoria: ${pruebaObj.Categoria}`);
  }
  
  async MensajePantallaOK(titulo: string, texto: string){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'success',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { });
  }

  // PEDIDOS //

  public getPedidos() {
    if (sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')==='CB') {
      this.pedidosCollection = this.afs.collection<Pedido>('Pedidos', ref => ref
                                  .where('Solicitante','==',sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh'))
                                  .orderBy('Fecha', 'asc'));
    } else {
      this.pedidosCollection = this.afs.collection<Pedido>('Pedidos',ref => ref.orderBy('Fecha', 'asc'));
    }
    this.pedidos = this.pedidosCollection.valueChanges({ idField: 'id' });
    return this.pedidos;
  }

  addPedido( pedido: Pedido ) {
    const pedidoObj = {
      Fecha: pedido.Fecha,
      Solicitante: pedido.Solicitante,
      pportecomun: pedido.pportecomun,
      Puesto: pedido.Puesto,
      Deporte: pedido.Deporte,
      Sexo: pedido.Sexo,
      PhotoURL: pedido.PhotoURL,
      ID: pedido.ID,
      Actividad: pedido.Actividad
    };
    this.pedidosCollection.add(pedidoObj)
    .then( pedidoDoc => {
      this.pedidosCollection.doc(`${pedidoDoc.id}`).update({ID: pedidoDoc.id});
    });

  }

  public editPedido(pedido: Pedido) {
    return this.pruebasCollection.doc(pedido.ID).update(pedido);
  }

  public deletePedido(pedido: Pedido) {
    return this.pedidosCollection.doc(pedido.ID).delete();
  }

  // CLUBES //

  public getClubes() {
    if (sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')==='CB') {
      this.clubesCollection = this.afs.collection<Club>('Clubes', ref => ref
                                      .where('Club','==',sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh')));
    } else {
      this.clubesCollection = this.afs.collection<Club>('Clubes', ref => ref.orderBy('Club', 'asc'));
    }
    this.clubes = this.clubesCollection.valueChanges();
    return this.clubes;
  }

  addClub( club: Club ) {
    this.clubesCollection.add(club)
    .then (clubesDoc => {
      this.clubesCollection.doc(clubesDoc.id).update({ID: clubesDoc.id});
    });
  }

  editClub( club: Club ) {
    return this.clubesCollection.doc(club.ID).update(club); 
  }

  public deleteClub(club: Club) {
    return this.clubesCollection.doc(club.ID).delete(); 
  }

// EQUIPOS //

public getEquipos() {
  this.equiposCollection = this.afs.collection<Equipo>('Equipos', ref => ref.orderBy('nombre', 'asc'));
  this.equipos = this.equiposCollection.valueChanges();
  return this.equipos;
}

addEquipo( equipo: Equipo ) {
  this.equiposCollection.add(equipo)
  .then (equiposDoc => {
    this.equiposCollection.doc(equiposDoc.id).update({ID: equiposDoc.id, fechaalta: new Date().getTime(), fechaultimamod: new Date().getTime()});
  });
}

editEquipo( equipo: Equipo ) {
  equipo.fechaultimamod = new Date().getDate();
  return this.equiposCollection.doc(equipo.ID).update(equipo); 
}

public deleteEquipo(equipo: Equipo) {
  return this.equiposCollection.doc(equipo.ID).delete(); 
}


  // PREDIOS //

  public getPredios(deporte?: string) {
    if (sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')==='CE') {
      this.prediosCollection = this.afs.collection<Predio>('Predios', ref => ref
                                  .where('Nombre','==',sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh')));
    } else {
      if (!deporte) {
        this.prediosCollection = this.afs.collection<Predio>('Predios', ref => ref.orderBy('Nombre', 'asc'));
      } else {
        this.prediosCollection = this.afs.collection<Predio>('Predios', ref => ref
                                                  .where ('Deportes','array-contains', deporte)    
                                                  .orderBy('Nombre', 'asc'));
      }
    }
    this.predios = this.prediosCollection.valueChanges();
    return this.predios;
  }

  addPredio( predio: Predio ) {
    this.prediosCollection.doc(predio.Nombre).set(predio);
  }

  editPredio( predio: Predio ) {
    return this.prediosCollection.doc(predio.Nombre).update(predio); 
  }

  public deletePredio(predio: Predio) {
    return this.prediosCollection.doc(predio.Nombre).delete(); 
  }

  buscarPredio(predio: string) {
    return this.prediosCollection.doc(predio).get().toPromise().then();
  }


   // OFRECIDOS //

   public getOfrecidos() {
    if (sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')==='CB') {
      this.ofrecidosCollection = this.afs.collection<Ofrecido>('Ofrecidos', ref => ref
                                  .where('club','==',sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh'))
                                  .orderBy('fecha', 'asc'));
    } else {
      this.ofrecidosCollection = this.afs.collection<Ofrecido>('Ofrecidos',ref => ref.orderBy('fecha', 'asc'));
    }
    this.ofrecidos = this.ofrecidosCollection.valueChanges({ idField: 'id' });
    return this.ofrecidos;
  }

  public deleteOfrecido(ofrecido: Ofrecido) {
    this.enviarmensaje(ofrecido);
    return this.ofrecidosCollection.doc(ofrecido.id).delete(); 
  }

  // CURRICULUMS VITAE //
  
  public getCV() {
    if (sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')==='CB') {
      this.ofrecidosCollection = this.afs.collection<Ofrecido>('OfrecidosJG', ref => ref
                                  .where('club','==',sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh'))
                                  .orderBy('fecha', 'asc'));
    } else {
      this.ofrecidosCollection = this.afs.collection<Ofrecido>('OfrecidosJG',ref => ref.orderBy('fecha', 'asc'));
    }
    this.ofrecidos = this.ofrecidosCollection.valueChanges({ idField: 'id' });
    return this.ofrecidos;
  }
  
  public deleteCV(ofrecido: Ofrecido) {
    this.enviarmensajeCV(ofrecido);
    return this.ofrecidosCollection.doc(ofrecido.id).delete(); 
  }
  

  // VIDEOS //

  public getVideos(uid:string) {
    this.videosCollection = this.afs.collection('Videos',ref => ref.where('usuario','==',uid).orderBy('fechasubida','asc'));
    this.videos = this.videosCollection.valueChanges();
    return this.videos;
  }

  // BENEFICIOS //

  public getBeneficios( estado: boolean ) {
    this.beneficiosCollection = this.afs.collection('Beneficios',ref => ref.where('activo','==',estado));
    this.beneficios = this.beneficiosCollection.valueChanges();
    return this.beneficios;
  }

  public editBeneficios(beneficio: Beneficio) {
    return this.beneficiosCollection.doc(beneficio.id).update(beneficio)
  }

  addBeneficio( beneficio: Beneficio ) {
    beneficio.id = new Date().getTime().toString();
    this.beneficiosCollection.doc(beneficio.id).set(beneficio);
    this.MensajePantallaOK('Nueva Beneficio Guardado',`Etiqueta: ${beneficio.nombre} - Tipo de Beneficio: ${beneficio.tipo}`);
  }

  public deleteBeneficio(beneficio: Beneficio) {
    return this.beneficiosCollection.doc(beneficio.id).delete(); 
  }

  public estadoBeneficio( beneficio: Beneficio, dato: boolean ) {
    return this.beneficiosCollection.doc(beneficio.id).update({ activo: !dato });
  }

  public estadoBeneficiado( beneficiado: Beneficiados, dato: boolean ) {
    return this.afs.collection('Beneficiados').doc(beneficiado.idbeneficiado).update({ aceptado: dato });
  }

  // EXPORTAR A XLSx

  exportexcel(filename: string, hidden: number[]): void 
  {
    console.log(filename);
    /* table id is passed over here */   
    let element = document.getElementById('excel-table');

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
    ws['!cols'] = [];
    for (let i = 0; i < hidden.length; i++) {
      ws['!cols'][hidden[i]] = { hidden: true };
    }

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, filename);

    /* save to file */
    XLSX.writeFile(wb, `${filename}.xlsx`); 
  }

  // MENSAJES DE AVISOS
  async enviarmensaje(ofrecido: Ofrecido){ 
    const mensaje: Mensaje = {
      nombre: ofrecido.club,
      mensaje: `La entidad deportiva ${ofrecido.club} ha desestimado definitivamente tu ofrecimiento luego de haberlo puesto en consideración. Te agradece por la oferta laboral pero no será tomada en cuenta. Saludos.`,
      fecha: new Date().getTime(),
      uid: 'contacto@mercadodepases.club',
      chat: ofrecido.usuario,
      leidoreceptor: 0
    };
    this.afs.doc(`chats/${mensaje.fecha}`).set(mensaje);
  }

  async enviarmensajeCV(ofrecido: Ofrecido){ 
    const mensaje: Mensaje = {
      nombre: ofrecido.club,
      mensaje: `La entidad deportiva ${ofrecido.club} ha desestimado definitivamente tu CV luego de haberlo puesto en consideración. Te agradece por la molestia de la entrega de tu CV pero no será tomado en cuenta. Saludos.`,
      fecha: new Date().getTime(),
      uid: 'contacto@mercadodepases.club',
      chat: ofrecido.usuario,
      leidoreceptor: 0
    };
    this.afs.doc(`chats/${mensaje.fecha}`).set(mensaje);
  }

  async enviarconsideracion(ofrecido: Ofrecido){ 
    const mensaje: Mensaje = {
      nombre: ofrecido.club,
      mensaje: `La entidad deportiva ${ofrecido.club} ha puesto en consideracion tu ofrecimiento laboral espontáneo. Te mantendremos informado. Saludos.`,
      fecha: new Date().getTime(),
      uid: 'contacto@mercadodepases.club',
      chat: ofrecido.usuario,
      leidoreceptor: 0
    };
    this.afs.doc(`chats/${mensaje.fecha}`).set(mensaje);
  }

  async enviarconsideracionCV(ofrecido: Ofrecido){ 
    const mensaje: Mensaje = {
      nombre: ofrecido.club,
      mensaje: `La entidad deportiva ${ofrecido.club} ha puesto en consideracion tu Currículum Vitae. Te mantendremos informado. Saludos.`,
      fecha: new Date().getTime(),
      uid: 'contacto@mercadodepases.club',
      chat: ofrecido.usuario,
      leidoreceptor: 0
    };
    this.afs.doc(`chats/${mensaje.fecha}`).set(mensaje);
  }

  // AMISTOSOS - TENIS //
  
  public getAmistosos(tipo: boolean, vencidos: boolean) {
    let ahora = moment(Date.now()).toDate();
    if (!vencidos){
      if (sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')==='CB') {
        this.amistososCollection = this.afs.collection<Amistoso>('Amistosos', ref => ref
                                    .where('Organizador','==',sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh'))
                                    .where('Activo','==', tipo)
                                    .where('Evento', '>=', ahora)
                                    .orderBy('Evento', 'asc'));
      } else if (sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')==='CE') {
        this.amistososCollection = this.afs.collection<Amistoso>('Amistosos', ref => ref
                                    .where('Predio','==',sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh'))
                                    .where('Activo','==', tipo)
                                    .where('Evento', '>=', ahora)
                                    .orderBy('Evento', 'asc'));
      } else {
        this.amistososCollection = this.afs.collection<Amistoso>('Amistosos', ref => ref
                                    .where('Activo','==', tipo)
                                    .where('Evento', '>=', ahora)
                                    .orderBy('Evento', 'asc'));
      }
    } else {
      if (sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')==='CB') {
        this.amistososCollection = this.afs.collection<Amistoso>('Amistosos', ref => ref
                                    .where('Organizador','==',sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh'))
                                    .where('Evento', '<', ahora)
                                    .orderBy('Evento', 'desc'));
      } else if (sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')==='CE') {
        this.amistososCollection = this.afs.collection<Amistoso>('Amistosos', ref => ref
                                    .where('Predio','==',sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh'))
                                    .where('Evento', '<', ahora)
                                    .orderBy('Evento', 'desc'));
      } else {
        this.amistososCollection = this.afs.collection<Amistoso>('Amistosos', ref => ref
                                    .where('Evento', '<', ahora)
                                    .orderBy('Evento', 'desc'));
      }
    }
    this.amistosos = this.amistososCollection.valueChanges({ idField: 'id' });
    return this.amistosos;
  }
  
  public deleteAmistoso( amistoso: Amistoso ) {
    return this.amistososCollection.doc(amistoso.ID).delete();
  }
  
  public editAmistoso( amistoso: Amistoso ) {
    amistoso.Evento = amistoso.Fecha;
    amistoso.Organizador = amistoso.Organizador.nombre;
    this.MensajePantallaOK('Prueba Actualizada',`Organizador: ${amistoso.Organizador} - Horario: ${moment(amistoso.Evento).format('HH:mm DD/MM/YYYY')} - Partido de ${amistoso.Juego}`);
    return this.amistososCollection.doc(amistoso.id).update(amistoso);
  }
  
  public estadoAmistoso( amistoso: Amistoso, dato: boolean ) {
    this.InscriptosCollection = this.afs.collection('Inscripciones', ref => ref.where('id','==',amistoso.ID));
    this.InscriptosCollection.get().toPromise().then( resplist => { 
      this.cant = 0;
      resplist.forEach(doc => {
        this.cant = this.cant + 1;
        doc.ref.update({inscripto: !dato});
      })
      this.total = resplist.size;
      if (this.cant === resplist.size) {
        if (!dato) {
          this.MensajePantalla('Inscriptos', `Se activaron ${this.cant} inscripciones del amistoso seleccionado`); 
        } else {
          this.MensajePantalla('Inscriptos', `Se desactivaron ${this.cant} inscripciones del amistoso seleccionado`);
        }
      }
    })
    return this.amistososCollection.doc(amistoso.id).update({ Activo: !dato });
  }
  
  public deleteAmistosoById( id: string ) {
    return this.amistososCollection.doc(`${id}`).delete();
  }
  
  public getAmistosoById( id: string ) {
    return this.amistososCollection.doc(`${id}`).valueChanges();
  }
  
  public addAmistoso( amistoso: Amistoso ) {
  
    const amistosoObj = {
      Activo: false,
      fechaCreacion: amistoso.fechaCreacion,
      ID: amistoso.ID,
      id: amistoso.id,
      Categoria: '',
      Sexo: 'A',
      Deporte: 'TN',
      Organizador: amistoso.Organizador,
      PhotoURL: amistoso.PhotoURL,
      Juego: amistoso.Juego,
      Evento: amistoso.Evento,
      Fecha: amistoso.Fecha,
      Predio: amistoso.Predio,
      Telefono: amistoso.Telefono,
      Abierto: true
    };
    this.amistososCollection.add(amistosoObj)
    .then(Doc => {
      this.amistososCollection.doc(`${Doc.id}`).update({ID: Doc.id});
    });
    this.MensajePantallaOK('Nuevo Amistoso',`Organizador: ${amistosoObj.Organizador} - Horario: ${moment(amistosoObj.Evento).format('HH:mm DD/MM/YYYY')} - Partido de ${amistosoObj.Juego}`);
  }


  
}
