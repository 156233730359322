<ng-container matColumnDef="Filtro-Botones">
    <button color="secondary" (click)="exportexcel()">
        <mat-icon>file_download</mat-icon><br>Exportar a Excel
    </button>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar Usuarios">
    </mat-form-field>
    <mat-label color="secondary">Usuarios que buscan Representante: {{dataSource.data.length}} </mat-label>
</ng-container>

<div class="mat-elevation-z8">
    <table id="excel-table" mat-table [dataSource]="dataSource" matSort>

        <!-- Icono Column -->
        <ng-container class="omitir" matColumnDef="icono">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
            <td style="text-align:left; padding: 10px;" mat-cell *matCellDef="let row">
                <p style="font-weight: bold;font-size: 14px;">
                    <button type="button" style="width:40px;height:40px;background-color:rgb(27, 89, 128);color:lightsteelblue;border-radius: 50%;" (click)="verusuario(row.correoelectronico)">
                        <mat-icon>person</mat-icon>
                    </button>
                </p>
            </td>
        </ng-container>

        <!-- Usuario Column -->
        <ng-container matColumnDef="correoelectronico">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
            <td style="text-align:left" mat-cell *matCellDef="let row">
                <p style="font-weight: bold;font-size: 14px;">
                    {{row.correoelectronico}}
                </p>
            </td>
        </ng-container>

        <!-- Nombre Column -->
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
            <td style="text-align:left" mat-cell *matCellDef="let row">{{row.nombre}}</td>
        </ng-container>

        <!-- Apellido Column -->
        <ng-container matColumnDef="apellido">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Apellido</th>
            <td style="text-align:left" mat-cell *matCellDef="let row">{{row.apellido}}</td>
        </ng-container>

        <!-- Deporte Column -->
        <ng-container class="omitir" matColumnDef="deporte">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Deporte</th>
            <td mat-cell *matCellDef="let row">{{row.deporte}}</td>
        </ng-container>

        <!-- Sexo Column -->
        <ng-container class="omitir" matColumnDef="sexo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sexo</th>
            <td mat-cell *matCellDef="let row">{{row.sexo}}</td>
        </ng-container>

        <!-- Fecha Nacimiento Column -->
        <ng-container matColumnDef="fechanac">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Nac</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.fechanac"> MAL CARGADA </td>
                <td mat-cell *ngIf="row.fechanac">{{row.fechanac | date: 'dd/MM/yyyy'}}</td>
            </ng-container>
        </ng-container>

        <!-- Tipo de Perfil Column -->
        <ng-container matColumnDef="tipoperfil">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de Perfil</th>
            <td mat-cell *matCellDef="let row">{{row.tipoperfil}}</td>
        </ng-container>

        <!-- Actividad Column -->
        <ng-container matColumnDef="actividad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actividad</th>
            <td mat-cell *matCellDef="let row">{{row.actividad}}</td>
        </ng-container>

        <!-- Tipo de Licencia Column -->
        <ng-container matColumnDef="tipolicencia">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de Licencia</th>
            <td mat-cell *matCellDef="let row">{{row.tipolicencia}}</td>
        </ng-container>

        <!-- Herramientas Column -->
        <ng-container class="omitir" matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chat</th>
            <td mat-cell *matCellDef="let row">
                <button *ngIf="row.sinleer && row.correoelectronico !== 'contacto@mercadodepases.club'" style="background: red;" mat-stroked-button (click)="chat(row.correoelectronico)" value=0>
                  <mat-icon style="color:gold">chat</mat-icon>
                </button>
                <button *ngIf="!row.sinleer" mat-stroked-button (click)="chat(row.correoelectronico)" value=1>
                  <mat-icon style="color: green;">chat</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </mat-card>

    <mat-paginator [pageSizeOptions]="[50, 75, 150]" showFirstLastButtons></mat-paginator>

</div>