import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PruebaService } from '../../services/abm.service';
import { Predio } from 'src/app/models/interfaces.model';
import { DialogpredioComponent } from '../shared/dialogpredio/dialogpredio.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tabla-predios',
  templateUrl: './tabla-predios.component.html',
  styleUrls: ['./tabla-predios.component.scss']
})
export class TablaPrediosComponent implements OnInit , AfterViewInit {

  displayedColumns: string[] = ['Nombre', 'Deportes', 'Domicilio', 'Localidad', 'Partido', 'Provincia', 'Pais', 'Latitude', 'Longitude', 'usuario', 'clases', 'Acciones'];
  dataSource = new MatTableDataSource();

  isLoading = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private pruebaSvc: PruebaService,
    public dialog: MatDialog
  ) { }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Predios por página';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.pruebaSvc.getPredios().subscribe(
      predios => {
        this.isLoading = false;
        this.dataSource.data = predios;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  nuevo() {
    this.openDialog();
  }

  openDialog(predio?: Predio) {
    const config = {
      data: {
        message: predio ? 'Editar Predio' : 'Nuevo Predio',
        content: predio
      }
    };
    const dialogRef = this.dialog.open(DialogpredioComponent, config);
    dialogRef.afterClosed().subscribe(
      res => {  }
    );
  }

  editar(predio: Predio) {
    this.openDialog(predio);
  }

  borrar(predio: Predio) {
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Esta accion no se puede revertir',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then(
      aceptar => {
        if (aceptar.value) {
          this.pruebaSvc.deletePredio(predio)
          .then( () => {
            Swal.fire('Predio eliminado', 'El Predio ha sido eliminado', 'success');
          })
          .catch((err) => {
            Swal.fire('Error!', 'El Predio no ha sido eliminado', 'error');
          });
        }
      });
  }

  vincularusuario(predio: Predio){
    console.log(predio);
  }

}

