<div class="nueva-prueba-container">
    <form [formGroup]="clubForm" (ngSubmit)="addClub(clubForm.value)">
        <mat-form-field>
            <input matInput type="text" placeholder="Club" formControlName="Club" aria-label="Label">
        </mat-form-field>

        <mat-form-field>
            <input matInput type="text" placeholder="Fundacion (dd/mm/yyyy)" formControlName="Fundacion" aria-label="Label">
        </mat-form-field>

        <mat-form-field>
            <input matInput type="text" placeholder="URL del ESCUDO" formControlName="photoURL" aria-label="Label">
        </mat-form-field>

        <div class="container-button">
            <input type="submit" class="mat-flat-button" value="Guardar" [mat-dialog-close]="true">
        </div>
    </form>
</div>