import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

export interface Item { nombre: string; photoURL: string }

@Component({
  selector: 'app-imagenes',
  templateUrl: './imagenes.component.html',
  styles: []
})
export class ImagenesComponent implements OnInit {

  private itemsCollection: AngularFirestoreCollection<Item>;
  items: Observable<Item[]>;

  constructor(private afs: AngularFirestore) { 
    this.itemsCollection = afs.collection<Item>('Equipos');
    this.items = this.itemsCollection.valueChanges();
  }

  ngOnInit() {
  }

}
