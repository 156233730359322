import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/* COMPONENTES */
import { NavComponent } from './nav/nav.component';
import { MaterialModule } from './material.module';
import { LoginComponent } from './componentes/login/login.component';
import { CuerpoTecnicoComponent } from './componentes/cuerpo-tecnico/cuerpo-tecnico.component';
import { DialogComponent } from './componentes/shared/dialog/dialog.component';
import { TablaBusquedasComponent } from './componentes/tabla-busquedas/tabla-busquedas.component';
import { TablaClubesComponent } from './componentes/tabla-clubes/tabla-clubes.component';
import { ChattingComponent } from './componentes/chatting/chatting.component';
import { TablaInscriptosComponent } from './componentes/tabla-inscriptos/tabla-inscriptos.component';
import { MensajeriamasComponent } from './componentes/mensajeriamas/mensajeriamas.component';
import { TycComponent } from './componentes/tyc/tyc.component';
import { NuevoclubComponent } from './componentes/nuevoclub/nuevoclub.component';
import { DialogclubComponent } from './componentes/shared/dialogclub/dialogclub.component';
import { NuevopredioComponent } from './componentes/nuevopredio/nuevopredio.component';
import { TablaPrediosComponent } from './componentes/tabla-predios/tabla-predios.component';
import { DialogpredioComponent } from './componentes/shared/dialogpredio/dialogpredio.component';
import { CeComponent } from './componentes/landing/ce/ce.component';
import { JgComponent } from './componentes/landing/jg/jg.component';
import { CbComponent } from './componentes/landing/cb/cb.component';
import { CtComponent } from './componentes/landing/ct/ct.component';
import { LandingsComponent } from './componentes/landing/landings/landings.component';
import { PerfilComponent } from './componentes/perfil/perfil.component';
import { GQRComponent } from './componentes/gqr/gqr.component';

/* FIREBASE */
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/compat/storage';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment'; // Importa la configuración de Firebase


// FORMULARIOS
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalComponent } from './componentes/modal/modal.component';
import { TablaPostuladosComponent } from './componentes/tabla-postulados/tabla-postulados.component';
import { TablaOfrecidosComponent } from './componentes/tabla-ofrecidos/tabla-ofrecidos.component';
import { TablaBeneficiosComponent } from './componentes/tabla-beneficios/tabla-beneficios.component';
import { NuevobeneficioComponent } from './componentes/nuevobeneficio/nuevobeneficio.component';
import { DialogbeneficiosComponent } from './componentes/shared/dialogbeneficios/dialogbeneficios.component';
import { NuevaPruebaComponent } from './componentes/nueva-prueba/nueva-prueba.component';
import { TablaPruebasComponent } from './componentes/tabla-pruebas/tabla-pruebas.component';
import { TablaBeneficiadosComponent } from './componentes/tabla-beneficiados/tabla-beneficiados.component';
import { TablaOfrecidosjgComponent } from './componentes/tabla-ofrecidosjg/tabla-ofrecidosjg.component';
import { TablaEquiposComponent } from './componentes/tabla-equipos/tabla-equipos.component';
import { NuevoequipoComponent } from './componentes/nuevoequipo/nuevoequipo.component';
import { DialogequipoComponent } from './componentes/shared/dialogequipo/dialogequipo.component';
import { ChatsComponent } from './componentes/chats/chats.component';
import { TablaTenisAmistososComponent } from './componentes/tabla-tenis-amistosos/tabla-tenis-amistosos.component';
import { DialogtenisamistosoComponent } from './componentes/shared/dialogtenisamistoso/dialogtenisamistoso.component';
import { NuevoamistosoComponent } from './componentes/nuevoamistoso/nuevoamistoso.component';

// DATETIME MATERIAL
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';

// PLUGINS
// import { QRCodeModule } from 'angularx-qrcode';
import { QrCodeModule } from 'ng-qrcode';
import { HomeComponent } from './home/home.component';
import { TablaInscriptosTenisComponent } from './componentes/tabla-inscriptos-tenis/tabla-inscriptos-tenis.component';
import { ChatsSinLeerComponent } from './componentes/chats-sin-leer/chats-sin-leer.component';
import { TablaBuscanRepresentanteComponent } from './componentes/tabla-buscan-representante/tabla-buscan-representante.component';
import { TablaOrganizadoresComponent } from './componentes/tabla-organizadores/tabla-organizadores.component';
import { NuevoorganizadorComponent } from './componentes/nuevoorganizador/nuevoorganizador.component';
import { DialogorganizadorComponent } from './componentes/shared/dialogorganizador/dialogorganizador.component';
import { ImagenesComponent } from './componentes/imagenes/imagenes.component';
import { CargaComponent } from './componentes/carga/carga.component';
import { CargaImagenesService } from './services/carga-imagenes.service';
import { NdDropFilesDirective } from './directives/nd-drop-files.directive';
import { VideoPlayerComponent } from './componentes/video-player/video-player.component';
import { VideoReconoComponent } from './componentes/video-recono/video-recono.component';
import { AltacaptadorComponent } from './componentes/altacaptador/altacaptador.component';
import { JugadorescatComponent } from './componentes/jugadorescat/jugadorescat.component';
import { BorrarCuentaComponent } from './componentes/borrarcuenta/borrarcuenta.component';
import { HttpClientModule } from '@angular/common/http';



@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    NuevaPruebaComponent,
    ChatsComponent,
    LoginComponent,
    TablaPruebasComponent,
    ModalComponent,
    CuerpoTecnicoComponent,
    DialogComponent,
    TablaBusquedasComponent,
    TablaClubesComponent,
    ChattingComponent,
    TablaInscriptosComponent,
    MensajeriamasComponent,
    TycComponent,
    NuevoclubComponent,
    DialogclubComponent,
    NuevopredioComponent,
    TablaPrediosComponent,
    DialogpredioComponent,
    BorrarCuentaComponent,
    CeComponent,
    JgComponent,
    CbComponent,
    CtComponent,
    LandingsComponent,
    PerfilComponent,
    GQRComponent,
    TablaPostuladosComponent,
    TablaOfrecidosComponent,
    TablaBeneficiosComponent,
    DialogbeneficiosComponent,
    NuevobeneficioComponent,
    TablaBeneficiadosComponent,
    TablaOfrecidosjgComponent,
    TablaEquiposComponent,
    NuevoequipoComponent,
    DialogequipoComponent,
    TablaTenisAmistososComponent,
    DialogtenisamistosoComponent,
    NuevoamistosoComponent,
    HomeComponent,
    TablaInscriptosTenisComponent,
    ChatsSinLeerComponent,
    TablaBuscanRepresentanteComponent,
    TablaOrganizadoresComponent,
    NuevoorganizadorComponent,
    DialogorganizadorComponent,
    ImagenesComponent,
    CargaComponent,
    NdDropFilesDirective,
    VideoPlayerComponent,
    VideoReconoComponent,
    AltacaptadorComponent,
    JugadorescatComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    HttpClientModule,
    // QRCodeModule
    QrCodeModule
  ],
//   entryComponents: [
//     ModalComponent,
//     DialogComponent,
//     DialogclubComponent,
//     DialogpredioComponent,
//     DialogbeneficiosComponent,
//     DialogequipoComponent,
//     DialogtenisamistosoComponent,
//     DialogorganizadorComponent
//   ],
  providers: [
    // { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    { provide: BUCKET, useValue: 'gs://mercadodepasesclub.appspot.com' },
    CargaImagenesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
