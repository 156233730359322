import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Predio } from 'src/app/models/interfaces.model';
import { PruebaService } from 'src/app/services/abm.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nuevopredio',
  templateUrl: './nuevopredio.component.html',
  styleUrls: ['./nuevopredio.component.scss']
})
export class NuevopredioComponent implements OnInit {

  @Input() predio: Predio; // Recibimos una prueba por parámetro
  predioForm = this.fb.group(
    {
      id: [''],
      Nombre: [''],
      Deportes: [],
      Domicilio: [''],
      Localidad: [''],
      Partido: [''],
      Provincia: [''],
      Pais: [''],
      Latitude: [0],
      Longitude: [0],
      usuario: '',
      clases: false
    });

  constructor(
    public pruebaSvc: PruebaService,
    public fb: UntypedFormBuilder
    ) {  }

  ngOnInit() {
    this.iniciarForm();
  }

  iniciarForm() {
    this.predioForm.patchValue({ ...this.predio});
  }

  addPredio (predio: Predio) {
    const FormCompleto = Boolean(predio.Nombre && predio.Deportes && predio.Domicilio && predio.Localidad && predio.Partido && predio.Provincia && predio.Pais);
    predio.Nombre = predio.Nombre.toUpperCase();
    if (!FormCompleto) {
      this.MensajePantallaError('Faltan Datos','Existen Errores en la carga de la información. Vuelva a cargarlo');
    } else {
        
        if (this.predioForm.get('id').value === '') {
          this.pruebaSvc.addPredio(predio);
        } else {
          this.pruebaSvc.editPredio(predio);
        }
      }
    }

  async MensajePantallaError(titulo: string, texto: string){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'error',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { });
  }
}
