<div class="nueva-prueba-container">
    <form [formGroup]="equipoForm" (ngSubmit)="addEquipo(equipoForm.value)">
        <mat-form-field>
            <input matInput type="text" placeholder="Equipo" formControlName="nombre" aria-label="Label">
        </mat-form-field>

        <mat-form-field>
            <input matInput type="text" placeholder="ID Equipo (4 CARACTERES y 4 DIGITOS)" formControlName="idequipo" aria-label="Label">
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="Seleccione una Categoria" formControlName="categoria">
                <mat-option *ngFor="let catequipo of ( catequipos | async )" [value]="catequipo.tipo">
                    {{ catequipo.tipo }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="Seleccione un Usuario" formControlName="delegado">
                <mat-option *ngFor="let usuario of ( usuarios | async )" [value]="usuario.correoelectronico">
                    <p style="text-transform: capitalize !important;">{{ usuario.apellido }}, {{ usuario.nombre }}</p>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="Seleccione un Organizador" formControlName="organizador">
                <mat-option *ngFor="let organizador of ( organizadores | async)" [value]="organizador.nombre">
                    {{ organizador.nombre }}
                </mat-option>
            </mat-select>
        </mat-form-field>



        <mat-form-field>
            <input matInput type="text" placeholder="URL del ESCUDO" formControlName="photoURL" aria-label="Label">
        </mat-form-field>

        <div class="container-button">
            <input type="submit" class="mat-flat-button" value="Guardar" [mat-dialog-close]="true">
        </div>
    </form>
</div>