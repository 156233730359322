<div class="nueva-prueba-container">
    <form [formGroup]="pruebaForm" (ngSubmit)="addPrueba(pruebaForm.value)">
        <mat-form-field *ngIf="this.add">
            <mat-select placeholder="Organizador" formControlName="Organizador">
                <mat-option *ngFor="let organizador of (organizadores | async)" [value]="organizador">
                    <img [src]="organizador.photoURL" width="15%" style="border-radius: 50%; border: solid 0.25px gray"> {{organizador.nombre}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="Juego" formControlName="Juego">
                <mat-option value="SINGLE">SINGLE</mat-option>
                <mat-option value="DOBLES">DOBLES</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="Predio" formControlName="Predio">
                <mat-option *ngFor="let predio of (predios | async)" [value]="predio.Nombre">
                    {{ predio.Nombre }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <input *ngIf="amistoso" matInput [ngxMatDatetimePicker]="picker" placeholder="Fecha a realizarse" [min]="minDate" formControlName="Fecha" [value]="amistoso.Fecha" readonly>
            <input *ngIf="!amistoso" matInput [ngxMatDatetimePicker]="picker" placeholder="Fecha a realizarse" [min]="minDate" formControlName="Evento" value="" readonly>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker> </ngx-mat-datetime-picker>
        </mat-form-field>

        <div class="container-button">
            <input type="submit" class="mat-flat-button" value="Guardar" [mat-dialog-close]="true">
        </div>
    </form>
</div>