<div class="nueva-prueba-container">
    <form [formGroup]="predioForm" (ngSubmit)="addPredio(predioForm.value)">
        <mat-form-field>
            <input matInput type="text" placeholder="Nombre del Predio o Campo de Deportes" formControlName="Nombre" aria-label="Label" />
        </mat-form-field>

        <mat-selection-list placeholder="Deportes" formControlName="Deportes">
            <mat-list-option matInput *ngFor="let deporte of ['F11','F7','F5','FS','FP','ES','TN']" [value]="deporte"> {{deporte}}
            </mat-list-option>
        </mat-selection-list>

        <mat-form-field>
            <input matInput type="text" placeholder="Domicilio" formControlName="Domicilio" aria-label="Label" />
        </mat-form-field>

        <mat-form-field>
            <input matInput type="text" placeholder="Localidad" formControlName="Localidad" aria-label="Label" />
        </mat-form-field>

        <mat-form-field>
            <input matInput type="text" placeholder="Partido" formControlName="Partido" aria-label="Label" />
        </mat-form-field>

        <mat-form-field>
            <input matInput type="text" placeholder="Provincia" formControlName="Provincia" aria-label="Label" />
        </mat-form-field>

        <mat-form-field>
            <input matInput type="text" placeholder="Pais" formControlName="Pais" aria-label="Label" />
        </mat-form-field>

        <mat-form-field>
            <input matInput type="text" placeholder="Latitud" formControlName="Latitude" aria-label="Label" />
        </mat-form-field>

        <mat-form-field>
            <input matInput type="text" placeholder="Longitud" formControlName="Longitude" aria-label="Label" />
        </mat-form-field>

        <!-- <button mat-raised-button color="primary" (click)="obtenerUbicacion()">Obtener mi Ubicación</button> -->
        
        <mat-form-field>
            <input matInput type="text" placeholder="Usuario Vinculado" formControlName="usuario" aria-label="Label" />
        </mat-form-field>
        <br>
        <br>
        <mat-slide-toggle checked formControlName="clases">
            <p>¿Ofrece Entrenamiento o Clases para Deportes?</p>
        </mat-slide-toggle>
        <br>
        <br>
        <div class="container-button">
            <input type="submit" class="mat-flat-button" value="Guardar" [mat-dialog-close]="true">
        </div>
    </form>
</div>