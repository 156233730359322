import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PruebaService } from '../../services/abm.service';

import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';
import { Prueba } from '../../models/interfaces.model';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-tabla-pruebas',
  templateUrl: './tabla-pruebas.component.html',
  styleUrls: ['./tabla-pruebas.component.scss']
})
export class TablaPruebasComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: string[] = ['Categoria', 'Club', 'Fecha', 'Deporte', 'Tipo de Deporte', 'Predio', 'Estado', 'QR','Editar','Lista Inscriptos','Inscriptos', 'Eliminar'];
  dataSource = new MatTableDataSource();
  isLoading = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  items: AngularFirestoreCollection<any>;
  public tabla: any;
  public estado: boolean;
  TitPrueba: string;
  tipoentidad: string;
  perfiladmin: boolean;

  constructor(
    private pruebaSvc: PruebaService,
    public dialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore
  ) { }

  ngOnDestroy(): void {
    // Realiza aquí cualquier limpieza necesaria antes de destruir el componente
  }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Pruebas por página';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  ngOnInit() {
    this.pruebas(true,false);
    this.estado = true;
  }

  pruebas(estado: boolean,vencidas: boolean){
    if (estado && !vencidas) {
      this.TitPrueba = 'Pruebas Activas';
    } else if (!estado && !vencidas) {
      this.TitPrueba = 'Pruebas Inactivas';
    } else if (vencidas){
      this.TitPrueba = 'Pruebas Vencidas en el tiempo';
    }
    this.dataSource.data = [];
    this.pruebaSvc.getPruebas(estado, vencidas).subscribe(
      pruebas => {
        pruebas.forEach(prueba => {
          this.items = this.afs.collection<any>('Inscripciones', ref => ref
                                              .where('id','==', prueba.ID));
          this.tabla = this.items.valueChanges().subscribe(
            resp => {
              prueba.Inscriptos = resp.length;
            }
          );
        }); 
        this.isLoading = false;
        this.dataSource.data = pruebas;
        this.estado = estado; 
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editar(prueba: Prueba) {
    this.openDialog(prueba);
  }

  inscriptos(prueba: Prueba) {
    this.listadoinscriptos(prueba.id);
  }

  borrar(prueba: Prueba) {
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Esta acción no se puede revertir',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then(
      aceptar => {
        if (aceptar.value) {
          this.pruebaSvc.deletePrueba(prueba)
          .then( () => {
            Swal.fire('Prueba eliminada', 'La prueba ha sido eliminada', 'success');
          })
          .catch((err) => {
            Swal.fire('Error!', 'La prueba no ha sido eliminada', 'error');
          });
        }
      });
  }

  openDialog(prueba?: Prueba): void {
    const config = {
      data: {
        message: prueba ? 'Editar Prueba' : 'Nueva Prueba',
        content: prueba
      }
    };
    const dialogRef = this.dialog.open(ModalComponent, config);
    dialogRef.afterClosed().subscribe(
      res => { }
    );
  }

  listadoinscriptos(id: string){
    this.router.navigateByUrl(`tabla-inscriptos/${id}`);
  }

  activar(prueba: Prueba){
    this.pruebaSvc.estadoPrueba(prueba, prueba.Activa);
  }

  nuevaPrueba() {
    this.openDialog();
  }

  genqr(prueba: Prueba){
    let predio = prueba.Predio.replace(/()\s/g,'€');
    let datos = `prueba_Cancha1@${predio}_${prueba.ID}`;
    this.router.navigateByUrl(`gqr/${datos}`);
  }
}
