import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PruebaService } from '../../services/abm.service';
import { Club } from 'src/app/models/interfaces.model';
import { DialogclubComponent } from '../shared/dialogclub/dialogclub.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-tabla-clubes',
  templateUrl: './tabla-clubes.component.html',
  styleUrls: ['./tabla-clubes.component.scss']
})
export class TablaClubesComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['Escudo', 'Club', 'Fundacion', 'Acciones'];
  dataSource = new MatTableDataSource();

  isLoading = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private pruebaSvc: PruebaService,
    public dialog: MatDialog
  ) { }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Clubes por página';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.pruebaSvc.getClubes().subscribe(
      clubes => {
        this.isLoading = false;
        this.dataSource.data = clubes;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  nuevo() {
    this.openDialog();
  }

  openDialog(club?: Club) {
    const config = {
      data: {
        message: club ? 'Editar Club' : 'Nuevo Club',
        content: club
      }
    };
    const dialogRef = this.dialog.open(DialogclubComponent, config);
    dialogRef.afterClosed().subscribe(
      res => {  }
    );
  }

  editar(club: Club) {
    this.openDialog(club);
  }

  borrar(club: Club) {
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Esta accion no se puede revertir',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then(
      aceptar => {
        if (aceptar.value) {
          this.pruebaSvc.deleteClub(club)
          .then( () => {
            Swal.fire('Pedido eliminado', 'El pedido ha sido eliminado', 'success');
          })
          .catch((err) => {
            Swal.fire('Error!', 'El pedido no ha sido eliminado', 'error');
          });
        }
      });
  }


}
