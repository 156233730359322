import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PruebaService } from '../../services/abm.service';

import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { Pedido } from 'src/app/models/interfaces.model';
import { DialogComponent } from '../shared/dialog/dialog.component';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';


@Component({
  selector: 'app-tabla-busquedas',
  templateUrl: './tabla-busquedas.component.html',
  styleUrls: ['./tabla-busquedas.component.scss']
})
export class TablaBusquedasComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['Fecha', 'Deporte', 'Actividad', 'Puesto', 'Solicitante', 'Acciones', 'Postulados'];
  dataSource = new MatTableDataSource();

  isLoading = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  items: AngularFirestoreCollection<Pedido>;

  constructor(
    private pruebaSvc: PruebaService,
    public dialog: MatDialog,
    private router: Router,
    private afs: AngularFirestore
  ) { }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Ofertas Laborales por página';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
  
    this.pruebaSvc.getPedidos().subscribe(
      pedidos => {
        pedidos.forEach(pedido => {
          this.items = this.afs.collection<any>('Postulaciones', ref => ref
                                              .where('idpedido','==', pedido.ID));
          this.items.valueChanges().subscribe(
            resp => {
              pedido.Postulados = resp.length;
            }
          );
        }); 
        this.isLoading = false;
        this.dataSource.data = pedidos;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editar(pedido: Pedido) {
    this.openDialog(pedido);
  }

  borrar(pedido: Pedido) {
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Esta accion no se puede revertir',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then(
      aceptar => {
        if (aceptar.value) {
          this.pruebaSvc.deletePedido(pedido)
          .then( () => {
            Swal.fire('Pedido eliminado', 'El pedido ha sido eliminado', 'success');
          })
          .catch((err) => {
            Swal.fire('Error!', 'El pedido no ha sido eliminado', 'error');
          });
        }
      });
  }

  nuevo() {
    this.openDialog();
  }

  openDialog(pedido?: Pedido) {
    const config = {
      data: {
        message: pedido ? 'Editar Oferta Laboral' : 'Nueva Oferta Laboral',
        content: pedido
      }
    };
    const dialogRef = this.dialog.open(DialogComponent, config);
    dialogRef.afterClosed().subscribe(
      res => {  }
    );
  }

  postulados(pedido: Pedido){
    this.router.navigateByUrl(`tabla-postulados/${pedido.ID}`);
  }
}
