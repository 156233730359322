<ng-container matColumnDef="Filtro-Botones">
    <button color="secondary" (click)="exportexcel()">
        <mat-icon>file_download</mat-icon><br>Exportar a Excel
    </button>
    <br>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar Beneficiados">
    </mat-form-field>
</ng-container>
<div>
    <h2>Listado de Beneficiados en: {{Titulo}}</h2>
    <table id="excel-table" mat-table [dataSource]="dataSource" matSort matSortActive="usuario" matSortDirection="asc" slot="fixed">

        <!-- Present Column -->
        <ng-container matColumnDef="aceptado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usarlo</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.aceptado">
                    <button style="text-align: center; background: lightgreen; color:gray; width: 40px" (click)="aceptar(row)"> 
                        <span class="material-icons">shopping_cart</span>
                    </button>
                </td>
                <td mat-cell *ngIf="row.aceptado">
                    <button style="text-align: center; background: gray; color:white; width: 40px" (click)="volveratras(row)">
                        <span class="material-icons">remove_shopping_cart</span>
                    </button>
                </td>
            </ng-container>
        </ng-container>

        <!-- Usuario Column -->
        <ng-container matColumnDef="usuario">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.usuario"> Cargando... </td>
                <td mat-cell *ngIf="row.usuario" style="text-align: left;"> {{row.usuario}}</td>
            </ng-container>
        </ng-container>

        <!-- Nombre Column -->
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.nombre"> Cargando... </td>
                <td mat-cell *ngIf="row.nombre" style="text-align: left;"> {{row.nombre}}</td>
            </ng-container>
        </ng-container>

        <!-- Apellido Column -->
        <ng-container matColumnDef="apellido">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Apellido</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.apellido"> Cargando... </td>
                <td mat-cell *ngIf="row.apellido" style="text-align: left;"> {{row.apellido}}</td>
            </ng-container>
        </ng-container>

        <!-- Tipo Documento Column -->
        <ng-container matColumnDef="tipodoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Doc</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.tipodoc"> Cargando... </td>
                <td mat-cell *ngIf="row.tipodoc==='1'"> DNI </td>
                <td mat-cell *ngIf="row.tipodoc==='2'"> PASAPORTE </td>
                <td mat-cell *ngIf="row.tipodoc==='3'"> CC </td>
                <td mat-cell *ngIf="row.tipodoc==='4'"> CI </td>
                <td mat-cell *ngIf="row.tipodoc==='5'"> TI </td>
                <td mat-cell *ngIf="row.tipodoc==='6'"> RC </td>
                <td mat-cell *ngIf="row.tipodoc==='7'"> DUI </td>
                <td mat-cell *ngIf="row.tipodoc==='8'"> ID </td>
                <td mat-cell *ngIf="row.tipodoc==='9'"> DPI </td>
                <td mat-cell *ngIf="row.tipodoc==='10'"> CURP </td>
                <td mat-cell *ngIf="row.tipodoc==='11'"> CIP </td>
            </ng-container>
        </ng-container>

        <!-- NroDoc Column -->
        <ng-container matColumnDef="nrodoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nro Documento</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.nrodoc"> Cargando... </td>
                <td mat-cell *ngIf="row.nrodoc" style="text-align: left;"> {{row.nrodoc}}</td>
            </ng-container>
        </ng-container>

        <!-- Nacionalidad Column -->
        <ng-container matColumnDef="nacionalidad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nacionalidad</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.nacionalidad"> Cargando... </td>
                <td mat-cell *ngIf="row.nacionalidad" style="text-align: left;"> {{row.nacionalidad}}</td>
            </ng-container>
        </ng-container>

        <!-- Telefono Column -->
        <ng-container matColumnDef="telefono">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefono</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.telefono"> Cargando... </td>
                <td mat-cell *ngIf="row.telefono" style="text-align: left;"> {{row.telefono}}</td>
            </ng-container>
        </ng-container>

        <!-- Fecha Nacimiento Column -->
        <ng-container matColumnDef="fechanac">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Nac</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.fechanac"> MAL CARGADA </td>
                <td mat-cell *ngIf="row.fechanac">{{row.fechanac | date: 'dd/MM/yyyy'}}</td>
            </ng-container>
        </ng-container>

        <!-- IdBeneficiado Column -->
        <ng-container matColumnDef="idbeneficiado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.idbeneficiado"> Cargando... </td>
                <td mat-cell *ngIf="row.idbeneficiado" style="text-align: left;"> {{row.idbeneficiado}}</td>
            </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </mat-card>

    <mat-paginator [pageSizeOptions]="[25, 50, 100, 150]" showFirstLastButtons></mat-paginator>

</div>