import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PruebaService } from 'src/app/services/abm.service';
import { Observable } from 'rxjs';
import { Club, Pedido } from 'src/app/models/interfaces.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cuerpo-tecnico',
  templateUrl: './cuerpo-tecnico.component.html',
  styleUrls: ['./cuerpo-tecnico.component.scss']
})
export class CuerpoTecnicoComponent implements OnInit {

  @Input() pedido: Pedido; // Recibimos un pedido por parámetro
  
  clubes: Observable<Club[]>;
  PhotoURL: string;

  ctForm = this.fb.group({
    Fecha: [new Date().getTime()],
    Solicitante: [],
    ID: [''],
    Deporte: [''],
    Sexo: [''],
    Actividad: [''],
    Puesto: [''],
    pportecomun: [false ],
    PhotoURL: ['']
  });

  constructor(
    private fb: UntypedFormBuilder,
    public pruebaSvc: PruebaService,
  ) { }

  ngOnInit() {
    this.clubes = this.pruebaSvc.getClubes();
    this.iniciarForm();
  }

  addBusqueda(pedido: Pedido) {
    if (this.ctForm.get('ID').value === '') {
      pedido.PhotoURL = pedido.Solicitante.photoURL;
      pedido.Solicitante = pedido.Solicitante.Club;
      this.pruebaSvc.addPedido(pedido);
    } else {
      this.pruebaSvc.editPedido(pedido); }
  }

  iniciarForm() {
    this.ctForm.patchValue({ ...this.pedido});
  }
  
  async MensajePantallaError(titulo: string, texto: string){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'error',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { });
  }


}
