<div class="form-container">
    <form [formGroup]="ctForm" (ngSubmit)="addBusqueda(ctForm.value)">

        <mat-form-field>
            <mat-select placeholder="Club Solicitante" formControlName="Solicitante">
                <mat-option *ngFor="let club of (clubes | async)" [value]="club">
                    <img [src]="club.photoURL" width="5%" style="border-radius: 50%; border: solid 0.25px gray"> {{club.Club}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="Deporte" formControlName="Deporte">
                <mat-option value="F11">FUTBOL 11</mat-option>
                <mat-option value="FS">FUTSAL</mat-option>
                <mat-option value="FP">FUTBOL PLAYA</mat-option>
                <mat-option value="ES">ESPORTS</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-radio-group aria-labelledby="Tipo de deporte" formControlName="Sexo">
            <mat-radio-button value="M">Masculino</mat-radio-button>
            <mat-radio-button value="F">Femenino</mat-radio-button>
        </mat-radio-group>

        <mat-form-field>
            <mat-select placeholder="Calidad Deportiva" formControlName="Actividad">
                <mat-option value="AM">Amateur</mat-option>
                <mat-option value="PR">Profesional</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="Puesto" formControlName="Puesto">
                <mat-option *ngIf="ctForm.get('Actividad').value === 'PR'" value="AG">Analista de GPS</mat-option>
                <mat-option *ngIf="ctForm.get('Actividad').value === 'PR'" value="AV">Analista de Videos</mat-option>
                <mat-option value="AC">Ayudante de Campo</mat-option>
                <mat-option value="DT">Director Técnico</mat-option>
                <mat-option value="EA">Entrenador de Arqueros</mat-option>
                <mat-option *ngIf="ctForm.get('Actividad').value === 'PR'" value="KG">Kinesiólogo</mat-option>
                <mat-option *ngIf="ctForm.get('Actividad').value === 'PR'" value="MD">Médico</mat-option>
                <mat-option value="PF">Preparador Físico</mat-option>
                <mat-option *ngIf="ctForm.get('Actividad').value === 'PR'" value="PS">Psicologo</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-slide-toggle formControlName="pportecomun">
            ¿Pasaporte Comunitario de la Unión Europea?
        </mat-slide-toggle>

        <div class="container-button">
            <input type="submit" class="mat-flat-button" value="Guardar" [mat-dialog-close]="true">
        </div>
    </form>
</div>