export class Prueba {
    fechaCreacion?: string;
    Activa: boolean;
    ID?: string;
    id?: string;
    Club: any;
    Categoria: string;
    Evento: any;
    Fecha?: any;
    Predio: string;
    Latitud?: number;
    Longitud?: number;
    Provincia?: string;
    Pais?: string;
    Entrenamiento: boolean;
    Pasaporte: boolean;
    Pierna: string;
    Posicion: string;
    Deporte: string;
    Sexo: string;
    PhotoURL: string;
    Suspendida?: boolean;
    Requisitos?: string;
    Inscriptos?: number;
    Llamar?: boolean;
    Contacto?: string;
    Telefono?: string;
    Directo?: boolean;
    WhatsApp?: boolean;
    Antecedentes?: boolean;
    viaEM?: boolean;
    Email?: string;
    limite?: number;
    Estado?: string;
    constructor() {
        this.Activa = true;
        this.Llamar = false;
        this.WhatsApp = false;
        this.Antecedentes = false;
        this.limite = 0;
    }
}

export class Pedido {
    ID: string;
    Fecha: number;
    Solicitante: any;
    Deporte: string;
    Sexo: string;
    Actividad: string;
    Puesto: string;
    pportecomun: boolean;
    PhotoURL: string;
    Postulados?: number;
}

export class Club {
    ID: string;
    id: string;
    Club: string;
    Fundacion: string;
    photoURL: string;
}

export class Predio {
    Domicilio: string;
    Latitude: number;
    Localidad: string;
    Longitude: number;
    Nombre: string;
    Pais: string;
    Partido: string;
    Provincia: string;
    Deportes: string[];
    usuario?: string;
    clases?: boolean;
}

export class JugadorModel {
    correoelectronico: string;
    nombre: string;
    apellido: string;
    tipodoc: string;
    nrodoc: number;
    pportecomun: boolean;
    antecedentes: boolean;
    nacionalidad: string;
    tipoperfil: string;
    sexo: string;
    fechanac: number | string;
    telefono: string;
    actividad: string;
    posicion: string;
    pierna: string;
    deporte: string;
    perfilok: boolean;
    photoURL: string;
    Latitud?: number;
    Longitud?: number;
    licencia?: string;
    tipolicencia?: string;
    entidad?: string;
    tipoentidad?: string;
    delegado?: boolean;
    idequipo?: string;
    admin?: boolean;
    representante?: boolean;
    ultimologin?: Date;
    activacion?: Date;
    version: string;
    chequeado?: boolean;

    constructor() {}
}

export class VideoModel {

    public fechasubida: number;
    public usuario: string;
    public video: string;
}

export class Ofrecido {
    id: string;
    fecha: number;
    club: any;
    deporte: string;
    sexo: string;
    actividad: string;
    tipolicencia?: string;
    datosadicionales: string;
    puesto?: string;
    telefono?: string;
    usuario: string;
    vinculo?: boolean;
    videos?: VideoModel[];
}

export class Beneficio {
    id?: string;
    nombre: string;
    tipo: string;
    activo?: boolean;
    cantbeneficiados?: number;
}

export class Beneficiados {
    fecha: number;
    idbi: string;
    usuario: string;
    aceptado?: boolean;
    idbeneficiado?: string;
}

export class Equipo {
    ID: string;
    idequipo: string;
    aprobado: boolean;
    categoria: string;
    delegado: string;
    fechaalta: number;
    fechaultimamod: number;
    nombre: string;
    organizador: string;
    photoURL: string;
}

export class Organizador {

    estado: boolean;
    ID: string;
    deportes: string[];
    nombre: string;
    usuarios: string[];
    photoURL: string;
}

export class CatEquiposAmateurs {

    estado: boolean;
    tipo: string;
}

export class Amistoso {
    fechaCreacion?: string;
    Abierto?: boolean;
    Activo: boolean;
    ID?: string;
    id?: string;
    Categoria?: string;
    Organizador: any;
    Juego: string;
    Evento: any;
    Fecha?: any;
    Predio: string;
    Deporte: string;
    Sexo: string;
    PhotoURL: string;
    Suspendida?: boolean;
    Inscriptos?: number;
    Contacto?: string;
    Directo?: boolean;
    WhatsApp?: boolean;
    Telefono?: string;
    constructor() {
        this.Activo = true;
        this.WhatsApp = false;
    }
}