<main id="home-producto" class="producto-jugador-amateur">

    <section class="hero is-fullheight">
        <div class="container is-marginless">
            <h1 style="color:white; text-align: center; margin-top:10px">JUGADORES</h1><br>
            <h3 style="color:white; text-align: center;">AMATEURS, SENIORS y PROFESIONALES</h3><br><br><br>
            <h2 style="color:white; text-align: center;">
                Todo lo que necesitas para vincularte con el mundo del fútbol a un clic de distancia.
            </h2>
        </div>
    </section>

    <section class="section">
        <div class="container is-fluid">

            <div class="beneficios-section">
                <div class="columns is-multiline is-centered">
                    <div class="column is-full has-text-centered">
                        <h3 style="text-align: center;">¿CUALES SON LOS BENEFICIOS?</h3>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../assets/images/icon-mdp_JA_01.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">¿Quieres que te conozcan?</h5>
                                        <p class="is-size-5">Con Mercado de Pases podrás entrar en contacto con el universo del fútbol profesional y amateur. Podrás incluir en tu perfil, videos, fichas técnicas y descripciones para que todos los interesados, conozcan tus
                                            habilidades futbolísticas.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../assets/images/icon-mdp_JA_03.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">Integra la mayor red de jugadores del fútbol mundial.</h5>
                                        <p class="is-size-5">Podrás chatear y conectarte de manera directa con clubes, jugadores, representantes y demás miembros de nuestra exclusiva comunidad.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../assets/images/icon-mdp_JA_04.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">¡Entrénate!</h5>
                                        <p class="is-size-5">Encuentra las mejores opciones para tu entrenamiento.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../assets/images/icon-mdp_JA_05.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">Participa de torneos amateurs.</h5>
                                        <p class="is-size-5">Registrándote en Mercado de Pases podrás encontrar y participar de los mejores torneos amateurs que se realizan alrededor del mundo. Además podrás filtrar tu búsqueda por edad, distancia y costo.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../assets/images/icon-mdp_JA_06.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">Fútbol profesional.</h5>
                                        <p class="is-size-5">Personaliza tu perfil para recibir alertas acerca de las pruebas de jugadores que los clubes profesionales realizan. ¡Da el gran giro que buscas para tu carrera, de la mano de Mercado de Pases!</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../assets/images/icon-mdp_JA_07.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">¡Encuentra equipo!</h5>
                                        <p class="is-size-5">Muestra tu perfil a todos los clubes miembros de Mercado de Pases, para que puedas ser convocado. Si quieres recibir alertas puntuales, podrás programarlas de acuerdo a lo que buscas.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </div>

            <div class="columns is-multiline is-centered call-to-action">
                <div class="column is-full has-text-centered">
                    <h3>ES TU OPORTUNIDAD</h3>
                    <p class="is-size-1">Accedé a todos los beneficios ahora!</p>
                </div>
            </div>
        </div>
    </section>

</main>