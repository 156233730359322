<ng-container matColumnDef="Filtro-Botones">
    <button color="secondary" (click)="exportexcel()">
        <mat-icon>file_download</mat-icon><br>Exportar a Excel
    </button>
    <br>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar Usuarios">
    </mat-form-field>
</ng-container>
<div>
    <h2>Listado de Anotados</h2>
    <table id="excel-table" mat-table [dataSource]="dataSource" matSort>

        <!-- Present Column -->
        <ng-container matColumnDef="presente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Concurrencia</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.presente" style="text-align: center; background: red; color:white"> AUSENTE </td>
                <td mat-cell *ngIf="row.presente" style="text-align: center; background: green; color:white"> PRESENTE </td>
            </ng-container>
        </ng-container>

        <!-- Usuario Column -->
        <ng-container matColumnDef="usuario">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.usuario"> Cargando... </td>
                <td mat-cell *ngIf="row.usuario" style="text-align: left;"> {{row.usuario}}</td>
            </ng-container>
        </ng-container>

        <!-- Nombre Column -->
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.nombre"> Cargando... </td>
                <td mat-cell *ngIf="row.nombre" style="text-align: left;"> {{row.nombre}}</td>
            </ng-container>
        </ng-container>

        <!-- Apellido Column -->
        <ng-container matColumnDef="apellido">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Apellido</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.apellido"> Cargando... </td>
                <td mat-cell *ngIf="row.apellido" style="text-align: left;"> {{row.apellido}}</td>
            </ng-container>
        </ng-container>

        <!-- Tipo Documento Column -->
        <ng-container matColumnDef="tipodoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Doc</th>
            <ng-container *matCellDef="let row">
                <td mat-cell style="text-align: left;" *ngIf="!row.tipodoc"> Cargando... </td>
                <td mat-cell style="text-align: left;" *ngIf="row.tipodoc==='1'"> DNI </td>
                <td mat-cell style="text-align: left;" *ngIf="row.tipodoc==='2'"> PASAPORTE </td>
                <td mat-cell style="text-align: left;" *ngIf="row.tipodoc==='3'"> CC </td>
                <td mat-cell style="text-align: left;" *ngIf="row.tipodoc==='4'"> CI </td>
                <td mat-cell style="text-align: left;" *ngIf="row.tipodoc==='5'"> TI </td>
                <td mat-cell style="text-align: left;" *ngIf="row.tipodoc==='6'"> RC </td>
                <td mat-cell style="text-align: left;" *ngIf="row.tipodoc==='7'"> DUI </td>
                <td mat-cell style="text-align: left;" *ngIf="row.tipodoc==='8'"> ID </td>
                <td mat-cell style="text-align: left;" *ngIf="row.tipodoc==='9'"> DPI </td>
                <td mat-cell style="text-align: left;" *ngIf="row.tipodoc==='10'"> CURP </td>
                <td mat-cell style="text-align: left;" *ngIf="row.tipodoc==='11'"> CIP </td>
            </ng-container>
        </ng-container>

        <!-- NroDoc Column -->
        <ng-container matColumnDef="nrodoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nro Documento</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.nrodoc"> Cargando... </td>
                <td mat-cell *ngIf="row.nrodoc" style="text-align: left;"> {{row.nrodoc}}</td>
            </ng-container>
        </ng-container>

        <!-- Nacionalidad Column -->
        <ng-container matColumnDef="nacionalidad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nacionalidad</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.nacionalidad"> Cargando... </td>
                <td mat-cell *ngIf="row.nacionalidad" style="text-align: left;"> {{row.nacionalidad}}</td>
            </ng-container>
        </ng-container>

        <!-- Telefono Column -->
        <ng-container matColumnDef="telefono">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefono</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.telefono"> Cargando... </td>
                <td mat-cell *ngIf="row.telefono" style="text-align: left;"> {{row.telefono}}</td>
            </ng-container>
        </ng-container>

        <!-- Fecha Nacimiento Column -->
        <ng-container matColumnDef="fechanac">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Nac</th>
            <ng-container *matCellDef="let row">
                <td mat-cell style="text-align: left;" *ngIf="!row.fechanac"> MAL CARGADA </td>
                <td mat-cell style="text-align: left;" *ngIf="row.fechanac">{{row.fechanac | date: 'dd/MM/yyyy'}}</td>
            </ng-container>
        </ng-container>

        <!-- Actividad Column -->
        <ng-container matColumnDef="actividad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actividad</th>
            <ng-container *matCellDef="let row">
                <td mat-cell style="text-align: left;" *ngIf="!row.actividad"> Cargando... </td>
                <td mat-cell style="text-align: left;" *ngIf="row.actividad==='PC'"> PRINCIPIANTE </td>
                <td mat-cell style="text-align: left;" *ngIf="row.actividad==='AP'"> APRENDIZ </td>
                <td mat-cell style="text-align: left;" *ngIf="row.actividad==='IM'"> INTERMEDIO </td>
                <td mat-cell style="text-align: left;" *ngIf="row.actividad==='AV'"> AVANZADO </td>
            </ng-container>
        </ng-container>

        <!-- Herramientas Column -->
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chat</th>
            <td mat-cell style="text-align: left;" *matCellDef="let row">
                <button *ngIf="row.sinleer" style="background: red;" mat-stroked-button (click)="chat(row.usuario)">
                  <mat-icon style="color:gold">chat</mat-icon>
                </button>
                <button *ngIf="!row.sinleer" mat-stroked-button (click)="chat(row.usuario)">
                  <mat-icon style="color: green;">chat</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </mat-card>

    <mat-paginator [pageSizeOptions]="[25, 50, 100, 150]" showFirstLastButtons></mat-paginator>

</div>