import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Club } from 'src/app/models/interfaces.model';
import { PruebaService } from 'src/app/services/abm.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nuevoclub',
  templateUrl: './nuevoclub.component.html',
  styleUrls: ['./nuevoclub.component.scss']
})
export class NuevoclubComponent implements OnInit {

  @Input() club: Club; // Recibimos una prueba por parámetro
  PhotoURL: string;

  clubForm = this.fb.group(
    {
      ID: [''],
      id:[''],
      Club: [''],
      Fundacion: [''],
      photoURL: ['']
    });

  constructor(
    public pruebaSvc: PruebaService,
    public fb: UntypedFormBuilder
    ) {}

  ngOnInit() {
    this.iniciarForm();
  }

  iniciarForm() {
    this.clubForm.patchValue({ ...this.club});
  }

  addClub(club: Club) {
    const FormCompleto = Boolean(club.Club && club.Fundacion && club.photoURL);
    if (!FormCompleto) {
      this.MensajePantallaError('Faltan Datos','Existen Errores en la carga de la información. Vuelva a cargarlo');
    } else {
        if (this.clubForm.get('ID').value === '') {
          this.pruebaSvc.addClub(club);
        } else {
          this.pruebaSvc.editClub(club);
        }
      }
    }

  async MensajePantallaError(titulo: string, texto: string){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'error',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { });
  }
}
