<ng-container matColumnDef="Filtro-Botones">
    <button style="background-color:rgb(206, 24, 33); color:white;" (click)="exportAsPDF('MyDIv')">
        <mat-icon>file_download</mat-icon> Exportar a PDF
    </button>
</ng-container>

<div id="MyDIv" style="width:400px;text-align: center;" class="main-container">
    <!-- <qrcode [qrdata]="value" [width]="400" [errorCorrectionLevel]="'M'">
    </qrcode> -->
    <qr-code [value]="value" [size]="400" errorCorrectionLevel="M"></qr-code>
    <img src="../../favicon.ico" style="width: 20px" />
    <span style="padding: 2%;font-size: 20px;">MercadoDePases.Club</span>
    <h2 style="font-size:10px;text-align: center;">{{Pie}}</h2>
    <h2 style="font-size:10px;text-align: center;">{{Titulo}}</h2>
</div>