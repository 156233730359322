<!-- <main> -->
<section *ngIf="muestro" style="padding:30px;text-align: center;">
    <form [formGroup]="signInForm" (ngSubmit)="signIn()">
        <h2>Bienvenido</h2>
        <ng-container>
            <p>Ingrese sus Credenciales</p>
            <mat-form-field [formGroup]="signInForm">
                <input matInput placeholder="Email" [formControl]="email" required>
                <mat-error *ngIf="!email.valid">No es un email valido</mat-error>
            </mat-form-field>

            <mat-form-field [formGroup]="signInForm">
                <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" [formControl]="password" required>
                <mat-icon matSuffix (click)="hide = !hide"> {{ hide ? 'visibility' : 'visibility_off'}} </mat-icon>
                <mat-error *ngIf="!password.valid">Password requerido</mat-error>
            </mat-form-field>
            <button mat-raised-button type="submit" color="accent">Enviar</button>
        </ng-container>
    </form>
</section>
<!-- </main> -->