import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';

@Component({
  selector: 'app-gqr',
  templateUrl: './gqr.component.html',
  styleUrls: ['./gqr.component.scss']
})
export class GQRComponent implements OnInit {

  value: string;
  Titulo: string;
  Pie: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
            
            this.value = this.route.snapshot.paramMap.get('value');
            this.value = this.value.replace(/(€)/g,' ');
            const texto = this.value.split('_');
            let predio = texto[1].split('@');
            if (predio[1]){
              this.Titulo = `Predio: ${predio[1]}`;
            } else {
              this.Titulo = '';
            }
            this.Pie = `ID: ${texto[2]}`;
  }

  exportAsPDF(div_id: any)
  {
    let data = document.getElementById(div_id);  
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png')  
      // let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
      let pdf = new jspdf('p', 'cm', 'a4'); // Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'PNG', 0, 0, 20, 20);  
      pdf.save(`${this.Titulo}.pdf`);   
    }); 
  }

}
