import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/operators';
import { Mensaje } from '../models/chat.model';

@Injectable({
  providedIn: 'root'
})
export class AlertasService {

  uid: string = 'contacto@mercadodepases.club';
  sumatoria: number;
  private itemsCollection: AngularFirestoreCollection<Mensaje>;

  constructor (private db: AngularFirestore) { }

  AlertaMensajes(uid: string | void) {
    this.sumatoria = 0;
    if (!uid) {
      this.itemsCollection = this.db.collection<Mensaje>('chats', ref => ref.orderBy('fecha', 'desc').limit(20));
    } else {
      this.itemsCollection = this.db.collection<Mensaje>('chats', ref => ref.where ('chat', '==', uid)
                                                                  .orderBy('fecha', 'desc').limit(20));
    }
    return this.itemsCollection.valueChanges().pipe(
        map((mensajes: Mensaje[]) => {
          this.sumatoria = 0;
          for (const mensaje of mensajes ) {
            if (mensaje.uid !== this.uid){
              if(!mensaje.leidoreceptor){
                this.sumatoria = this.sumatoria + 1;
              }
            }
          }
          return this.sumatoria;
        }
      )
    );          
  }
}
