// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyB9f25Is9X92ZLj7wanmDkjiIAc2kOcO9Q',
    authDomain: 'mercadodepasesclub.firebaseapp.com',
    databaseURL: 'https://mercadodepasesclub.firebaseio.com',
    projectId: 'mercadodepasesclub',
    storageBucket: 'mercadodepasesclub.appspot.com',
    messagingSenderId: '221764039584',
    appId: '1:221764039584:web:1807551095958bc3d1aac5',
    measurementId: 'G-9TZQ856N6X',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
