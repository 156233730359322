import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { AlertasService } from 'src/app/services/alertas.service';
import { Mensaje } from 'src/app/models/chat.model';
import Swal from 'sweetalert2';
import { PruebaService } from 'src/app/services/abm.service';
import { Ofrecido } from 'src/app/models/interfaces.model';

@Component({
  selector: 'app-tabla-ofrecidosjg',
  templateUrl: './tabla-ofrecidosjg.component.html',
  styleUrls: ['./tabla-ofrecidosjg.component.scss']
})
export class TablaOfrecidosjgComponent implements OnInit {

    public itemchat: AngularFirestoreCollection<Mensaje>;
    public itemusu: AngularFirestoreCollection<any>
    tabla: any;
    tablausu: any;
    displayedColumns: string[] = ['usuario', 'club', 'deporte', 'actividad', 'sexo', 'puesto', 'telefono', 'datosadicionales', 'Acciones'];
    dataSource = new MatTableDataSource();
    isLoading = true;
    public id: string;
    
  
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    perfilActividad: string;
    perfilPuesto: string;
    perfilsexo: any;
    perfilDeporte: any;
    archivo: string;
    pruebaSvc: any;
  
    constructor  ( 
                   private router: Router,
                   public alertaSvc: AlertasService,
                   public svcabm: PruebaService
                  ) {}
                
  
    ngAfterViewInit() {
      this.paginator._intl.itemsPerPageLabel = 'Curriculums por página';
      this.dataSource.paginator = this.paginator;
      this.dataSource.data.splice(0);
      this.dataSource.sort = this.sort;
    }
  
    ngOnInit(){
    this.svcabm.getCV().subscribe(
      (ofrecidos: any) => {
        ofrecidos.forEach((resp: Ofrecido) => {
          if (resp.datosadicionales.substr(0,4)==='http'){
            resp.vinculo = true;
          }
        });
        this.isLoading = false;
        this.dataSource.data = ofrecidos;
      });
  }
    applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  
    chat( usuario: string ) {
      if (!sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh')){
        this.router.navigateByUrl(`/chatting/${usuario}`);
      } else {
        this.MensajePantallaInfo('MUY PRONTO', 'Esta función está en desarrollo')
      }

    }

    async MensajePantallaInfo(titulo: string, texto: string){
      Swal.fire({
        title: titulo,
        text: texto,
        icon: 'info',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
      }).then(() => { });
    }
  
    exportexcel(){
      this.svcabm.exportexcel('ofrecimientos',[7]);
    }

    verdatos(usuario: string){
      this.router.navigateByUrl(`/perfil/${usuario}`);
    }

    considerar(ofrecimiento: Ofrecido) {
      Swal.fire({
        title: 'Se le informará al usuario que se pone en consideración su CV ¿Está seguro?',
        text: 'Esta accion no se puede revertir',
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(
        aceptar => {
          if (aceptar.value) {
            this.svcabm.enviarconsideracionCV(ofrecimiento)
            .then( () => {
              Swal.fire('CV considerado', 'Se ha informado al usuario que su CV fue puesto en consideración por su entidad deportiva y que se le irá avisando los avances que se produzcan.', 'success');
            })
            .catch((err) => {
              Swal.fire('Error!', 'El CV no ha podido ser considerado por error en la red', 'error');
            });
          }
        });
    }

    borrar(ofrecimiento: Ofrecido) {
      Swal.fire({
        title: '¿Está seguro?',
        text: 'Esta accion no se puede revertir',
        icon: 'warning',
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
      }).then(
        aceptar => {
          if (aceptar.value) {
            this.svcabm.deleteCV(ofrecimiento)
            .then( () => {
              Swal.fire('CV eliminado', 'El CV seleccionado ha sido eliminado', 'success');
            })
            .catch((err) => {
              Swal.fire('Error!', 'El CV no ha sido eliminado por problemas de conexión', 'error');
            });
          }
        });
    }
  }