import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NuevaPruebaComponent } from './componentes/nueva-prueba/nueva-prueba.component';
import { ChatsComponent } from './componentes/chats/chats.component';
import { LoginComponent } from './componentes/login/login.component';
import { TablaPruebasComponent } from './componentes/tabla-pruebas/tabla-pruebas.component';
import { CuerpoTecnicoComponent } from './componentes/cuerpo-tecnico/cuerpo-tecnico.component';
import { TablaBusquedasComponent } from './componentes/tabla-busquedas/tabla-busquedas.component';
import { TablaClubesComponent } from './componentes/tabla-clubes/tabla-clubes.component';
import { ChattingComponent } from './componentes/chatting/chatting.component';
import { TablaInscriptosComponent } from './componentes/tabla-inscriptos/tabla-inscriptos.component';
import { MensajeriamasComponent } from './componentes/mensajeriamas/mensajeriamas.component';
import { TycComponent } from './componentes/tyc/tyc.component';
import { JgComponent } from './componentes/landing/jg/jg.component';
import { CbComponent } from './componentes/landing/cb/cb.component';
import { CeComponent } from './componentes/landing/ce/ce.component';
import { CtComponent } from './componentes/landing/ct/ct.component';
import { LandingsComponent } from './componentes/landing/landings/landings.component';
import { TablaPrediosComponent } from './componentes/tabla-predios/tabla-predios.component';
import { PerfilComponent } from './componentes/perfil/perfil.component';
import { GQRComponent } from './componentes/gqr/gqr.component';
import { TablaPostuladosComponent } from './componentes/tabla-postulados/tabla-postulados.component';
import { TablaOfrecidosComponent } from './componentes/tabla-ofrecidos/tabla-ofrecidos.component';
import { TablaBeneficiosComponent } from './componentes/tabla-beneficios/tabla-beneficios.component';
import { TablaBeneficiadosComponent } from './componentes/tabla-beneficiados/tabla-beneficiados.component';
import { TablaOfrecidosjgComponent } from './componentes/tabla-ofrecidosjg/tabla-ofrecidosjg.component';
import { TablaEquiposComponent } from './componentes/tabla-equipos/tabla-equipos.component';
import { TablaTenisAmistososComponent } from './componentes/tabla-tenis-amistosos/tabla-tenis-amistosos.component';
import { HomeComponent } from './home/home.component';
import { TablaInscriptosTenisComponent } from './componentes/tabla-inscriptos-tenis/tabla-inscriptos-tenis.component';
import { ChatsSinLeerComponent } from './componentes/chats-sin-leer/chats-sin-leer.component';
import { TablaBuscanRepresentanteComponent } from './componentes/tabla-buscan-representante/tabla-buscan-representante.component';
import { TablaOrganizadoresComponent } from './componentes/tabla-organizadores/tabla-organizadores.component';
import { CargaComponent } from './componentes/carga/carga.component';
import { ImagenesComponent } from './componentes/imagenes/imagenes.component';
import { VideoPlayerComponent } from './componentes/video-player/video-player.component';
import { VideoReconoComponent } from './componentes/video-recono/video-recono.component';
import { AltacaptadorComponent } from './componentes/altacaptador/altacaptador.component';
import { JugadorescatComponent } from './componentes/jugadorescat/jugadorescat.component';
import { BorrarCuentaComponent } from './componentes/borrarcuenta/borrarcuenta.component';
import { CuidarloginGuard } from './cuidarlogin.guard';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent  },
  { path: 'login', component: LoginComponent  },
  { path: 'pruebas', component: TablaPruebasComponent , canActivate:[CuidarloginGuard] },
  { path: 'pedidos', component: TablaBusquedasComponent, canActivate:[CuidarloginGuard]  },
  { path: 'clubes', component: TablaClubesComponent, canActivate:[CuidarloginGuard]  },
  { path: 'predios', component: TablaPrediosComponent, canActivate:[CuidarloginGuard]  },
  { path: 'equipos', component: TablaEquiposComponent , canActivate:[CuidarloginGuard] },
  { path: 'nueva-prueba', component: NuevaPruebaComponent, canActivate:[CuidarloginGuard]  },
  { path: 'chats', component: ChatsComponent, canActivate:[CuidarloginGuard]  },
  { path: 'ct', component: CuerpoTecnicoComponent , canActivate:[CuidarloginGuard] },
  { path: 'chatting/:usuario', component: ChattingComponent , canActivate:[CuidarloginGuard] },
  { path: 'tabla-inscriptos/:id', component: TablaInscriptosComponent, canActivate:[CuidarloginGuard]  },
  { path: 'tabla-postulados/:id', component: TablaPostuladosComponent, canActivate:[CuidarloginGuard]  },
  { path: 'mensajeriamas', component: MensajeriamasComponent, canActivate:[CuidarloginGuard]  },
  { path: 'tyc', component: TycComponent  },
  { path: 'jg', component: JgComponent, canActivate:[CuidarloginGuard]  },
  { path: 'cb', component: CbComponent, canActivate:[CuidarloginGuard]  },
  { path: 'ce', component: CeComponent, canActivate:[CuidarloginGuard]  },
  { path: 'dt', component: CtComponent , canActivate:[CuidarloginGuard] },
  { path: 'borrarcuenta', component: BorrarCuentaComponent },
  // { path: 'landings', component: LandingsComponent, canActivate:[CuidarloginGuard]  },
  { path: 'perfil/:usuario', component: PerfilComponent , canActivate:[CuidarloginGuard] },
  { path: 'gqr/:value', component: GQRComponent , canActivate:[CuidarloginGuard] },
  { path: 'ofrecidos', component: TablaOfrecidosComponent, canActivate:[CuidarloginGuard] },
  { path: 'ofrecidosjg', component: TablaOfrecidosjgComponent, canActivate:[CuidarloginGuard]  },
  { path: 'beneficios', component: TablaBeneficiosComponent, canActivate:[CuidarloginGuard]  },
  { path: 'tabla-beneficiados/:id', component: TablaBeneficiadosComponent , canActivate:[CuidarloginGuard] },
  // { path: 'amistosostenis', component: TablaTenisAmistososComponent, canActivate:[CuidarloginGuard] },
  // { path: 'tabla-inscriptos-tenis/:id', component: TablaInscriptosTenisComponent, canActivate:[CuidarloginGuard]  },
  { path: 'chats-sin-leer/:entidad', component: ChatsSinLeerComponent, canActivate:[CuidarloginGuard]  },
  { path: 'buscanrepresentantes', component: TablaBuscanRepresentanteComponent, canActivate:[CuidarloginGuard] },
  { path: 'organizadores', component: TablaOrganizadoresComponent, canActivate:[CuidarloginGuard]  },
  { path: 'carga', component: CargaComponent, canActivate:[CuidarloginGuard]  },
  { path: 'imagenes', component: ImagenesComponent, canActivate:[CuidarloginGuard]  },
  { path: 'videorecog', component: VideoReconoComponent, canActivate:[CuidarloginGuard]  },
  { path: 'altacaptador/:usuario', component: AltacaptadorComponent, canActivate:[CuidarloginGuard]  },
  { path: 'jugadorescat', component: JugadorescatComponent, canActivate:[CuidarloginGuard]  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
