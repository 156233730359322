<ng-container matColumnDef="Filtro-Botones">
    <button class="boton-menu" color="secondary" (click)="nuevoBeneficio()">
        <mat-icon>library_add</mat-icon><br>Crear un Beneficio
    </button>
    <br>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar Beneficios">
    </mat-form-field>
</ng-container>

<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="Nombre" matSortDirection="asc" slot="fixed">

        <!-- Beneficio Column -->
        <ng-container matColumnDef="Beneficio">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Beneficio</th>
            <td mat-cell *matCellDef="let row">{{row.nombre}}</td>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="ID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let row">{{row.id}}</td>
        </ng-container>

        <!-- Tipo Column -->
        <ng-container matColumnDef="Tipo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Desc</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.tipo"> Cargando... </td>
                <td mat-cell *ngIf="row.tipo==='DESC10'"> Descuento 10% </td>
                <td mat-cell *ngIf="row.tipo==='DESC25'"> Descuento 25% </td>
                <td mat-cell *ngIf="row.tipo==='DESC30'"> Descuento 30% </td>
                <td mat-cell *ngIf="row.tipo==='DESC33'"> Descuento 33% </td>
                <td mat-cell *ngIf="row.tipo==='DESC50'"> Descuento 50% </td>
                <td mat-cell *ngIf="row.tipo==='CU02SI'"> 2 Cuotas sin interés </td>
                <td mat-cell *ngIf="row.tipo==='CU03SI'"> 3 Cuotas sin interés </td>
                <td mat-cell *ngIf="row.tipo==='CU04SI'"> 4 Cuotas sin interés </td>
                <td mat-cell *ngIf="row.tipo==='CU06SI'"> 6 Cuotas sin interés </td>
                <td mat-cell *ngIf="row.tipo==='CU09SI'"> 9 Cuotas sin interés </td>
                <td mat-cell *ngIf="row.tipo==='CU12SI'"> 12 Cuotas sin interés </td>
            </ng-container>
        </ng-container>

        <!-- Herramientas Column -->
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
            <td width="5%" mat-cell *matCellDef="let row">
                <button mat-stroked-button class="acciones" color="accent" (click)="editar(row)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-stroked-button class="acciones" color="error" (click)="borrar(row)">
                    <mat-icon>delete</mat-icon>
                </button>
                <button mat-stroked-button class="acciones" color="primary" (click)="beneficiados(row)">
                    <mat-icon>grading</mat-icon>
                </button>
                <button mat-stroked-button class="acciones" color="warn" (click)="genqr(row)">
                    <mat-icon>qr_code_2</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Activo Column -->
        <ng-container matColumnDef="Activo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Activo</th>
            <td mat-cell *matCellDef="let row">
                <div>
                    <span *ngIf="row.activo; else noActivo">
                    <mat-chip selected color="primary" (click)="activar(row)">SI</mat-chip>
                </span>
                    <ng-template #noActivo>
                        <mat-chip selected color="accent" (click)="activar(row)">NO</mat-chip>
                    </ng-template>
                </div>
            </td>
        </ng-container>

        <!-- Beneficiados Column -->
        <ng-container matColumnDef="Beneficiados">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Beneficiados</th>
            <td mat-cell *matCellDef="let row">
                <div>
                    <span>
                        <mat-chip color="primary">{{row.cantbeneficiados}}</mat-chip>
                    </span>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </mat-card>

    <mat-paginator [pageSizeOptions]="[30, 100]" showFirstLastButtons></mat-paginator>

</div>