import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertasService } from 'src/app/services/alertas.service';
import { Mensaje } from 'src/app/models/chat.model';
import Swal from 'sweetalert2';
import { PruebaService } from 'src/app/services/abm.service';

@Component({
  selector: 'app-tabla-postulados',
  templateUrl: './tabla-postulados.component.html',
  styleUrls: ['./tabla-postulados.component.scss']
})
export class TablaPostuladosComponent implements OnInit {

  private items: AngularFirestoreCollection<any>;
    public itemchat: AngularFirestoreCollection<Mensaje>;
    public itemusu: AngularFirestoreCollection<any>
    tabla: any;
    tablausu: any;
    displayedColumns: string[] = ['usuario','nombre','apellido', 'tipodoc', 'nrodoc', 'nacionalidad','telefono', 'fechanac', 'posicion', 'actividad', 'Acciones'];
    dataSource = new MatTableDataSource();
    isLoading = true;
    public id: string;
  
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
  PedidoTitulo: string;
  perfilActividad: string;
  perfilPuesto: string;
  perfilsexo: any;
  perfilDeporte: any;
  archivo: string;
  
    constructor  ( private afs: AngularFirestore,
                   private router: Router,
                   public alertaSvc: AlertasService,
                   private route: ActivatedRoute,
                   public svcabm: PruebaService
                  ) {}
                
  
    ngAfterViewInit() {
      this.paginator._intl.itemsPerPageLabel = 'Usuarios por página';
      this.dataSource.paginator = this.paginator;
      this.dataSource.data.splice(0);
      this.dataSource.sort = this.sort;
    }
  
    ngOnInit() {
      this.id = this.route.snapshot.paramMap.get('id');
      this.items = this.afs.collection('Postulaciones', ref => ref
                                                  .where('idpedido','==',this.id)
                                                  .orderBy('usuario', 'desc'));
      this.items.valueChanges().subscribe(
        usuarios => {
          let i = 0;
          usuarios.forEach(usuario => {
            this.itemusu = this.afs.collection<Mensaje>('Usuarios', ref => ref
                                                .where('correoelectronico','==', usuario.usuario));
            this.tablausu = this.itemusu.valueChanges().subscribe(
              respusu => {
                if (respusu.length > 0) {
                  this.itemchat = this.afs.collection<Mensaje>('chats', ref => ref
                                                      .where('uid','==', usuario.usuario)
                                                      .where('chat','==', usuario.usuario)
                                                      .where('leidoreceptor','==',0 ));
                  this.tabla = this.itemchat.valueChanges().subscribe(
                    resp => {
                      if (resp.length > 0) {
                        usuario.sinleer = true;
                      } else { 
                        usuario.sinleer = false; 
                      }
                    }
                  );
                  let tiposDeportes = {'M':'Masculino','A':'Ambos','F':'Femenino'};
                  this.perfilsexo = respusu[0].sexo.replace(/[A-Z]/g, m => tiposDeportes[m]);
                  
                  let tiposActividad = {'AM':'Amateur','PA':'Profesional Activo','PL':'Profesional Libre','SR':'Seniors','PR':'Profesional'};
                  this.perfilActividad = respusu[0].actividad.replace(/[A-Z][A-Z]/g, m => tiposActividad[m]);

                  let tiposPosicion = {'PO':'Arquero','DF':'Defensor','MC':'Mediocampista','DE':'Delantero','AG':'Analista de GPS','AV':'Analista de Videos', 'AC':'Ayudante de Campo', 'EA':'Entrenador de Arqueros', 'KG':'Kinesiólogo','MD':'Médico', 'PF':'Preparador Físico','PS':'Psicologo','CO':'Coordinador','MG':'Manager','DT':'Director Técnico'};
                  this.perfilPuesto = respusu[0].posicion.replace(/[A-Z][A-Z]/g, m => tiposPosicion[m]);

                  let tiposDep = {'F11':'Fútbol 11','FP':'Fútbol Playa','FS':'Fútbol Sala', 'ES':'ESports'};
                  this.perfilDeporte = respusu[0].deporte.replace(/[A-Z][A-Z0-9][0-9]?/g, m => tiposDep[m]);

                  this.PedidoTitulo = `${this.perfilPuesto} - ${this.perfilActividad} - ${this.perfilDeporte} ${this.perfilsexo}`;
                  this.archivo = `${respusu[0].posicion} - ${respusu[0].actividad} - ${respusu[0].deporte} ${respusu[0].sexo}`
                  usuario.usuario = respusu[0].correoelectronico;
                  usuario.nombre = respusu[0].nombre;
                  usuario.apellido = respusu[0].apellido;
                  usuario.tipodoc = respusu[0].tipodoc;
                  usuario.nrodoc = respusu[0].nrodoc;
                  usuario.nacionalidad = respusu[0].nacionalidad;
                  usuario.telefono = respusu[0].telefono;
                  usuario.fechanac = respusu[0].fechanac;
                  usuario.actividad = respusu[0].actividad;
                  usuario.posicion = respusu[0].posicion;
                  usuario.tipolicencia = respusu[0].tipolicencia;
                  usuarios.splice(0 , 1);
                  usuarios.push(usuario);
                } 
              }
            );                           
            i = i + 1;
          });
          
          
          if (this.tabla){
            this.tabla.unsubscribe();
            this.tablausu.unsubscribe();
          }
          setTimeout(() => {
            this.isLoading = false;
            this.dataSource.data = usuarios;
          }, 500);
          
        });
        
    }
  
    applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  
    chat( usuario: string ) {
      if (!sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh')){
        this.router.navigateByUrl(`/chatting/${usuario}`);
      } else {
        this.MensajePantallaInfo('MUY PRONTO', 'Esta función está en desarrollo')
      }

    }

    async MensajePantallaInfo(titulo: string, texto: string){
      Swal.fire({
        title: titulo,
        text: texto,
        icon: 'info',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
      }).then(() => { });
    }
  
    exportexcel(){
      this.svcabm.exportexcel(this.archivo,[10]);
    }

  }