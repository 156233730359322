import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cb',
  templateUrl: './cb.component.html',
  styleUrls: ['./cb.component.scss','../../../css/mystyles.css', '../../../css/custom.css']
})
export class CbComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
