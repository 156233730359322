import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class CuidarloginGuard  {
  constructor(
              private auth: AngularFireAuth, 
              private router: Router
             ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
    return this.auth.authState.pipe(
      map(autenticacion => {
        if (autenticacion !== null) {
          return true;
        } else {
            this.router.navigateByUrl('/login');
          return false;
        }
      })
    );
  }
}

