<main id="home-producto" class="producto-clubesSAD">

    <section class="hero is-fullheight">
        <div class="container is-marginless">
            <div class="column is-6-desktop is-5-widescreen is-offset-1">
                <h1 class="hero-body title has-text-white">CLUBES Y SAD</h1>
                <h2 class="has-text-white">Mercado de Pases es la red global inteligente más completa, inmediata y transparente donde los clubes y SAD de todo el mundo interactúan y se vinculan de manera Online y efectiva. </h2>
            </div>
        </div>
    </section>

    <section class="section">
        <div class="container is-fluid">

            <div class="beneficios-section">
                <div class="columns is-multiline is-centered">
                    <div class="column is-full has-text-centered">
                        <h3>¿CUALES SON LOS BENEFICIOS?</h3>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../../assets/images/icon-mdp_CP_01.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">Conexión entre clubes</h5>
                                        <p class="is-size-5">Los clubes de fútbol del mundo, vinculados e integrados de manera simple y efectiva. ¡No esperes más, y registra tu club hoy!</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../../assets/images/icon-mdp_CP_02.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">Encuentra y compra jugadores</h5>
                                        <p class="is-size-5">Encuentra a través de nuestro buscador, a los jugadores que estás buscando para incorporar al club que gestionas. ¡Garantizamos seguridad y eficacia en todos nuestros procesos!</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../../assets/images/icon-mdp_CP_03.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">¡Ofrece jugadores!</h5>
                                        <p class="is-size-5">Mercado de Pases cuenta con un sistema inteligente mediante el cual, los clubes y SAD pueden ingresar todos los jugadores de su posesión -profesionales e infanto-juveniles- y de este modo lograr que el mundo del
                                            fútbol pueda conocerlos y comprarlos.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../../assets/images/icon-mdp_CP_04.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">¡Encuentra cuerpo técnico!</h5>
                                        <p class="is-size-5">Nuestra plataforma te brinda la posibilidad de establecer comunicación directa con directores técnicos, preparadores físicos, entrenadores de arqueros, psicólogos deportivos, médicos, kinesiólogos y técnicos en
                                            GPS., generando de este modo ahorro de tiempo y efectividad en tu gestión.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../../assets/images/icon-mdp_CP_05.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">Pruebas de jugadores</h5>
                                        <p class="is-size-5">Creando tu perfil y configurándolo, podrás generar alertas para que estés siempre informado respecto a las pruebas de jugadores que los clubes de fútbol realicen.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../../assets/images/icon-mdp_CP_06.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">Captación de talentos, los 365 días del año</h5>
                                        <p class="is-size-5">Con Mercado de Pases, los clubes y SAD podrán hacer convenios con distintos centros de captación de talentos, evitando de este modo tener que realizar numerosas pruebas y búsquedas de jugadores. Creamos alianzas
                                            estratégicas, para vincularlos de manera efectiva logrando ahorro en tiempo y dinero. ¡Sabemos que esto es un claro beneficio para tu gestión!</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                    <div class="column is-9 side-padding-mobile">
                        <div class="box beneficios-box">
                            <article class="media vertical-align is-flex-touch">
                                <div class="media-left">
                                    <figure class="image is-128x128">
                                        <img src="../../../assets/images/icon-mdp_CP_07.svg" alt="Image">
                                    </figure>
                                </div>
                                <div class="media-content">
                                    <div class="content">
                                        <h5 class="is-size-3">Partidos amistosos</h5>
                                        <p class="is-size-5">Si el club o SAD en el que trabajas se encuentra interesado en organizar partidos amistosos, podrás colocar los requerimientos y condiciones en nuestro sistema (fechas, ubicación, etc.) y Mercado de Pases organizará
                                            los mismos.</p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>

                </div>
            </div>

            <div class="columns is-multiline is-centered call-to-action">
                <div class="column is-full has-text-centered">
                    <h3>ES TU OPORTUNIDAD</h3>
                    <p class="is-size-1">Accedé a todos los beneficios ahora!</p>
                </div>
            </div>
        </div>
    </section>
</main>