import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import Swal from 'sweetalert2';
import { Mensaje } from 'src/app/models/chat.model';

@Component({
  selector: 'app-mensajeriamas',
  templateUrl: './mensajeriamas.component.html',
  styleUrls: ['./mensajeriamas.component.scss']
})
export class MensajeriamasComponent implements OnInit {

  private itemsUsuarios: AngularFirestoreCollection<any>;
  private itemsCollection: AngularFirestoreCollection<Mensaje>;

  public chats: Mensaje[] = [];
  elemento: any;
  cargando: boolean;
  tema: string;
  mensaje: string;
  chat: number;
  uid: string;
  public nombre: string;
  photoURL: string;
  datos: any;
  public cant: number;
  public total: number;
  public tabla: any;

  constructor(
    public db: AngularFirestore) {

    this.cargando = true;
    }

  ngOnInit() {
  }

  enviar_mensaje()  {
    if  (this.mensaje.length !== 0) {
      this.agregarMensaje(this.mensaje);
      }
    }

  async agregarMensaje(texto: string) {
  
    this.itemsUsuarios = this.db.collection('Usuarios', ref => ref
                                                    .orderBy('ultimologin', 'asc'));
                                                    
    this.tabla = this.itemsUsuarios.valueChanges().subscribe(
      usuarios => {
        this.cant = 0;
        usuarios.forEach(usuario => {
          this.cant = this.cant + 1;
          this.sleep(1);
          const mensaje: Mensaje = {
            nombre: 'MercadoDePases.Club',
            mensaje: texto,
            fecha: new Date().getTime(),
            uid: 'contacto@mercadodepases.club',
            chat: usuario.correoelectronico,
            leidoreceptor: 0
          };
          this.db.doc(`chats/${mensaje.fecha}`).set(mensaje);
        });
        if (this.cant === usuarios.length) {
          this.MensajePantalla('Mensajes enviados', `Se enviaron ${this.cant} los mensajes masivos`);
          this.tabla.unsubscribe();
          this.mensaje = '';  
        }
      }
    );
  }

  sleep(milliseconds: number) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  async MensajePantalla(titulo: string, texto: string){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'success',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { this.mensaje = '' });
  }

  borrar_todos_adm_mensajes(){
    this.itemsCollection = this.db.collection('chats', ref => ref
                                                    .where('nombre', '==', 'MercadoDePases.Club'));
    this.itemsCollection.get().toPromise().then( resplist => { 
      this.cant = 0;
      resplist.forEach(doc => {
        this.cant = this.cant + 1;
        doc.ref.delete();
      })
      this.total = resplist.size;
      if (this.cant === resplist.size) {
        this.MensajePantalla('Mensajes masivos', `Se eliminaron ${this.cant} mensajes masivos que se encontraban publicados`);
      }
    });
    
  
  }
}
