<ng-container matColumnDef="Filtro-Botones">
    <button class="boton-menu" color="secondary" (click)="nuevoAmistoso()">
        <mat-icon>library_add</mat-icon><br>Cargar Amistoso
    </button>
    <button class="boton-menu" *ngIf="this.estado" color="secondary" (click)="amistosos(false,false)">
        <mat-icon>explore_off</mat-icon><br>Amistosos Inactivos
    </button>
    <button class="boton-menu" *ngIf="!this.estado" color="secondary" (click)="amistosos(true,false)">
        <mat-icon>explore</mat-icon><br>Amistosos Activos
    </button>
    <button class="boton-menu" color="secondary" (click)="amistosos(true,true)">
        <mat-icon>hourglass_disabled</mat-icon><br>Amistosos Vencidos
    </button>
    <br>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar Amistosos">
    </mat-form-field>
</ng-container>

<div class="mat-elevation-z8">
    <h2>{{TitAmistoso}}</h2>
    <table mat-table [dataSource]="dataSource" matSort matSortActive="Juego" matSortDirection="asc" slot="fixed">

        <!-- Juego Column -->
        <ng-container matColumnDef="Juego">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Juego</th>
            <td mat-cell *matCellDef="let row">{{row.Juego}}</td>
        </ng-container>

        <!-- Club Column -->
        <ng-container matColumnDef="Organizador">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Organizador</th>
            <td mat-cell *matCellDef="let row">{{row.Organizador}}</td>
        </ng-container>

        <!-- Tipo de Deporte Column -->
        <ng-container matColumnDef="Tipo de Deporte">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de Deporte</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="row.Sexo==='M'"> MASCULINO </td>
                <td mat-cell *ngIf="row.Sexo==='F'"> FEMENINO </td>
                <td mat-cell *ngIf="row.Sexo==='A'"> MIXTO </td>
            </ng-container>
        </ng-container>

        <!-- Fecha Column -->
        <ng-container matColumnDef="Fecha">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
            <td mat-cell *matCellDef="let row">{{row.Evento.toDate() | date: 'dd/MM/yyyy HH:mm'}}</td>
        </ng-container>

        <!-- Predio Column -->
        <ng-container matColumnDef="Predio">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Predio</th>
            <td mat-cell *matCellDef="let row">{{row.Predio}}</td>
        </ng-container>

        <!-- Herramientas Column -->
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
            <td width="5%" mat-cell *matCellDef="let row">
                <button *ngIf="this.TitAmistoso !== 'Amistosos Vencidos en el tiempo'" mat-stroked-button color="secondary" (click)="editar(row)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-stroked-button color="secondary" (click)="borrar(row)">
                    <mat-icon>delete</mat-icon>
                </button>
                <button mat-stroked-button color="secondary" (click)="inscriptos(row)">
                    <mat-icon>grading</mat-icon>
                </button>
                <button mat-stroked-button color="secondary" (click)="genqr(row)">
                    <mat-icon>qr_code_2</mat-icon>
                </button>
            </td>
        </ng-container>

        <ng-container matColumnDef="Abierto">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Completo</th>
            <td mat-cell *matCellDef="let row">
                <div>
                    <span *ngIf="!row.Abierto; else Cerrado">
                    <mat-chip selected color="primary">PARA JUGAR</mat-chip>
                </span>
                    <ng-template #Cerrado>
                        <mat-chip selected color="accent">ESPERANDO</mat-chip>
                    </ng-template>
                </div>
            </td>
        </ng-container>

        <!-- Activo Column -->
        <ng-container matColumnDef="Activo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Activo</th>
            <td mat-cell *matCellDef="let row">
                <mat-slide-toggle *ngIf="row.Activo; else noActivo" checked formControlName="Activo" (click)="activar(row)"></mat-slide-toggle>
                <!-- 
                <mat-chip-list>
                    <span *ngIf="row.Activo; else noActivo">
                    <mat-chip selected color="primary" (click)="activar(row)">SI</mat-chip>
                </span> -->
                <ng-template #noActivo>
                    <mat-slide-toggle formControlName="Activo" (click)="activar(row)"></mat-slide-toggle>
                </ng-template>
                <!-- </mat-chip-list> -->
            </td>
        </ng-container>

        <!-- Inscriptos Column -->
        <ng-container matColumnDef="Inscriptos">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Inscriptos</th>
            <td mat-cell *matCellDef="let row">
                <div>
                    <span>
                        <mat-chip color="primary">{{row.Inscriptos}}</mat-chip>
                    </span>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </mat-card>

    <mat-paginator [pageSizeOptions]="[30, 100]" showFirstLastButtons></mat-paginator>

</div>