<div *ngIf="this.innerWidth < 1000" class="marca-de-agua">
    <img alt="" src="../assets/images/MdP-color-02-700px.png" />
    <p class="texto">
        <b>La herramienta de la comunidad deportiva.</b>
    </p>
    <p class="copete">
        Busca tu oportunidad y date a conocer con las entidades deportivas del planeta.
    </p>
</div>
<!-- 
<p style="text-align: center;">
    <a href="https://apps.apple.com/us/app/mercado-de-pases-club/id1536669123">
        <img src="../../assets/images/ES-apple.svg" />
    </a>
    <span>&nbsp;</span>
    <a href="https://play.google.com/store/apps/details?id=mercadodepases.club">
        <img src="../../assets/images/ES-google.svg" />
    </a>
</p>
<br>
<div class="imagen">
    <img *ngIf="this.innerWidth >= 1000" class="imagen_grande" src="../assets/images/FondoVirtual-MDP.png" />
    <img *ngIf="this.innerWidth < 1000" class="imagen_chico" src="../assets/images/grafico-home-mobile-gray-fully.png" />
</div> -->
<div>
    <iframe src="https://mercadodepases.futbol" style="width: 100%; height: 100vh; border: none;"></iframe>
</div>
