import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertasService } from 'src/app/services/alertas.service';
import { Mensaje } from 'src/app/models/chat.model';
import { PruebaService } from 'src/app/services/abm.service';

@Component({
  selector: 'app-chats-sin-leer',
  templateUrl: './chats-sin-leer.component.html',
  styleUrls: ['./chats-sin-leer.component.scss']
})
export class ChatsSinLeerComponent implements OnInit {
  private items: AngularFirestoreCollection<any>;
  public itemchat: AngularFirestoreCollection<Mensaje>;
  tablausu: any;
  displayedColumns: string[] = ['icono', 'correoelectronico', 'nombre', 'apellido', 'deporte','sexo','tipoperfil', 'actividad', 'tipolicencia', 'fechanac', 'ultimologin','Ver Mensajes'];
  dataSource = new MatTableDataSource();
  isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  cantusuarios: number;
  cantmensajes: number;
  sinleer = false;
  usuariosmensajes: any;
  datasinleer: any = [];
  cargandodatos: boolean;

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    public alertaSvc: AlertasService,
    public svcabm: PruebaService,
    private route: ActivatedRoute
  ) {
    const entidad = this.route.snapshot.paramMap.get('entidad');
  }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Usuarios por página';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {
    this.cargandodatos = true;

    this.usuariosmensajes = await this.afs.collection('chats', ref => ref
      .where('uid', '!=', 'contacto@mercadodepases.club')
      .where('leidoreceptor', '==', 0)).get().toPromise();

    if (this.usuariosmensajes.size > 0) {
      const uniqueChatsSet = new Set();

      const tasks = this.usuariosmensajes.docs.map(async (data) => {
        const sinleer: any = (await this.afs.doc(data.ref.path).get().toPromise()).data();

        if (sinleer && sinleer.chat) {
          const userExists = await this.afs.collection('Usuarios', ref => ref
            .where('correoelectronico', '==', sinleer.uid)).get().toPromise();

          if (userExists.size > 0) {
            uniqueChatsSet.add(sinleer.chat);
          } else {
            const todayNumeric = new Date().getDate();
            await this.afs.doc(data.ref.path).update({ leidoreceptor: todayNumeric });
          }
        }
      });

      await Promise.all(tasks);

      this.datasinleer = Array.from(uniqueChatsSet);
      this.cargandodatos = false;

      if (this.datasinleer.length > 0) {
        await this.loadUsersData(this.datasinleer);
      }
    } else {
      this.router.navigateByUrl('/chats');
    }
  }

  private async loadUsersData(emails: string[]) {
    const pageSize = 10;
    const pageCount = Math.ceil(emails.length / pageSize);

    for (let i = 0; i < pageCount; i++) {
      const startIndex = i * pageSize;
      const endIndex = (i + 1) * pageSize;
      const chunk = emails.slice(startIndex, endIndex);

      const query = this.afs.collection('Usuarios', ref => ref
        .where('correoelectronico', 'in', chunk)
        .orderBy('ultimologin', 'desc'));

      query.valueChanges().subscribe(usuarios => {
        this.isLoading = false;

        const existingEmails = this.dataSource.data.map((user: any) => user.correoelectronico);
        const uniqueUsers = usuarios.filter((user: any) => !existingEmails.includes(user.correoelectronico));

        this.dataSource.data = [...this.dataSource.data, ...uniqueUsers];
      });
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  chat(usuario: string) {
    this.router.navigateByUrl(`/chatting/${usuario}`);
  }

  verusuario(usuario: string) {
    this.router.navigateByUrl(`/perfil/${usuario}`);
  }

  exportexcel() {
    this.svcabm.exportexcel('Usuarios', [0, 11, 12, 13, 14]);
  }

  todoslosusuarios() {
    this.router.navigateByUrl('/chats');
  }
}
