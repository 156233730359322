import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import firebase from 'firebase/compat'; // Esto no lo hace
// import * as admin from 'firebase-admin';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Observable } from 'rxjs';
import Swal from 'sweetalert2';

interface User {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: Observable<User>; // Este paso estaba en Fireship 2018
  authState: firebase.User;

  public userData: Observable<firebase.User>; // Esto lo hace Dominicode
  public perfiladmin: boolean;
  public entidad: string;
  public centrodeportivo: string;
  public version: string;
  public a: any;

  constructor   (
              private afAuth: AngularFireAuth,
              private afs: AngularFirestore,
              private router: Router
                ) 
    {
      this.version = '1.5.01-WEB'
      this.userData = afAuth.authState; // Esto lo hace Dominicode
     }

// LOGIN
async emailSignIn(email: string, password: string) {
  try {
    if (!email) {
      this.MensajePantalla('Ingreso Inválido','Debe ingresar su Usuario');
      return
    }
    if (!password) {
      this.MensajePantalla('Ingreso Inválido','Debe ingresar su Contraseña');
      return
    }
    return await this.afAuth.signInWithEmailAndPassword(
      email.toLowerCase(),
      password
    );
  } catch (err) {
    switch (err.code) {
      case 'auth/wrong-password': {
        this.MensajePantalla('Ingreso Inválido','Usuario o Contraseña incorrecta');
        break;
      }
        case 'auth/too-many-requests': {
          this.MensajePantalla('Ingreso Inválido','Su usuario está bloqueado, contactarse con el administrador del sistema via: contacto@mercadodepases.club');
          break;
      }
        case 'auth/user-not-found' : {
          this.MensajePantalla('Ingreso Inválido','Usuario o Contraseña incorrecta');
          break;
      }
        case 'auth/user-disabled': {
          this.MensajePantalla('Ingreso Inválido','Su usuario se encuentra inhabilitado');
          break;
      }
        case 'auth/invalid-email': {
          this.MensajePantalla('Ingreso Inválido','Ingrese una dirección de correo válida');
          break;
      }
        case 'auth/network-request-failed': {
        this.MensajePantalla('Ingreso Inválido','Verifique el estado de su conexión de red');
        break;
      }

      default: {
        this.MensajePantalla('Ingreso Inválido','Error inesperado en el ingreso del usuario');
        break;
      }
    }
  }
}

  cargarperfil(correoelectronico: string){
    const a = this.afs.doc<any>(`Usuarios/${correoelectronico.toLowerCase()}`).valueChanges().subscribe(
      usuario => {
        if (!usuario.admin) {
          this.MensajePantalla('Usuario Administrador NO VALIDO', 'Ud. no tiene los permisos necesarios para ingresar a este módulo');
          this.signOut();
          a.unsubscribe();
        } else if (usuario.tipoentidad === 'CB' || usuario.tipoentidad === 'CE') {
          this.cargardatosentidad(usuario);
          this.cargardatossession(usuario);
          this.loggearactividad(usuario.correoelectronico);
          this.router.navigateByUrl('/pruebas');
          a.unsubscribe();
        } else {
          this.setadmin(usuario.photoURL);
          this.cargardatossession(usuario);
          this.loggearactividad(usuario.correoelectronico);
          this.router.navigateByUrl('/chats');
          a.unsubscribe();
        }
      }
    );
  }

  async signOut() {
    await this.afAuth.signOut();
    sessionStorage.removeItem('admao.@uhg523sodhfgvin');
    sessionStorage.removeItem('ionfoaenr');
    sessionStorage.removeItem('lat');
    sessionStorage.removeItem('lgn');
    sessionStorage.removeItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh');
    sessionStorage.removeItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj');
  }

  MensajePantalla(titulo: string, texto: string){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { });
  }

  setadmin(uid: string){
    sessionStorage.setItem('admao.@uhg523sodhfgvin', uid);
  }

  buscaUsuario(){
    return sessionStorage.getItem('ionfoaenr');
  }

  cargardatossession(usuario: any){
    sessionStorage.setItem('ionfoaenr',usuario.correoelectronico);
    sessionStorage.setItem('lat',usuario.Latitud);
    sessionStorage.setItem('lgn',usuario.Longitud);
  }

  buscarGPS(){
    let lat:any = sessionStorage.getItem('lat');
    lat = Number(lat);
    let lgn:any = sessionStorage.getItem('lgn');
    lgn = Number(lgn);
    return { 'latitude':lat,'longitude': lgn }
  }

  cargardatosentidad(usuario:any){
    sessionStorage.setItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh',usuario.entidad);
    sessionStorage.setItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj',usuario.tipoentidad);
  }

  loggearactividad(usu:string){
    this.afs.doc(`Usuarios/${ usu }`).update({ultimologin: new Date(), version: this.version});
  }

  async eliminarCuenta(usu: string){

    // var user = admin.auth().getUserByEmail(usu);
    console.log(usu);

    // user.delete().then(() => {
    //   this.afs.doc(`Usuarios/${usu}`).delete();
    // }).catch(err => {
    //   console.log('ERROR: ', err);
    // })
  }

}
