<div class="nueva-prueba-container">
    <form [formGroup]="pruebaForm" (ngSubmit)="addPrueba(pruebaForm.value)">
        <mat-form-field *ngIf="this.add">
            <mat-select placeholder="Club" formControlName="Club">
                <mat-option *ngFor="let club of (clubes | async)" [value]="club">
                    <img [src]="club.photoURL" width="15%" style="border-radius: 50%; border: solid 0.25px gray"> {{club.Club}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field [class.is-invalid]="DeportesDatos">
            <mat-select placeholder="Deporte" formControlName="Deporte">
                <mat-option value="F11">FUTBOL 11</mat-option>
                <mat-option value="FS">FUTSAL</mat-option>
                <mat-option value="FP">FUTBOL PLAYA</mat-option>
                <mat-option value="ES">E-SPORTS</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="Categoria" formControlName="Categoria">
                <mat-option value="+55">Mayor de 55</mat-option>
                <mat-option value="+45">Mayor de 45</mat-option>
                <mat-option value="+35">Mayor de 35</mat-option>
                <mat-option value="1997">1997</mat-option>
                <mat-option value="1998">1998</mat-option>
                <mat-option value="1999">1999</mat-option>
                <mat-option value="2000">2000</mat-option>
                <mat-option value="2001">2001</mat-option>
                <mat-option value="2002">2002</mat-option>
                <mat-option value="2003">2003</mat-option>
                <mat-option value="2004">2004</mat-option>
                <mat-option value="2005">2005</mat-option>
                <mat-option value="2006">2006</mat-option>
                <mat-option value="2007">2007</mat-option>
                <mat-option value="2008">2008</mat-option>
                <mat-option value="2009">2009</mat-option>
                <mat-option value="2010">2010</mat-option>
                <mat-option value="2011">2011</mat-option>
                <mat-option value="2012">2012</mat-option>
                <mat-option value="2013">2013</mat-option>
                <mat-option value="2014">2014</mat-option>
                <mat-option value="2015">2015</mat-option>
                <mat-option value="2016">2016</mat-option>
                <mat-option value="2017">2017</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-radio-group aria-labelledby="Tipo" formControlName="Sexo">
            <mat-radio-button value="M">Masculino</mat-radio-button>
            <mat-radio-button value="F">Femenino</mat-radio-button>
        </mat-radio-group>

        <br><br>

        <mat-form-field>
            <mat-select placeholder="Predio" formControlName="Predio">
                <mat-option *ngFor="let predio of (predios | async)" [value]="predio.Nombre">
                    {{ predio.Nombre }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <input *ngIf="prueba" matInput [ngxMatDatetimePicker]="picker" placeholder="Fecha a realizarse" [min]="minDate" formControlName="Fecha" [value]="prueba.Fecha" readonly>
            <input *ngIf="!prueba" matInput [ngxMatDatetimePicker]="picker" placeholder="Fecha a realizarse" [min]="minDate" formControlName="Evento" value="" readonly>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker #picker> </ngx-mat-datetime-picker>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="Pierna" formControlName="Pierna">
                <mat-option value="CQ">CUALQUIERA</mat-option>
                <mat-option value="RG">DERECHA</mat-option>
                <mat-option value="LF">IZQUIERDA</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="Posicion" formControlName="Posicion">
                <mat-option value="CQ">CUALQUIERA</mat-option>
                <mat-option value="PO">ARQUERO</mat-option>
                <mat-option value="DF">DEFENSOR</mat-option>
                <mat-option value="MC">MEDIOCAMPISTA</mat-option>
                <mat-option value="DE">DELANTERO</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <input matInput type="text" placeholder="Límite de Jugadores (0 = Cupo Ilimitado)" formControlName="limite" aria-label="Label">
        </mat-form-field>

        <br>


        <mat-label><b>REQUISITOS PARA LA PRUEBA:</b></mat-label>
        <br>
        <mat-slide-toggle checked formControlName="Entrenamiento">
            <p>Entrenamiento Previo</p>
        </mat-slide-toggle>
        <br>
        <mat-slide-toggle formControlName="Pasaporte">
            <p>Pasaporte Comunitario</p>
        </mat-slide-toggle>
        <br>
        <mat-slide-toggle formControlName="Antecedentes">
            <p>Antecedentes Dep. en Fed. Profesional</p>
        </mat-slide-toggle>

        <br><br>
        <mat-label><u>Antes de Inscribirse los jugadores deben:</u></mat-label>

        <mat-radio-group aria-labelledby="nueva-prueba-container" class="example-radio-group" [(ngModel)]="Elegido" name="Elegido" [ngModelOptions]="{standalone: true}">
            <mat-radio-button class="example-radio-button" *ngFor="let tipo of Tipos" [value]="tipo">
                {{tipo}}
            </mat-radio-button>
        </mat-radio-group>

        <mat-label *ngIf="this.Elegido==='Llamar por Teléfono' || this.Elegido === 'Comunicarse por WhatsApp'">Información para Llamar o WhatsApp:</mat-label>
        <div *ngIf="this.Elegido==='Llamar por Teléfono' || this.Elegido === 'Comunicarse por WhatsApp'">
            <mat-form-field>
                <input matInput type="text" placeholder="Contacto" formControlName="Contacto" aria-label="Label">
            </mat-form-field>
            <mat-form-field>
                <input matInput type="text" placeholder="Cod país + Cod area + Nro de Telefono" formControlName="Telefono" aria-label="Label">
            </mat-form-field>
        </div>
        <div *ngIf="this.Elegido === 'Enviar Correo Electrónico'">
            <mat-form-field>
                <input matInput type="text" placeholder="Correo Electrónico" formControlName="Email" aria-label="Label">
            </mat-form-field>
        </div>
        <div class="container-button">
            <input type="submit" class="mat-flat-button" value="Guardar" [mat-dialog-close]="true">
        </div>
    </form>
</div>