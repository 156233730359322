<ng-container matColumnDef="Filtro-Botones">
    <div class="botones-container">
        <button mat-stroked-button color="secondary" routerLink="/mensajeriamas" class="boton">
            <mat-icon>record_voice_over</mat-icon>
            <span>Enviar Mensaje Masivo</span>
        </button>
        <button mat-stroked-button color="secondary" (click)="depurador()" class="boton">
            <mat-icon>delete</mat-icon>
            <span>Depurar Usuarios Incompletos</span>
        </button>
        <button mat-stroked-button color="secondary" (click)="exportexcel()" class="boton">
            <mat-icon>file_download</mat-icon>
            <span>Exportar a MS Excel</span>
        </button>
        <button mat-stroked-button color="secondary" (click)="buscarusuario()" class="boton">
            <mat-icon>person</mat-icon>
            <span>Buscar Usuario</span>
        </button>
        <button *ngIf="!analizando" mat-stroked-button color="primary" (click)="startanalizadorpuntajes()" class="boton" style="color:green">
            <mat-icon>star</mat-icon>
            <span>Comenzar Analizador Puntajes</span>
        </button>
        <button *ngIf="analizando" mat-stroked-button color="warn" (click)="stopanalizadorpuntajes()" class="boton" style="color:red">
            <mat-icon>star</mat-icon>
            <span>Detener Analizador Puntajes</span>
        </button>
        <button *ngIf="!sinleer" mat-stroked-button color="accent" (click)="mensajessinleer()" class="boton" style="color:blue">
            <mat-icon>mark_email_unread</mat-icon>
            <span>Mensajes Sin Leer</span>
        </button>
    </div>

    <mat-form-field class="filtro">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar Usuarios">
    </mat-form-field>

    <div class="info-container">
        <mat-label *ngIf="!this.isLoadingcontar" class="info-label">
            <b>Usuarios Activos: {{this.cantusuarios}}</b>
        </mat-label>
        <mat-label *ngIf="this.isLoadingcontar" class="info-label">
            <b>Usuarios Activos:</b> ESPERE...
        </mat-label>
        <mat-label *ngIf="!this.isLoadingcontar" class="info-label">
            <b>Mensajes sin Leer: {{this.cantmensajes}}</b>
        </mat-label>
        <mat-label *ngIf="this.isLoadingcontar" class="info-label">
            <b>Mensajes sin Leer:</b> ESPERE...
        </mat-label>
    </div>

    <div class="progress">
        <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': porcentaje + '%' }"></div>
    </div>
</ng-container>

<div class="mat-elevation-z8">
    <table id="excel-table" mat-table [dataSource]="dataSource" matSort>
        <!-- Usuario Column -->
        <ng-container matColumnDef="correoelectronico">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
            <td (click)="verusuario(row.correoelectronico)" mat-cell *matCellDef="let row" class="clickable-cell">
                <p class="usuario-text">
                    {{row.correoelectronico}}
                </p>
            </td>
        </ng-container>

        <!-- Proveedor de Login Column -->
        <ng-container matColumnDef="proveedor">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Proveedor</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.proveedor"> e-mail </td>
                <td mat-cell *ngIf="row.proveedor">{{row.proveedor}}</td>
            </ng-container>
        </ng-container>

        <!-- Nombre Column -->
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
            <td mat-cell *matCellDef="let row">{{row.nombre}}</td>
        </ng-container>

        <!-- Apellido Column -->
        <ng-container matColumnDef="apellido">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Apellido</th>
            <td mat-cell *matCellDef="let row">{{row.apellido}}</td>
        </ng-container>

        <!-- Deporte Column -->
        <ng-container class="omitir" matColumnDef="deporte">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Deporte</th>
            <td mat-cell *matCellDef="let row">{{row.deporte}}</td>
        </ng-container>

        <!-- Sexo Column -->
        <ng-container class="omitir" matColumnDef="sexo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sexo</th>
            <td mat-cell *matCellDef="let row">{{row.sexo}}</td>
        </ng-container>

        <!-- Fecha Nacimiento Column -->
        <ng-container matColumnDef="fechanac">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Nac</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.fechanac"> SIN CARGAR </td>
                <td mat-cell *ngIf="row.fechanac">{{row.fechanac | date: 'dd/MM/yyyy'}}</td>
            </ng-container>
        </ng-container>

        <!-- Tipo de Perfil Column -->
        <ng-container matColumnDef="perfil">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo de Perfil</th>
            <td mat-cell *matCellDef="let row">{{row.tipoperfil}}</td>
        </ng-container>

        <!-- Último Login Column -->
        <ng-container matColumnDef="ultimologin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Último Acceso</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.ultimologin"> Aún no ingresó </td>
                <td mat-cell *ngIf="row.ultimologin">{{(row.ultimologin).toDate() | date: 'dd/MM/yyyy HH:mm'}}</td>
            </ng-container>
        </ng-container>

        <!-- Versión APP Column -->
        <ng-container class="omitir" matColumnDef="version">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Versión APP</th>
            <td mat-cell *matCellDef="let row">{{row.version}}</td>
        </ng-container>

        <!-- Herramientas Column -->
        <ng-container class="omitir" matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Chat</th>
            <td mat-cell *matCellDef="let row">
                <button mat-stroked-button style="width: 5%;" *ngIf="row.sinleer && row.correoelectronico !== 'contacto@mercadodepases.club'" style="background: red;" (click)="chat(row.correoelectronico)">
                  <mat-icon style="color:gold">chat</mat-icon>
                </button>
                <button mat-stroked-button style="width: 5%;" *ngIf="!row.sinleer" (click)="chat(row.correoelectronico)">
                  <mat-icon style="color: green;">chat</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-card *ngIf="isLoading" class="loading-card">
        <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
    </mat-card>

    <mat-paginator [pageSizeOptions]="[50, 75, 150, 1000, 5000]" showFirstLastButtons></mat-paginator>
</div>
