import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { FileItem } from '../models/file-item';

@Injectable({
  providedIn: 'root'
})
export class CargaImagenesService {

  private CARPETA_IMAGENES = 'cargas';

  constructor( private db: AngularFirestore ) { }

  cargarImagenesFirebase( imagenes: FileItem[] ) {
  

    const storageRef = firebase.storage().ref();
    for (const item of imagenes) {

      item.estaSubiendo = true;
      if (item.progreso >= 100){
        continue;
      }

      const uploadTask: firebase.storage.UploadTask = 
              storageRef.child(`${ this.CARPETA_IMAGENES }/${ item.nombreArchivo }`)
                        .put( item.archivo );

      uploadTask.on( firebase.storage.TaskEvent.STATE_CHANGED,
                ( snapshot ) => item.progreso = ( snapshot.bytesTransferred / snapshot.totalBytes) * 100,
                ( error ) => console.error('Error al subir', error ),
                () => {
                  storageRef.child(`${ this.CARPETA_IMAGENES }/${ item.nombreArchivo }`).getDownloadURL().then(ref => {
                    item.photoURL = ref;
                    this.guardarImagen({
                      nombre: item.nombreArchivo,
                      photoURL: item.photoURL
                    });
                  });
                  item.estaSubiendo = false;
                });
    }
  }

  private guardarImagen( imagen: {nombre: string, photoURL: string} ){

      this.db.collection('/cargas')
                        .add ( imagen );

  }

}
