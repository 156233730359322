<mat-sidenav-container *ngIf="!cargando" class="sidenav-container">
    <mat-sidenav *ngIf="muestro && (authSvc.userData | async )" #sidenav class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false"
        (click)="toggleSidenav()">
        <mat-toolbar *ngIf="this.entidad" style="font-size: 16px;">{{this.entidad}}</mat-toolbar>
        <hr *ngIf="this.entidad" color="000000" height="4px">
        <mat-nav-list>
            <a *ngIf="muestro && this.perfiladmin" mat-list-item routerLink="/chats">
                <mat-icon class="icon">person</mat-icon>&nbsp;Lista de Usuarios</a>
            <a *ngIf="muestro && this.perfiladmin" mat-list-item routerLink="/jugadorescat">
                <mat-icon class="icon">persons</mat-icon>&nbsp;Jugadores por Categoria</a>
            <hr *ngIf="muestro && this.perfiladmin" width="90%">
            <a *ngIf="muestro" mat-list-item routerLink="/pruebas">
                <mat-icon class="icon">assignment</mat-icon>&nbsp;Pruebas de Fútbol</a>
            <a *ngIf="muestro" mat-list-item routerLink="/pruebasES">
                <mat-icon class="icon">gamepad</mat-icon>&nbsp;Pruebas de E-Sports</a>
            <a *ngIf="muestro && this.perfiladmin" mat-list-item routerLink="/clubes">
                <mat-icon class="icon">shield</mat-icon>&nbsp;Clubes Profesionales</a>
            <a *ngIf="muestro && this.perfiladmin" mat-list-item routerLink="/equipos">
                <mat-icon class="icon">sports_soccer</mat-icon>&nbsp;Equipos Amateurs</a>
            <a *ngIf="muestro && !(this.tipoentidad==='CE')" mat-list-item routerLink="/pedidos">
                <mat-icon class="icon">work</mat-icon>&nbsp;Ofertas Laborales</a>
            <a *ngIf="muestro && !(this.tipoentidad==='CE')" mat-list-item routerLink="/ofrecidos">
                <mat-icon class="icon">connect_without_contact</mat-icon>&nbsp;Ofrecidos</a>
            <a *ngIf="muestro && !(this.tipoentidad==='CE')" mat-list-item routerLink="/ofrecidosjg">
                <mat-icon class="icon">assignment_ind</mat-icon>&nbsp;CV Jugadores</a>
            <a *ngIf="muestro && this.perfiladmin" mat-list-item routerLink="/buscanrepresentantes">
                <mat-icon class="icon">supervisor_account</mat-icon>&nbsp;Buscan Representante</a>
            <hr *ngIf="muestro && this.perfiladmin" width="90%">
            <a *ngIf="muestro && this.perfiladmin" mat-list-item routerLink="/amistosostenis">
                <mat-icon class="icon">sports_tennis</mat-icon>&nbsp;Amistosos Tenis</a>
            <hr *ngIf="muestro && this.perfiladmin" width="90%">
            <a *ngIf="muestro && this.perfiladmin" mat-list-item routerLink="/predios">
                <mat-icon class="icon">fitness_center</mat-icon>&nbsp;Centros de Entrenamiento</a>
            <a *ngIf="muestro && this.perfiladmin" mat-list-item routerLink="/organizadores">
                <mat-icon class="icon">store</mat-icon>&nbsp;Organizadores</a>
            <a *ngIf="muestro && this.perfiladmin" mat-list-item routerLink="/beneficios">
                <mat-icon class="icon">card_giftcard</mat-icon>&nbsp;Beneficios</a>
            <hr *ngIf="muestro && this.perfiladmin" width="90%">
            <a *ngIf="muestro && this.perfiladmin" mat-list-item routerLink="/carga">
                <mat-icon class="icon">folder_multiple_image</mat-icon>&nbsp;Subir Imágenes</a>
            <a *ngIf="muestro && this.perfiladmin" mat-list-item routerLink="/imagenes">
                <mat-icon class="icon">image_search_outline</mat-icon>&nbsp;Ver Imágenes</a>
            <a *ngIf="muestro && this.perfiladmin" mat-list-item routerLink="/videorecog">
                <mat-icon class="icon">face_outline</mat-icon>&nbsp;Reconocimiento Facial</a>
            <hr color="#ff0033" height="2px">
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content width=100%>

        <mat-toolbar color="secondary">
            <button *ngIf="muestro" mat-icon-button class="example-icon" (click)="toggleSidenav()">
                <mat-icon>menu</mat-icon>
            </button>
            <button *ngIf="muestro" mat-icon-button class="example-icon" (click)="this.location.back();">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <!-- <span class="example-spacer"></span> -->
            <!-- <span>MercadoDePases.Club</span> -->
            <div *ngIf="url !== '/home' && url !=='/' && url !==''">
                <span class="example-spacer">
                    <img *ngIf="this.innerWidth < 1000" class="example-spacer" style="text-align: right;" width="80%" src="../../assets/images/Login-mark.png">
                    <img *ngIf="this.innerWidth >= 1000" class="example-spacer" style="text-align: right;" width="55%" src="../../assets/images/Login-mark.png">
                </span>
            </div>
            <span class="example-spacer"></span>
            <button mat-icon-button class="example-icon home-icon" (click)="home()">
                <mat-icon>home</mat-icon>
            </button>
            <!-- <span class="example-spacer"></span> -->
            <button *ngIf="!muestro" mat-icon-button class="example-icon login-icon" (click)="login()">
                <mat-icon>login</mat-icon>
            </button>
            <button *ngIf="muestro" mat-icon-button class="example-icon logout-icon" style="color: red;" (click)="this.muestro=false;salir()">
                <mat-icon>logout</mat-icon>
            </button>
        </mat-toolbar>
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>
<!-- Footer -->
<footer class="text-center text-lg-start bg-light text-muted">
    <!-- Section: Social media -->
    <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <!-- Left -->
        <div class="me-8 d-none d-lg-block">
        </div>
        <!-- Left -->
        <!-- Right -->
        <div>
            <a class="me-4 text-reset"><span>Nuestras redes sociales:         </span></a>
            <a href="https://www.facebook.com/mercadode.pases.129" target="_blank" class="me-4 text-reset">
                <i class="fab fa-facebook-f"></i>
            </a>
            <a href="https://www.instagram.com/mercadodepases.club/?utm_medium=copy_link" target="_blank" class="me-4 text-reset">
                <i class="fab fa-instagram"></i>
            </a>
        </div>
        <!-- Right -->
    </section>
    <!-- Section: Social media -->

    <!-- Section: Links  -->
    <section class="">
        <div class="container text-center text-md-start mt-5">
            <!-- Grid row -->
            <div class="row mt-3">
                <!-- Grid column -->
                <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                    <!-- Content -->
                    <h6 class="text-uppercase fw-bold mb-4">
                        <i class="fas fa-gem me-3"></i>MERCADO DE PASES
                    </h6>
                    <p>
                        Somos MercadoDePases.Club, tu puente digital en el apasionante universo del deporte, conectando jugadores amateurs con quienes buscan sus talentos para convertirlos en profesionales. Participan Clubes, Padres, Agentes, Jugadores y Cuerpos Técnicos.
                    </p>
                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                    <!-- Links -->
                    <h6 class="text-uppercase fw-bold mb-4">
                        Legales
                    </h6>
                    <p>
                        <a routerLink="/tyc" class="text-reset">Términos y Condiciones</a>
                    </p>
                    <br>
                    <h6 class="text-uppercase fw-bold mb-4">
                        CUENTAS DE USUARIOS
                    </h6>
                    <p>
                        <a routerLink="/borrarcuenta" class="text-reset">Borrar Cuenta</a>
                    </p>
                </div>

                <!-- Grid column -->

                <!-- Grid column -->
                <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                    <!-- Links -->
                    <h6 class="text-uppercase fw-bold mb-4">
                        Links del Uso
                    </h6>
                    <p>
                        <a routerLink="/jg" class="text-reset">Jugadores</a>
                    </p>
                    <p>
                        <a routerLink="/dt" class="text-reset">Directores Técnicos</a>
                    </p>
                    <p>
                        <a routerLink="/cb" class="text-reset">Clubes</a>
                    </p>
                    <p>
                        <a routerLink="/ce" class="text-reset">Centros de Entrenamiento</a>
                    </p>
                </div>
                <!-- Grid column -->

                <!-- Grid column -->
                <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                    <!-- Links -->
                    <h6 class="text-uppercase fw-bold mb-4">
                        Contactos
                    </h6>
                    <p><i class="fas fa-home me-3 fa-beat"></i> Buenos Aires, Argentina</p>
                    <p><i class="fas fa-envelope me-3"></i> contacto@mercadodepases.club</p>
                    <p><img src="../../assets/icons/whatsapp-brands.svg" class="fas me-3 fa-beat" width="6%"/><a href="https://wa.me/+5491157061116?text=HOLA%21%20Necesito%20ayuda%20con%20la%20APP" target="_blank">Nuestro Soporte en WhatsApp</a></p>
                    <!-- <p><img src="../../assets/icons/telegram-brands.svg" class="fas me-3 fa-beat" width="6%"/><a href="https://t.me/+jduALqcDsos3NmZh" target="_blank">Nuestro Soporte en Telegram</a></p> -->
                </div>
                <!-- Grid column -->
            </div>
            <!-- Grid row -->
        </div>
    </section>
    <!-- Section: Links  -->

    <!-- Copyright -->
    <div class="footer-copyright text-center py-3">© 2015 Copyright 2018
        <img src="../../favicon.ico" style="width: 50px" (click)="home()" /> MercadoDePases.Club
    </div>
    <!-- Copyright -->

</footer>
<!-- Footer -->