import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Organizador } from 'src/app/models/interfaces.model';
import { PruebaService } from 'src/app/services/abm.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nuevoorganizador',
  templateUrl: './nuevoorganizador.component.html',
  styleUrls: ['./nuevoorganizador.component.scss']
})
export class NuevoorganizadorComponent implements OnInit {

  @Input() organizador: Organizador; // Recibimos una prueba por parámetro
  organizadorForm = this.fb.group(
    {
      ID: [''],
      nombre: [''],
      deportes: [],
      estado: [],
      photoURL: [''],
      usuarios:['']
    });
  usuarios: any;
  PhotoURL: string;

  constructor(
    public pruebaSvc: PruebaService,
    public fb: UntypedFormBuilder
    ) {  }

  ngOnInit() {
    this.iniciarForm();
    this.usuarios = this.pruebaSvc.getUsuarios();
  }

  iniciarForm() {
    this.organizadorForm.patchValue({ ...this.organizador});
  }

  addOrganizador (organizador: Organizador) {
    const FormCompleto = Boolean(organizador.nombre && organizador.deportes && organizador.usuarios && organizador.photoURL);
    organizador.nombre = organizador.nombre.toUpperCase();
    if (!FormCompleto) {
      this.MensajePantallaError('Faltan Datos','Existen Errores en la carga de la información. Vuelva a cargarlo');
    } else {
        
        if (this.organizadorForm.get('ID').value === '') {
          this.pruebaSvc.addOrganizador(organizador);
        } else {
          this.pruebaSvc.editOrganizador(organizador);
        }
      }
    }

  async MensajePantallaError(titulo: string, texto: string){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'error',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { });
  }
}
