import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PruebaService } from '../../services/abm.service';
import { Equipo } from 'src/app/models/interfaces.model';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { DialogequipoComponent } from '../shared/dialogequipo/dialogequipo.component';

@Component({
  selector: 'app-tabla-equipos',
  templateUrl: './tabla-equipos.component.html',
  styleUrls: ['./tabla-equipos.component.scss']
})
export class TablaEquiposComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['Escudo', 'IDEquipo', 'Equipo', 'Delegado', 'Categoria', 'Organizador', 'Aprobado', 'Fecha Ultima Modificación', 'Acciones'];
  dataSource = new MatTableDataSource();

  isLoading = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private pruebaSvc: PruebaService,
    public dialog: MatDialog
  ) { }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Equipos por página';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.pruebaSvc.getEquipos().subscribe(
      equipos => {
        this.isLoading = false;
        this.dataSource.data = equipos;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  nuevo() {
    this.openDialog();
  }

  openDialog(equipo?: Equipo) {
    const config = {
      data: {
        message: equipo ? 'Editar Equipo' : 'Nuevo Equipo',
        content: equipo
      }
    };
    const dialogRef = this.dialog.open(DialogequipoComponent, config);
    dialogRef.afterClosed().subscribe(
      res => {  }
    );
  }

  editar(equipo: Equipo) {
    this.openDialog(equipo);
  }

  borrar(equipo: Equipo) {
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Esta accion no se puede revertir',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then(
      aceptar => {
        if (aceptar.value) {
          this.pruebaSvc.deleteEquipo(equipo)
          .then( () => {
            Swal.fire('Equipo eliminado', 'El pedido ha sido eliminado', 'success');
          })
          .catch((err) => {
            Swal.fire('Error!', 'El pedido no ha sido eliminado', 'error');
          });
        }
      });
  }


}
