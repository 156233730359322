import { Component, AfterViewInit, } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import HaversineGeolocation from 'haversine-geolocation';
import { Mensaje } from 'src/app/models/chat.model';
import { JugadorModel, VideoModel } from 'src/app/models/interfaces.model';
import { PruebaService } from 'src/app/services/abm.service';
import { AuthService } from 'src/app/services/auth.service';
import { AltacaptadorComponent } from '../altacaptador/altacaptador.component';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss']
})
export class PerfilComponent implements AfterViewInit {
  public cargando = true;
  jugador = new JugadorModel();
  forma: UntypedFormGroup;
  public hayvideos = false;
  videos: VideoModel[];
  public distancia: any;
  public a: { latitude: any; longitude: any; };
  public 
  public chatCollection: AngularFirestoreCollection<Mensaje>;
  chat: any;
  perfiladmin: boolean;
  entidad: string;
  tipoentidad: string;
  activacion: any;
  ultimologin: any;

  constructor(
            public db: AngularFirestore,
            private route: ActivatedRoute,
            private router: Router,
            private afs: AngularFirestore,
            private fb: UntypedFormBuilder,
            public authSvc: AuthService,
            public buscarVideos: PruebaService,
            public dialog: MatDialog) { }

ngAfterViewInit(){

  const uid = this.route.snapshot.paramMap.get('usuario');
  if (sessionStorage.getItem('admao.@uhg523sodhfgvin')){
    this.perfiladmin = true;
  } else if (sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh') && sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')){
    this.perfiladmin = false;
    this.entidad = sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh');
    this.tipoentidad = sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj');
  }

  this.crearFormulario();

  const carga = new Promise((resolve) => {
    this.afs.doc(`/Usuarios/${ uid }`).valueChanges().subscribe( (data: JugadorModel) => {
      if (data) {
        this.jugador = data;
        let tiposLista = {'1':'DNI','2':'Pasaporte','3':'CC','4':'CI','5':'TI','6':'RC','7':'DUI','8':'ID','9':'DPI','10':'CURP','11':'CIP','12':'RUT'};
        this.jugador.tipodoc = this.jugador.tipodoc.replace(/[0-9][0-9]?/g, m => tiposLista[m]);
    
        if (this.jugador.actividad !== 'NO'){
          let tiposActividad = {'AM':'Amateur','PA':'Profesional Activo','PL':'Profesional Libre','SR':'Seniors','PR':'Profesional','AV':'Avanzada','IM':'Intermedia','AP':'Aprendiz','PC':'Principiante'};
          this.jugador.actividad = this.jugador.actividad.replace(/[A-Z][A-Z]/g, m => tiposActividad[m]);

          let tiposDeportes = {'M':'Masculino','A':'Ambos','F':'Femenino'};
          this.jugador.sexo = this.jugador.sexo.replace(/[A-Z]/g, m => tiposDeportes[m]);
    
          let tiposDep = {'F11':'Fútbol 11','FP':'Fútbol Playa','FS':'Fútbol Sala', 'ES':'ESports','TN':'Tenis'};
          this.jugador.deporte = this.jugador.deporte.replace(/[A-Z][A-Z0-9][0-9]?/g, m => tiposDep[m]);
        }

        let tiposPerfil = {'JG':'Jugador','CT':'Cuerpo Técnico','CP':'Captador','DT':'Director Técnico','JT':'Jugador de Tenis','RT':'Representante','PP':'Padre Futura Promesa','FP':'Futura Promesa'};
        this.jugador.tipoperfil = this.jugador.tipoperfil.replace(/[A-Z][A-Z]/g, m => tiposPerfil[m]);

        if (this.jugador.posicion){
          let tiposPosicion = {'PO':'Arquero','DF':'Defensor','MC':'Mediocampista','DE':'Delantero','AG':'Analista de GPS','AV':'Analista de Videos', 'AC':'Ayudante de Campo', 'EA':'Entrenador de Arqueros', 'KG':'Kinesiólogo','MD':'Médico', 'PF':'Preparador Físico','PS':'Psicologo','CO':'Coordinador','MG':'Manager','DT':'Director Técnico'};
          this.jugador.posicion = this.jugador.posicion.replace(/[A-Z][A-Z]/g, m => tiposPosicion[m]);
        }
        if (this.jugador.tipolicencia){
          let tiposLicencia = {'CB':'Licencia CB','AA':'Licencia A','PR':'Licencia Profesional','CQ':'Amateur'};
          this.jugador.tipolicencia = this.jugador.tipolicencia.replace(/[A-Z][A-Z]/g, m => tiposLicencia[m]);
        }
        if (this.jugador.pierna){
          let tiposPierna = {'LF':'Izquierda','BT':'Ambidiestro','RG':'Derecha'};
          this.jugador.pierna = this.jugador.pierna.replace(/[A-Z][A-Z]/g, m => tiposPierna[m]);
        }
        if (this.jugador.tipoentidad){
          let tiposEntidad = {'CB':'Club','CE':'Centro de Entrenamiento'};
          this.jugador.tipoentidad = this.jugador.tipoentidad.replace(/[A-Z][A-Z]/g, m => tiposEntidad[m]);
        }

        if (this.jugador.activacion){
          this.activacion = this.jugador.activacion;
        }

        if (this.jugador.ultimologin){
          this.ultimologin = this.jugador.ultimologin;
        }

          

        // DISTANCIA - GPS
        this.a = this.authSvc.buscarGPS();
        let b = { latitude: (this.jugador.Latitud), longitude: (this.jugador.Longitud) };
          this.distancia = HaversineGeolocation.getDistanceBetween(this.a, b);
          this.distancia = this.distancia.toString().replace(".",",");
          if (Number.isNaN(this.distancia)) {
            this.distancia = -1;
          }
        
        this.cargaDataForm( this.jugador );

        this.buscarVideos.getVideos(uid).subscribe(
          videos => {
            if (videos.length > 0){
              this.hayvideos = true;
              this.videos = videos;
            }
          }
        );
      }
    });
    setTimeout(() => {
      resolve (this.cargando = false)
    }, 1000);
  });
}

  crearFormulario() {
    this.forma = this.fb.group({
      nombre: [''],
      apellido: [''],
      tipodoc: [''],
      nrodoc: [''],
      pportecomun: [],
      tipoperfil: [],
      nacionalidad: [''],
      sexo: [''],
      fechanac: [''],
      telefono: [''],
      actividad: [''],
      posicion: [''],
      pierna: [''],
      deporte: [''],
      photoURL: [''],
      Latitud: [],
      Longitud: [],
    });
  }
  // PATTERN EMAIL: '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$'

  cargaDataForm( jugador: JugadorModel) {
    this.forma.reset( jugador );
  }

  chatpersonal(usuario: string){
    this.router.navigateByUrl(`chatting/${usuario}`);
  }

  // PendienteLeer(usuario: string){
  //   this.chatCollection = this.afs.collection<Mensaje>('chats', ref => ref
  //                                 .where('chat','==', usuario)
  //                                 .where('uid','==', usuario)
  //                                 .where('leidoreceptor','==',0));
  //   return this.chatCollection.valueChanges().subscribe(
  //     resp => {
  //       if (resp.length > 0) {
  //         return false;
  //       } else { 
  //         return true;
  //       }
  //   });
  // }

  listadeinscripciones( uid: string ){
    console.log('inscripciones: ',uid);
  }

  listavideos( uid: string ){
    console.log('videos de ',uid);
  }

  serResponsableClub( uid: string){
      const config = {
        data: {
          message: 'Alta de Captador o Coordinador del Club',
          content: uid
        }
      };
    const dialogRef = this.dialog.open(AltacaptadorComponent, config);
    dialogRef.afterClosed().subscribe(
      res => { }
    );
    // this.router.navigateByUrl(`altacaptador/${uid}`);
  }
}