<div class="delete-account-container">
    <h2>Eliminar Cuenta</h2>
    <form (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="email">Correo Electrónico:</label>
        <input type="email" id="email" [(ngModel)]="this.email" name="email" required />
      </div>
      <div class="form-group">
        <label for="validationCode">Código de Validación<br>(ultimos 4 digitos de tu Nro de Documento):</label>
        <input type="text" id="validationCode" [(ngModel)]="this.validationCode" name="validationCode" required />
      </div>
      <p>
        Al eliminar la cuenta, se borrarán todos tus datos, puntajes y participaciones dentro de nuestra plataforma. EL SISTEMA NO REPREGUNTA AL HACER CLICK EJECUTA LA ELIMINACION, si encuentra los datos, LOS BORRA!! 
      </p>
      <button type="submit" class="delete-button">Eliminar Cuenta</button>
    </form>
    <p *ngIf="errorMessage" class="error-message">{{ errorMessage }}</p>
    <p *ngIf="successMessage" class="success-message">{{ successMessage }}</p>
  </div>
  