<ng-container matColumnDef="Filtro-Botones">
    <button color="secondary" (click)="nuevo()">
        <mat-icon>library_add</mat-icon><br>Crear una Nueva Oferta Laboral
    </button>
    <br>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar Ofertas Laborales">
    </mat-form-field>
</ng-container>


<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="Fecha" matSortDirection="asc">

        <!-- Fecha Column -->
        <ng-container matColumnDef="Fecha">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha</th>
            <td mat-cell *matCellDef="let row">{{row.Fecha | date: 'dd/MM/yyyy HH:mm'}}</td>
        </ng-container>

        <!-- Club Column -->
        <ng-container matColumnDef="Deporte">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Deporte</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.Deporte"> Cargando... </td>
                <td mat-cell *ngIf="row.Deporte==='F11'"> FUTBOL 11 </td>
                <td mat-cell *ngIf="row.Deporte==='FS'"> FUTBOL SALA </td>
                <td mat-cell *ngIf="row.Deporte==='FP'"> FUTBOL PLAYA </td>
                <td mat-cell *ngIf="row.Deporte==='TN'"> TENIS </td>
            </ng-container>
        </ng-container>

        <!-- Entrenamiento Column -->
        <ng-container matColumnDef="Actividad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actividad</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.Actividad"> Cargando... </td>
                <td mat-cell *ngIf="row.Actividad==='AM'"> AMATEUR </td>
                <td mat-cell *ngIf="row.Actividad==='PR'"> PROFESIONAL </td>
            </ng-container>
        </ng-container>

        <!-- Predio Column -->
        <ng-container matColumnDef="Puesto">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Puesto</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.Puesto"> Cargando... </td>
                <td mat-cell *ngIf="row.Puesto==='PF'"> PREPARADOR FISICO </td>
                <td mat-cell *ngIf="row.Puesto==='KG'"> KINESIOLOGO </td>
                <td mat-cell *ngIf="row.Puesto==='MD'"> MEDICO </td>
                <td mat-cell *ngIf="row.Puesto==='EA'"> ENTRENADOR DE ARQUEROS </td>
                <td mat-cell *ngIf="row.Puesto==='AC'"> AYUDANTE DE CAMPO </td>
                <td mat-cell *ngIf="row.Puesto==='AV'"> ANALISTA DE VIDEOS </td>
                <td mat-cell *ngIf="row.Puesto==='AG'"> ANALISTA DE GPS </td>
                <td mat-cell *ngIf="row.Puesto==='DT'"> DIRECTOR TECNICO </td>
            </ng-container>
        </ng-container>

        <!-- Solicitante/Club Column -->
        <ng-container matColumnDef="Solicitante">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Solicitante</th>
            <td mat-cell *matCellDef="let row">{{row.Solicitante}}</td>
        </ng-container>

        <!-- Herramientas Column -->
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
            <td mat-cell *matCellDef="let row">
                <button mat-stroked-button color="accent" (click)="editar(row)">
                        <mat-icon>edit</mat-icon>
                    </button>
                <button mat-stroked-button color="warn" (click)="borrar(row)">
                        <mat-icon>delete</mat-icon>
                    </button>
                <button mat-stroked-button color="primary" (click)="postulados(row)">
                        <mat-icon>grading</mat-icon>
                    </button>
            </td>
        </ng-container>


        <!-- Postulados Column -->
        <ng-container matColumnDef="Postulados">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Postulados</th>
            <td mat-cell *matCellDef="let row">
                <div>
                    <span>
                        <mat-chip color="primary">{{row.Postulados}}</mat-chip>
                    </span>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </mat-card>

    <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons></mat-paginator>

</div>