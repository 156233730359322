import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { AlertasService } from 'src/app/services/alertas.service';
import { Mensaje } from 'src/app/models/chat.model';
import { PruebaService } from 'src/app/services/abm.service';


@Component({
  selector: 'app-tabla-buscan-representante',
  templateUrl: './tabla-buscan-representante.component.html',
  styleUrls: ['./tabla-buscan-representante.component.scss']
})
export class TablaBuscanRepresentanteComponent implements OnInit {
  
  private items: AngularFirestoreCollection<any>;
  public itemchat: AngularFirestoreCollection<Mensaje>;
  tablausu: any;
  displayedColumns: string[] = ['icono', 'correoelectronico', 'nombre', 'apellido', 'deporte','sexo','tipoperfil', 'actividad', 'tipolicencia', 'fechanac', 'Acciones'];
  dataSource = new MatTableDataSource();
  isLoading = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  cantusuarios: number;
  cantmensajes: number;
  sinleer = false;

    constructor(private afs: AngularFirestore,
                private router: Router,
                public alertaSvc: AlertasService,
                public svcabm: PruebaService
                ) { }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Usuarios por página';
    this.dataSource.paginator = this.paginator;
    this.dataSource.data.splice(0);
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {

    // this.cantusuarios = (await this.afs.collection('Usuarios', ref => ref
    //     .where ('buscorepresentante','==', true)).get().toPromise().then()).size;
    this.items = this.afs.collection('Usuarios', ref => ref
        .where ('buscorepresentante','==', true)
        .orderBy('ultimologin', 'desc'));
    this.items.valueChanges().subscribe(
      usuarios => {
        let i = 0;
        usuarios.forEach(async usuario => {
          this.itemchat = this.afs.collection<Mensaje>('chats', ref => ref
                                              .where('uid','==', usuario.correoelectronico)
                                              .where('chat','==', usuario.correoelectronico)
                                              .where('leidoreceptor','==',0 ));
          this.tablausu = this.itemchat.valueChanges().subscribe(
            resp => {
              if (resp.length > 0) {
                usuario.sinleer = true;
              } else { 
                usuario.sinleer = false; 
              }
              usuarios.splice(i , 1);
              usuarios.push(usuario);
            }
          );
          i = i + 1;
        }); 
        if (this.tablausu) {
          this.tablausu.unsubscribe();
        }
        this.isLoading = false;
        this.dataSource.data = usuarios;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  chat( usuario: string) {
    this.router.navigateByUrl(`/chatting/${usuario}`);
  }

  verusuario ( usuario: string ){
    this.router.navigateByUrl(`/perfil/${usuario}`);
  }

  exportexcel(){
    this.svcabm.exportexcel('Usuarios',[0,11,12,13,14]);
  }

  mensajessinleer(){

    if (sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh')){
      this.router.navigateByUrl(`/chats-sin-leer/${sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh')}`);
    } else {
      this.router.navigateByUrl(`/chats-sin-leer/admin`);
    }
  }
}
