import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertasService } from 'src/app/services/alertas.service';
import { Mensaje } from 'src/app/models/chat.model';
import Swal from 'sweetalert2';
import { PruebaService } from 'src/app/services/abm.service';
import * as moment from 'moment';

@Component({
  selector: 'app-jugadorescat',
  templateUrl: './jugadorescat.component.html',
  styleUrls: ['./jugadorescat.component.scss']
})
export class JugadorescatComponent {

  private items: AngularFirestoreCollection<any>;
  public itemchat: AngularFirestoreCollection<Mensaje>;
  public itemusu: AngularFirestoreCollection<any>
  tabla: any;
  tablausu: any;
  displayedColumns: string[] = ['usuario','nombre','apellido', 'tipodoc','nrodoc', 'nacionalidad','telefono', 'fechanac', 'actividad', 'posicion', 'pierna', 'pportecomun', 'antecedentes', 'presente'];
  dataSource = new MatTableDataSource();
  isLoading = false;
  public id: string;
  mensaje: string;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  perfilActividad: string;
  perfilPuesto: string;
  Titulo: string;
  perfilDeporte: any;
  public categoria: any;
  perfilsexo: any;
  total: number;
  public i: number;
  public porcentaje: number;
  
  
  constructor  ( private afs: AngularFirestore,
                 private router: Router,
                 public alertaSvc: AlertasService,
                 private svcabm: PruebaService
                ) {}
              

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Usuarios por página';
    this.dataSource.paginator = this.paginator;
    this.dataSource.data.splice(0);
    this.dataSource.sort = this.sort;
  }

  buscarCat(cate: string) {
    this.isLoading = true;
    const fecmin = moment(`01/01/${cate}`).toDate().getTime();
    const fecmax = moment(`12/31/${cate}`).toDate().getTime();
    this.items = this.afs.collection('Usuarios', ref => ref
                                                .where('tipoperfil','==','JG')
                                                .where('sexo','==','M')
                                                .where('fechanac','<=', fecmax)
                                                .where('fechanac','>=',fecmin)
                                                .orderBy('fechanac', 'desc'));
    const a = this.items.valueChanges().subscribe(
      usuarios => {
        this.total = usuarios.length;
        usuarios.forEach(
          respusu => {
            let tiposDeportes = {'M':'Masculino','A':'Ambos','F':'Femenino'};
            this.perfilsexo = respusu.sexo.replace(/[A-Z]/g, m => tiposDeportes[m]);

            let tiposDep = {'F11':'Fútbol 11','FP':'Fútbol Playa','FS':'Fútbol Sala', 'ES':'ESports'};
            this.perfilDeporte = respusu.deporte.replace(/[A-Z][A-Z0-9][0-9]?/g, m => tiposDep[m]);

            const fechanac = moment(respusu.fechanac).format('YYYY');
            this.categoria = fechanac.toString();

            this.Titulo = `${this.categoria} - ${this.perfilDeporte} ${this.perfilsexo}`;
            respusu.usuario = respusu.correoelectronico;
            // usuarios.splice(0,1);
            // usuarios.push(respusu);
              } 
          );                           
        // });
        
        // if (this.tabla){
        //   this.tabla.unsubscribe();
        //   this.tablausu.unsubscribe();
        // }
        if (!a) {
          a.unsubscribe();
        }
        this.isLoading = false;
        this.dataSource.data = usuarios;
      });
      
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  verperfil( usuario: string ){
    this.router.navigateByUrl(`/perfil/${usuario}`);
  }

  async MensajePantallaInfo(titulo: string, texto: string){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'info',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { });
  }

  exportexcel(){
    this.svcabm.exportexcel(this.Titulo,[10,11]);
  }

  enviarmensaje(){
    if  (this.mensaje.length !== 0) {
      this.agregarMensaje(this.mensaje);
    }
  }

  enviarmensajeausentes(){
    if  (this.mensaje.length !== 0) {
      this.agregarMensaje(this.mensaje, false);
    }
  }

  enviarmensajepresentes(){
    if  (this.mensaje.length !== 0) {
      this.agregarMensaje(this.mensaje, true);
    }
  }

  async agregarMensaje(texto: string, estado?:boolean) {
    if (estado !== undefined){
      if (estado){
        this.itemusu = this.afs.collection('Inscripciones', ref => ref
        .where('id','==',this.id)
        .where('presente','==',true)
        .orderBy('usuario', 'desc'));
      } else {
        this.itemusu = this.afs.collection('Inscripciones', ref => ref
        .where('id','==',this.id)
        .where('presente','==',false)
        .orderBy('usuario', 'desc'));
      }
    } else {
      this.itemusu = this.afs.collection('Inscripciones', ref => ref
                        .where('id','==',this.id)
                        .orderBy('usuario', 'desc'));
    }
    this.tabla = this.itemusu.valueChanges().subscribe(
      usuarios => {
        let cant = 0;
        usuarios.forEach(usuario => {
          cant = cant + 1;
          this.sleep(1);
          const mensaje: Mensaje = {
            nombre: 'MercadoDePases.Club',
            mensaje: texto,
            fecha: new Date().getTime(),
            uid: 'contacto@mercadodepases.club',
            chat: usuario.usuario,
            leidoreceptor: 0
          };
          this.afs.doc(`chats/${mensaje.fecha}`).set(mensaje);
        });
        if (cant === usuarios.length) {
          this.MensajePantalla('Mensajes enviados', `Se enviaron ${cant} los mensajes masivos`);
          this.tabla.unsubscribe();
          this.mensaje = '';  
        }
      }
    );
  }

  sleep(milliseconds: number) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  async MensajePantalla(titulo: string, texto: string){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'success',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { this.mensaje = '' });
  }

  DarPresente(row: any){
    console.log(row);
    const insc = this.afs.collection('Inscripciones', ref => ref.where('id','==',row.id)
                                                   .where('usuario','==',row.usuario));
    const a = insc.snapshotChanges().subscribe( info => {
      this.afs.doc(`Inscripciones/${info[0].payload.doc.id}`).update({ presente: !row.presente });
      a.unsubscribe();
    });
    
  }

  putTodosAusentes(){
    this.i = 0;
    const a = this.afs.collection('Inscripciones', ref => ref
    .where('id','==',this.id)).snapshotChanges().subscribe(
      info => {
        info.forEach( data => {
          this.i = this.i + 1;
          this.porcentaje = this.i / this.total * 100;
          this.afs.doc(`Inscripciones/${data.payload.doc.id}`).update({presente: false});
          a.unsubscribe();
        });
      } 
    )
    this.i = 0;
    this.porcentaje = 0;
  }

  putTodosPresentes(){
    this.i = 0;
    this.porcentaje = 0;
    const a = this.afs.collection('Inscripciones', ref => ref
    .where('id','==',this.id)).snapshotChanges().subscribe(
      info => {
        info.forEach( data => {
          this.i = this.i + 1;
          this.porcentaje = this.i / this.total * 100;
          this.afs.doc(`Inscripciones/${data.payload.doc.id}`).update({presente: true});
          a.unsubscribe();
        });
      } 
    )
    this.i = 0;
    this.porcentaje = 0;
  }
}
