<ng-container matColumnDef="Filtro-Botones">
    <button color="secondary" (click)="nuevo()">
        <mat-icon>add_circle</mat-icon><br>Agregar un Centro de Entrenamiento
    </button>
    <br>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar Centros de Entrenamiento">
    </mat-form-field>
</ng-container>


<div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

        <!-- Nombre Column -->
        <ng-container matColumnDef="Nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Predio</th>
            <td mat-cell *matCellDef="let row">{{row.Nombre}}</td>
        </ng-container>

        <!-- Deportes Column -->
        <ng-container matColumnDef="Deportes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Deportes</th>
            <td mat-cell *matCellDef="let row">{{row.Deportes}}</td>
        </ng-container>

        <!-- Domicilio Column -->
        <ng-container matColumnDef="Domicilio">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Domilicio</th>
            <td mat-cell *matCellDef="let row">{{row.Domicilio}}</td>
        </ng-container>

        <!-- Localidad Column -->
        <ng-container matColumnDef="Localidad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Localidad</th>
            <td mat-cell *matCellDef="let row">{{row.Localidad}}</td>
        </ng-container>

        <!-- Partido Column -->
        <ng-container matColumnDef="Partido">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Partido / Departamento</th>
            <td mat-cell *matCellDef="let row">{{row.Partido}}</td>
        </ng-container>

        <!-- Provincia Column -->
        <ng-container matColumnDef="Provincia">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Provincia</th>
            <td mat-cell *matCellDef="let row">{{row.Provincia}}</td>
        </ng-container>

        <!-- Pais Column -->
        <ng-container matColumnDef="Pais">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>País</th>
            <td mat-cell *matCellDef="let row">{{row.Pais}}</td>
        </ng-container>

        <!-- Latitud Column -->
        <ng-container matColumnDef="Latitude">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Latitud</th>
            <td mat-cell *matCellDef="let row">{{row.Latitude}}</td>
        </ng-container>

        <!-- Longitud Column -->
        <ng-container matColumnDef="Longitude">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Longitud</th>
            <td mat-cell *matCellDef="let row">{{row.Longitude}}</td>
        </ng-container>

        <!-- Usuario Vinculado Column -->
        <ng-container matColumnDef="usuario">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario Vinculado</th>
            <ng-container mat-cell *matCellDef="let row">
                <td mat-cell *ngIf="!row.usuario"> SIN USUARIO </td>
                <td mat-cell *ngIf="row.usuario">{{ row.usuario }}</td>
            </ng-container>
        </ng-container>

        <!-- Clases de Entrenamniento Column -->
        <ng-container matColumnDef="clases">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Clases</th>
            <ng-container mat-cell *matCellDef="let row">
                <td mat-cell *ngIf="!row.clases"> NO </td>
                <td mat-cell *ngIf="row.clases"> SI </td>
            </ng-container>
        </ng-container>

        <!-- Acciones Column -->
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
            <td mat-cell *matCellDef="let row">
                <button mat-stroked-button (click)="vincularusuario(row)">
                    <mat-icon style="color:green">person</mat-icon>
                  </button>
                <button mat-stroked-button (click)="editar(row)">
                  <mat-icon style="color:gold">edit</mat-icon>
                </button>
                <button mat-stroked-button (click)="borrar(row)">
                  <mat-icon style="color: red;">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </mat-card>

    <mat-paginator [pageSizeOptions]="[150, 300]" showFirstLastButtons></mat-paginator>

</div>