import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Equipo } from 'src/app/models/interfaces.model';
import { PruebaService } from 'src/app/services/abm.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nuevoequipo',
  templateUrl: './nuevoequipo.component.html',
  styleUrls: ['./nuevoequipo.component.scss']
})
export class NuevoequipoComponent implements OnInit {

  @Input() equipo: Equipo; // Recibimos una prueba por parámetro
  PhotoURL: string;
  usuarios: any;
  organizadores: any;
  catequipos: any;

  equipoForm = this.fb.group(
    {
      ID: [''],
      idequipo:[''],
      aprobado: [ false ],
      nombre: [''],
      delegado: [''],
      categoria: [''],
      organizador: [''],
      photoURL: ['']
    });


  constructor(
    public pruebaSvc: PruebaService,
    public fb: UntypedFormBuilder
    ) {}

    ngOnInit() {
      this.usuarios = this.pruebaSvc.getUsuarios();
      this.organizadores = this.pruebaSvc.getOrganizadores();
      this.catequipos = this.pruebaSvc.getCatEquipos();
      this.iniciarForm();
    }

  iniciarForm() {
    this.equipoForm.patchValue({ ...this.equipo});
  }

  addEquipo(equipo: Equipo) {
    const FormCompleto = Boolean(equipo.nombre && equipo.photoURL && equipo.organizador && equipo.delegado);
    if (!FormCompleto) {
      this.MensajePantallaError('Faltan Datos','Existen Errores en la carga de la información. Vuelva a cargarlo');
    } else {
        if (this.equipoForm.get('ID').value === '') {
          this.pruebaSvc.addEquipo(equipo);
        } else {
          this.pruebaSvc.editEquipo(equipo);
        }
      }
    }

  async MensajePantallaError(titulo: string, texto: string){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'error',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { });
  }
}
