import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { Mensaje } from '../../models/chat.model';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-chatting',
  templateUrl: './chatting.component.html',
  styleUrls: ['./chatting.component.scss']
})
export class ChattingComponent implements OnInit, OnDestroy {

  @ViewChild('scroller', {static: true} ) private feed: ElementRef;

  private itemsCollection: AngularFirestoreCollection<Mensaje>;
  public chats: Mensaje[] = [];
  elemento: any;
  cargando: boolean;
  tema: string;
  mensaje: string;
  chat: number;
  uid: string;
  nombre: string;
  photoURL: string;
  datos: any;
  chatuid: string;
  public tabla: any;

  public items: Observable<any[]>;

  constructor(
          public db: AngularFirestore,
          private route: ActivatedRoute,
          private router: Router) {

          const uid = this.route.snapshot.paramMap.get('usuario');

          this.cargando = true;
          this.chatuid = uid;
          this.uid = 'contacto@mercadodepases.club';
          this.nombre = 'Administrador';
          this.tabla = this.cargarMensajes(this.chatuid).subscribe(() => {
            setTimeout( () => this.elemento.scrollTop = this.elemento.scrollHeight , 30 );
          });
          this.cargando = false;
  }

  ngOnInit() {
    this.elemento = document.getElementById('app-mensaje');
    this.items = this.db.collection('chats').valueChanges().pipe(delay(1000));
  }

  ngOnDestroy() {
    this.tabla.unsubscribe();
  }

  cargarMensajes(chatuid: string) {
      this.itemsCollection = this.db.collection<Mensaje>('chats', ref => ref
                                                                  .where ('chat', '==', chatuid)
                                                                  .orderBy('fecha', 'desc').limit(20));
      return this.itemsCollection.valueChanges().pipe(
        map((mensajes: Mensaje[]) => {
          this.chats = [];
          for (const mensaje of mensajes ) {
            if (mensaje.uid !== this.uid) {
              if (mensaje.leidoreceptor === 0) {
                this.db.collection('chats').doc(`${mensaje.fecha}`).update({leidoreceptor: new Date().getTime()});
              }
            }
            this.chats.unshift(mensaje); 
          }
          return this.chats; }
        )
      );
    }

  enviar_mensaje()  {

    if  (this.mensaje.length !== 0) {
    this.agregarMensaje(this.mensaje)
        .then (() => this.mensaje = '')
        .catch ((err) => console.log('Error al enviar', err));
      }
    }

  agregarMensaje(texto: string) {
  
      // FALTA el UID
    const mensaje: Mensaje = {
      nombre: this.nombre,
      mensaje: texto,
      fecha: new Date().getTime(),
      uid: this.uid,
      chat: this.chatuid,
      leidoreceptor: 0
    };
    return this.itemsCollection.doc(`${mensaje.fecha}`).set(mensaje);
  }

  perfil(){
    this.router.navigateByUrl(`/perfil/${this.chatuid}`);
  }

}
