<ng-container matColumnDef="Filtro-Botones">
    <button color="secondary" (click)="nuevo()">
        <mat-icon>add_circle</mat-icon><br>Agregar un Organizador
    </button>
    <br>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar Organizadores">
    </mat-form-field>
</ng-container>


<div class="mat-elevation-z8">
    <!-- <button mat-fab class="fab-bottom-right"><mat-icon>add</mat-icon></button> -->
    <table mat-table [dataSource]="dataSource" matSort>

        <!-- Icono Column -->
        <ng-container matColumnDef="icono">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
            <td style="text-align:left; padding: 10px;" mat-cell *matCellDef="let row">
                <p style="font-weight: bold;font-size: 14px;">
                    <button type="button" style="width:40px;height:40px;background-color:rgb(27, 89, 128);color:lightsteelblue;border-radius: 50%;">
                        <img [src]="row.photoURL" />
                    </button>
                </p>
            </td>
        </ng-container>

        <!-- Nombre Column -->
        <ng-container matColumnDef="Organizador">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Organizador</th>
            <td mat-cell *matCellDef="let row">{{row.nombre}}</td>
        </ng-container>

        <!-- ID Column -->
        <ng-container matColumnDef="ID">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
            <td mat-cell *matCellDef="let row">{{row.ID}}</td>
        </ng-container>

        <!-- Deportes Column -->
        <ng-container matColumnDef="Deportes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Deportes</th>
            <td mat-cell *matCellDef="let row">{{row.deportes}}</td>
        </ng-container>

        <!-- Vinculado Column -->
        <ng-container matColumnDef="Vinculado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuarios Vinculados</th>
            <td mat-cell *matCellDef="let row">{{row.usuarios}}</td>
        </ng-container>

        <!-- Estado Column -->
        <ng-container matColumnDef="Estado">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Estado</th>
            <td mat-cell *matCellDef="let row">
                <mat-slide-toggle *ngIf="row.estado; else noActivo" checked (click)="activar(row)"></mat-slide-toggle>
                <ng-template #noActivo>
                    <mat-slide-toggle (click)="activar(row)"></mat-slide-toggle>
                </ng-template>
            </td>
        </ng-container>

        <!-- Acciones Column -->
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
            <td mat-cell *matCellDef="let row">
                <button mat-stroked-button (click)="editar(row)">
                  <mat-icon style="color:gold">edit</mat-icon>
                </button>
                <button mat-stroked-button (click)="borrar(row)">
                  <mat-icon style="color: red;">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </mat-card>

    <mat-paginator [pageSizeOptions]="[150, 300]" showFirstLastButtons></mat-paginator>

</div>