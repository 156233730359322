import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PruebaService } from '../../services/abm.service';
import { Organizador } from 'src/app/models/interfaces.model';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { DialogorganizadorComponent } from '../shared/dialogorganizador/dialogorganizador.component';

@Component({
  selector: 'app-tabla-organizadores',
  templateUrl: './tabla-organizadores.component.html',
  styleUrls: ['./tabla-organizadores.component.scss']
})
export class TablaOrganizadoresComponent implements OnInit , AfterViewInit {

  displayedColumns: string[] = ['Organizador','ID','Deportes','Estado','Vinculado','Acciones'];
  dataSource = new MatTableDataSource();

  isLoading = true;

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(
    private pruebaSvc: PruebaService,
    public dialog: MatDialog
  ) { }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Organizadores por página';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit() {
    this.pruebaSvc.getOrganizadores().subscribe(
      predios => {
        this.isLoading = false;
        this.dataSource.data = predios;
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  nuevo() {
    this.openDialog();
  }

  openDialog(organizador?: Organizador) {
    const config = {
      data: {
        message: organizador ? 'Editar Organizador' : 'Nuevo Organizador',
        content: organizador
      }
    };
    const dialogRef = this.dialog.open(DialogorganizadorComponent, config);
    dialogRef.afterClosed().subscribe(
      res => {  }
    );
  }

  editar(organizador: Organizador) {
    this.openDialog(organizador);
  }

  borrar(organizador: Organizador) {
    Swal.fire({
      title: '¿Está seguro?',
      text: 'Esta accion no se puede revertir',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then(
      aceptar => {
        if (aceptar.value) {
          this.pruebaSvc.deleteOrganizador(organizador)
          .then( () => {
            Swal.fire('Organizador eliminado', 'El Organizador ha sido eliminado', 'success');
          })
          .catch((err) => {
            Swal.fire('Error!', 'El Organizador no ha sido eliminado', 'error');
          });
        }
      });
  }
  
  activar(organizador: Organizador){
    this.pruebaSvc.estadoOrganizador(organizador, organizador.estado);
  }

}

