<ng-container>

    <table width=80% padding=20px>
        <th>
            <h4 class="passion-one is-big is-size-1 has-text-centered has-text-weight-bold has-text-black">LO QUE TENEMOS PARA TU PERFIL</h4>
            <h2 class="has-text- has-text-centered">Elige y observa las opciones para vos</h2>
        </th>
    </table>
    <table style="width:80%;padding:20px">
        <tr style="padding:20px" align="center">
            <button style="vertical-align: middle; width:500px; border-radius: 20px;" class="passion-one is-big is-size-1 has-text-centered has-text-weight-bold has-text-black" routerLink="/ce">
                <label style="text-align: center; font-size: 20px;">Centro Entrenamientos</label>
            </button>
        </tr>
        <tr style="padding:20px;" align="center">
            <button style="width:500px; border-radius: 20px;" class="passion-one is-big is-size-1 has-text-centered has-text-weight-bold has-text-black" routerLink="/cb">   
                <label style="text-align: center; font-size: 20px;">Clubes</label> 
            </button>
        </tr>
        <tr style="padding:20px" align="center">
            <button style="width:500px; border-radius: 20px;" class="passion-one is-big is-size-1 has-text-centered has-text-weight-bold has-text-black" routerLink="/dt">
                <label style="text-align: center; font-size: 20px;">Cuerpos Técnicos</label>  
            </button>
        </tr>
        <tr style="padding:20px" align="center">
            <button style="width:500px; border-radius: 20px;" class="passion-one is-big is-size-1 has-text-centered has-text-weight-bold has-text-black" routerLink="/jg">
                <label style="text-align: center; font-size: 20px;">Jugadores</label>
            </button>
        </tr>
        <hr>
        <tr style="padding:20px" align="center">
            <button style="width:250px; border-radius: 20px; background-color: red; color: white" routerLink="/login">
                <label style="vertical-align: middle; font-size: 20px;">VOLVER AL LOGIN</label>
            </button>
        </tr>
    </table>

</ng-container>