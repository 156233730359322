<div class="nueva-prueba-container">
    <form [formGroup]="organizadorForm" (ngSubmit)="addOrganizador(organizadorForm.value)">
        <mat-form-field>
            <input matInput type="text" placeholder="Nombre" formControlName="nombre" aria-label="Label">
        </mat-form-field>

        <mat-form-field>
            <mat-selection-list placeholder="Deportes" formControlName="deportes">
                <mat-list-option *ngFor="let deporte of ['F11','F7','F5','FS','FP','ES','TN']" [value]="deporte">
                    {{deporte}}
                </mat-list-option>
            </mat-selection-list>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="Seleccione un Usuario" formControlName="usuarios">
                <mat-option *ngFor="let usuario of ( usuarios | async )" [value]="usuario.correoelectronico">
                    <p style="text-transform: capitalize !important;">{{ usuario.apellido }}, {{ usuario.nombre }}</p>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="container-button">
            <input type="submit" class="mat-flat-button" value="Guardar" [mat-dialog-close]="true">
        </div>
    </form>
</div>