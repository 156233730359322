import { AfterContentChecked, AfterViewInit, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { AlertasService } from 'src/app/services/alertas.service';
import { Mensaje } from 'src/app/models/chat.model';
import { PruebaService } from 'src/app/services/abm.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';
import { combineLatest, map, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit, AfterContentChecked, AfterViewInit, OnDestroy {

  public items: AngularFirestoreCollection<any>;
  public itemchat: AngularFirestoreCollection<Mensaje>;
  tablausu: any;
  displayedColumns: string[] = ['correoelectronico', 'proveedor', 'nombre', 'apellido', 'perfil', 'deporte', 'sexo', 'fechanac', 'ultimologin', 'version', 'Acciones'];
  dataSource = new MatTableDataSource();
  isLoading = true;
  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  cantusuarios: number;
  cantmensajes = -1;
  sinleer = true;
  public porcentaje: number;
  isLoadingcontar = false;
  i: number;
  puntos: number;
  analizando: boolean;
  public suscription: any;

  private pageSize = 100;  // Tamaño de la página
  private lastDoc: any = null;  // Último documento cargado

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    public alertaSvc: AlertasService,
    public svcabm: PruebaService,
    public authfs: AuthService,
    private http: HttpClient  
  ) { }

  ngAfterContentChecked() {
    this.sinleer = this.cantmensajes <= 0;
  }

  ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Usuarios por página';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async ngOnInit() {
    this.loadInitialData();

    this.items.valueChanges().pipe(take(1)).subscribe(usuarios => {
      this.isLoading = false;
      this.dataSource.data = usuarios;
      console.log(this.dataSource.data);
    });

    this.checkUnreadMessages();
  }

  private async loadInitialData() {
    try {
      const [usuariosSnapshot, mensajesSnapshot] = await Promise.all([
        this.afs.collection('Usuarios', ref => ref.limit(this.pageSize).orderBy('ultimologin', 'desc')).get().toPromise(),
        this.afs.collection('chats', ref => ref
          .where('uid', '!=', 'contacto@mercadodepases.club')
          .where('leidoreceptor', '==', 0)
        ).get().toPromise()
      ]);

      // this.cantusuarios = usuariosSnapshot.size;
      // Solicitud a la API para obtener el número total de usuarios
      this.http.get<{ count: number }>('https://mdp.up.railway.app/api/user/cantTotal')
        .subscribe(response => {
          this.cantusuarios = response.count;  // Asigna el resultado a cantusuarios
        }, error => {
          console.error('Error al obtener el número total de usuarios:', error);
          this.cantusuarios = 0;  // Opcional: Asignar un valor predeterminado en caso de error
      });
      this.cantmensajes = mensajesSnapshot.size;
      this.isLoadingcontar = this.cantmensajes >= 0 ? false : true;
      this.lastDoc = usuariosSnapshot.docs[usuariosSnapshot.docs.length - 1];

      this.dataSource.data = usuariosSnapshot.docs.map(doc => doc.data());
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  private checkUnreadMessages() {
    this.afs.collection('chats', ref => ref
      .where('uid', '!=', 'contacto@mercadodepases.club')
      .where('leidoreceptor', '==', 0)
    ).valueChanges().subscribe(mensajes => {
      this.cantmensajes = mensajes.length;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  chat(usuario: string) {
    this.router.navigateByUrl(`/chatting/${usuario}`);
  }

  verusuario(usuario: string) {
    this.router.navigateByUrl(`/perfil/${usuario}`);
  }

  exportexcel() {
    this.svcabm.exportexcel('Usuarios', [0, 11, 12, 13, 14]);
  }

  mensajessinleer() {
    if (this.cantmensajes > 0) {
      const sessionUser = sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh');
      const route = sessionUser ? `/chats-sin-leer/${sessionUser}` : `/chats-sin-leer/admin`;
      this.router.navigateByUrl(route);
    }
  }

  depurador() {
    Swal.fire({
      title: 'Estás seguro?',
      text: "Eliminarás los Usuarios Incompletos y no hay vuelta atrás",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminalos!'
    }).then((result) => {
      if (result.isConfirmed) {
        let haceunmes = moment(Date.now()).add(-1, 'month').toDate();
        this.items = this.afs.collection('Usuarios', ref => ref.where('actividad', '==', 'NO')
          .where('ultimologin', '<=', haceunmes));
        this.items.valueChanges().subscribe(
          usuarios => {
            if (!usuarios) {
              this.MensajePantallaError('Error', 'Existen Errores en la carga de la información. Vuelva a intentarlo');
              return;
            }
            setTimeout(() => {
              this.porcentaje = 0;
            }, 2500);
            let i = 0;
            let x = 0;
            usuarios.forEach(info => {
              i++;
              if (!info.tipodoc && !info.nrodoc) {
                this.porcentaje = ((i / usuarios.length) * 100);
                this.authfs.eliminarCuenta(info.correoelectronico);
                x++;
              }
            });
            this.MensajePantallaOK('Depurar Usuarios Incompletos', `Se eliminaron: ${x} Usuarios Incompletos`);
          });
      }
    });
  }

  async MensajePantallaError(titulo: string, texto: string) {
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'error',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { });
  }

  async MensajePantallaOK(titulo: string, texto: string) {
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'success',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { });
  }

  buscarusuario() {
    Swal.fire({
      title: 'Usuario a Buscar',
      text: "Ingresar el email a buscar",
      input: "email",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Buscar'
    }).then((result) => {
      if (result.isConfirmed && result.value != '') {
        this.router.navigateByUrl(`/perfil/${result.value}`);
      }
    });
  }

  startanalizadorpuntajes() {
    this.items = this.afs.collection('Usuarios', ref => ref.limit(1).orderBy('ultimologin', 'desc'));
    this.suscription = this.items.valueChanges().subscribe(
      usuarios => {
        this.i = 0;
        this.porcentaje = 0;
        if (usuarios.length != 0) {
          usuarios.forEach(element => {
            this.puntos = 0;
            this.i++;
            this.porcentaje = ((this.i / usuarios.length) * 100);
            const a = this.afs.collection('Inscripciones', ref => ref.where('usuario', '==', element.correoelectronico));
            a.valueChanges().subscribe(
              inscripciones => {
                if (inscripciones.length > 0) {
                  this.puntos = inscripciones.length;
                  inscripciones.forEach((data: any) => {
                    if (data.up) {
                      this.puntos += 10;
                    }
                    if (data.presente) {
                      this.puntos += 5;
                    } else if (!data.presente) {
                      this.puntos -= 1;
                    }
                  });
                }
                console.log(element.correoelectronico, this.puntos);
                this.afs.doc(`Usuarios/${element.correoelectronico}`).update({ puntaje: this.puntos });
              });
          });
        }
      });
    this.analizando = true;
  }

  stopanalizadorpuntajes() {
    this.suscription.unsubscribe();
    this.porcentaje = 0;
    this.analizando = false;
  }

  ngOnDestroy() {
    if (this.analizando) {
      console.log('ON DESTROY!');
      this.stopanalizadorpuntajes();
    }
  }
}
