<div class="app-mensaje" id="app-mensaje">
    <div class="chat-window" [ngClass]="{'derecho text-right': uid == chat.uid, 'izquierdo text-left': uid !== chat.uid}" *ngFor="let chat of chats" style="background-color: rgb(228, 226, 226);">
        <div class="padding">
            <span class="badge badge-primary" [ngClass]="{ 'negativo': uid != chat.uid , 'positivo': uid == chat.uid }" (click)="this.perfil()">{{ chat.nombre }}</span>
            <h6 style="padding: 10px;margin-left: 35px;margin-right: 35px; text-align:start;" [ngClass]="{'ion-text-left': uid !== chat.uid}">{{chat.mensaje}}</h6>
            <p style="padding: 10px;font-size: 12px;text-align: right;margin-right: px;">{{ chat.fecha | date:'dd/MM/yyyy HH:mm'}}
                <mat-icon *ngIf="chat.leidoreceptor===0" style="margin-right: 0px;text-align:right;color:grey">done</mat-icon>
                <mat-icon *ngIf="chat.leidoreceptor!==0" style="margin-right: 0px;text-align:right;color:royalblue">done_all</mat-icon>
            </p>
        </div>
    </div>
</div>
<footer>
    <input type="text" class="textarea" [(ngModel)]="mensaje" name="mensaje" placeholder="Escribe un mensaje">
    <button (click)="enviar_mensaje()"><i class="material-icons right">send</i></button>
</footer>