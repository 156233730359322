<div class="nueva-prueba-container">
    <form [formGroup]="beneficiosForm" (ngSubmit)="addBeneficio(beneficiosForm.value)">

        <mat-form-field>
            <input matInput type="text" placeholder="Etiqueta del Beneficio" formControlName="nombre" aria-label="Label">
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="Tipo de Beneficio" formControlName="tipo">
                <mat-option value="DESC10">Descuento 10%</mat-option>
                <mat-option value="DESC25">Descuento 25%</mat-option>
                <mat-option value="DESC30">Descuento 30%</mat-option>
                <mat-option value="DESC33">Descuento 33%</mat-option>
                <mat-option value="DESC50">Descuento 50%</mat-option>
                <mat-option value="CU02SI">2 Cuotas Sin Interes</mat-option>
                <mat-option value="CU03SI">3 Cuotas Sin Interes</mat-option>
                <mat-option value="CU04SI">4 Cuotas Sin Interes</mat-option>
                <mat-option value="CU06SI">6 Cuotas Sin Interes</mat-option>
                <mat-option value="CU09SI">9 Cuotas Sin Interes</mat-option>
                <mat-option value="CU12SI">12 Cuotas Sin Interes</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="container-button">
            <input type="submit" class="mat-flat-button" value="Guardar" [mat-dialog-close]="true">
        </div>
    </form>
</div>