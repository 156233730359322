<ng-container matColumnDef="Filtro-Botones">
    <button color="secondary" (click)="exportexcel()">
        <mat-icon>file_download</mat-icon><br>Exportar a <br>Excel
    </button>
    <button color="secondary" (click)="putTodosAusentes()">
        <mat-icon>check_circle_outline</mat-icon><br>Poner a Todos Ausentes
    </button>
    <button color="secondary" (click)="putTodosPresentes()">
        <mat-icon>highlight_off</mat-icon><br>Poner a Todos Presentes
    </button>
    <br>
    <div>
        <mat-form-field>
            <input style="border-bottom-color: black;width: 100%" matInput [(ngModel)]="mensaje" name="mensaje" placeholder="Escriba su mensaje a TODOS LOS INSCRIPTOS de esta prueba">
        </mat-form-field>
        <button color="secondary" (click)="enviarmensaje()">
            <mat-icon>send</mat-icon><br>Mensaje a <br>TODOS
        </button>
        <button color="secondary" (click)="enviarmensajepresentes()">
            <mat-icon>send</mat-icon><br>Mensaje a <br>PRESENTES
        </button>
        <button color="secondary" (click)="enviarmensajeausentes()">
            <mat-icon>send</mat-icon><br>Mensaje a <br>AUSENTES
        </button>
    </div>
    <br>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar Usuarios">
    </mat-form-field>
    <br>
    <div>
        <mat-form-field>
            <mat-select [(ngModel)]="categoria" name="categoria" placeholder="Seleccione la Categoría">
                <mat-option *ngFor="let cat of [1996,1997,1998,1999,2000,2001,2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016]" [value]="cat" (onSelectionChange)="this.buscarCat(cat)">
                    {{cat}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</ng-container>
<div>
    <tr width="1250px">
        <td width='25%'>
            <h2 style="padding: 20px; font-size: 18px; font-weight: 1500; color: red;">{{ total }} Inscriptos</h2>
        </td>
        <td width='75%'>
            <div class="progress">
                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': porcentaje + '%' }">
                </div>
            </div>
        </td>
    </tr>
    <h2>Listado de Inscriptos para la PRUEBA: {{Titulo}}</h2>
    <table id="excel-table" mat-table [dataSource]="dataSource" matSort>

        <!-- Present Column -->
        <ng-container matColumnDef="presente">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Concurrencia</th>
            <ng-container *matCellDef="let row">
                <td>
                    <button mat-cell *ngIf="!row.presente" style="text-align: center; width: 70%; vertical-align: middle; background: red; color:white" (click)="DarPresente(row)">AUSENTE</button>
                    <button mat-cell *ngIf="row.presente" style="text-align: center; width: 70%; vertical-align: middle; background: green; color:white" (click)="DarPresente(row)"> PRESENTE</button>
                </td>
            </ng-container>
        </ng-container>

        <!-- Usuario Column -->
        <ng-container matColumnDef="usuario">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.usuario"> Cargando... </td>
                <td mat-cell *ngIf="row.usuario" style="text-align: left;"><a style="color:red; cursor:pointer;" (click)="verperfil(row.usuario)">{{row.usuario}}</a></td>
            </ng-container>
        </ng-container>

        <!-- Nombre Column -->
        <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.nombre"> Cargando... </td>
                <td mat-cell *ngIf="row.nombre" style="text-align: left;"> {{row.nombre}}</td>
            </ng-container>
        </ng-container>

        <!-- Apellido Column -->
        <ng-container matColumnDef="apellido">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Apellido</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.apellido"> Cargando... </td>
                <td mat-cell *ngIf="row.apellido" style="text-align: left;"> {{row.apellido}}</td>
            </ng-container>
        </ng-container>

        <!-- Tipo Documento Column -->
        <ng-container matColumnDef="tipodoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo Doc</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.tipodoc"> Cargando... </td>
                <td mat-cell *ngIf="row.tipodoc==='1'"> DNI </td>
                <td mat-cell *ngIf="row.tipodoc==='2'"> PASAPORTE </td>
                <td mat-cell *ngIf="row.tipodoc==='3'"> CC </td>
                <td mat-cell *ngIf="row.tipodoc==='4'"> CI </td>
                <td mat-cell *ngIf="row.tipodoc==='5'"> TI </td>
                <td mat-cell *ngIf="row.tipodoc==='6'"> RC </td>
                <td mat-cell *ngIf="row.tipodoc==='7'"> DUI </td>
                <td mat-cell *ngIf="row.tipodoc==='8'"> ID </td>
                <td mat-cell *ngIf="row.tipodoc==='9'"> DPI </td>
                <td mat-cell *ngIf="row.tipodoc==='10'"> CURP </td>
                <td mat-cell *ngIf="row.tipodoc==='11'"> CIP </td>
            </ng-container>
        </ng-container>

        <!-- NroDoc Column -->
        <ng-container matColumnDef="nrodoc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nro Documento</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.nrodoc"> Cargando... </td>
                <td mat-cell *ngIf="row.nrodoc" style="text-align: left;"> {{row.nrodoc}}</td>
            </ng-container>
        </ng-container>

        <!-- Nacionalidad Column -->
        <ng-container matColumnDef="nacionalidad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Nacionalidad</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.nacionalidad"> Cargando... </td>
                <td mat-cell *ngIf="row.nacionalidad" style="text-align: left;"> {{row.nacionalidad}}</td>
            </ng-container>
        </ng-container>

        <!-- Telefono Column -->
        <ng-container matColumnDef="telefono">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefono</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.telefono"> Cargando... </td>
                <td mat-cell *ngIf="row.telefono" style="text-align: left;"> {{row.telefono}}</td>
            </ng-container>
        </ng-container>

        <!-- Fecha Nacimiento Column -->
        <ng-container matColumnDef="fechanac">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fecha Nac</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.fechanac"> MAL CARGADA </td>
                <td mat-cell *ngIf="row.fechanac">{{row.fechanac | date: 'dd/MM/yyyy'}}</td>
            </ng-container>
        </ng-container>

        <!-- Actividad Column -->
        <ng-container matColumnDef="actividad">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Actividad</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.actividad"> Cargando... </td>
                <td mat-cell *ngIf="row.actividad==='AM'"> AMATEUR </td>
                <td mat-cell *ngIf="row.actividad==='PL'"> PROF LIBRE </td>
                <td mat-cell *ngIf="row.actividad==='PA'"> PROF ACTIVO </td>
                <td mat-cell *ngIf="row.actividad==='SR'"> SENIOR </td>
            </ng-container>
        </ng-container>

        <!-- Posicion Column -->
        <ng-container matColumnDef="posicion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Posición</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.posicion"> Cargando... </td>
                <td mat-cell *ngIf="row.posicion==='PO'"> ARQUERO </td>
                <td mat-cell *ngIf="row.posicion==='DF'"> DEFENSOR </td>
                <td mat-cell *ngIf="row.posicion==='MC'"> MEDIOCAMPISTA </td>
                <td mat-cell *ngIf="row.posicion==='DE'"> DELANTERO </td>
            </ng-container>
        </ng-container>

        <!-- Pierna Column -->
        <ng-container matColumnDef="pierna">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pierna</th>
            <ng-container *matCellDef="let row">
                <td mat-cell *ngIf="!row.pierna"> Cargando... </td>
                <td mat-cell *ngIf="row.pierna==='RG'"> DERECHA </td>
                <td mat-cell *ngIf="row.pierna==='LF'"> IZQUIERDA </td>
                <td mat-cell *ngIf="row.pierna==='BT'"> AMBIDIESTRO </td>
            </ng-container>
        </ng-container>

        <!-- Antecedentes Column -->
        <ng-container matColumnDef="antecedentes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Antecedentes <br> Deportivos</th>
            <td mat-cell *matCellDef="let row">
                <div>
                    <span *ngIf="row.antecedentes else noAntec">
                    <mat-chip selected color="primary">SI</mat-chip>
                </span>
                    <ng-template #noAntec>
                        <mat-chip selected color="accent">NO</mat-chip>
                    </ng-template>
                </div>
            </td>
        </ng-container>

        <!-- pportecomun Column -->
        <ng-container matColumnDef="pportecomun">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pasaporte</th>
            <td mat-cell *matCellDef="let row">
                <div>
                    <span *ngIf="row.pportecomun; else noPP">
                    <mat-chip selected color="primary">SI</mat-chip>
                </span>
                    <ng-template #noPP>
                        <mat-chip selected color="accent">NO</mat-chip>
                    </ng-template>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>

    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </mat-card>

    <mat-paginator [pageSizeOptions]="[150, 500, 1000]" showFirstLastButtons></mat-paginator>

</div>