import { Component, AfterContentChecked, ViewChild, OnInit, HostListener } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Location } from '@angular/common';

// SERVICIOS
import { AuthService } from '../services/auth.service';
import { AlertasService } from '../services/alertas.service';

// INTERFACES
import { Pedido, Prueba } from '../models/interfaces.model';

// COMPONENTES
import { DialogComponent } from '../componentes/shared/dialog/dialog.component';

// TODO: VER SI ESTO VA ACA
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../componentes/modal/modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements AfterContentChecked, OnInit {

  @ViewChild('sidenav', {static: false}) sidenav: any;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  perfiladmin: boolean;
  public entidad: string;
  public tipoentidad: string;
  public cargando: true;
  public muestro = false;
  url: string;
  public innerWidth: any;
  public event: any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public authSvc: AuthService,
    public dialog: MatDialog,
    public alertaSvc: AlertasService,
    public router: Router,
    public location: Location
    ) { }

  ngOnInit(){
    this.innerWidth = window.innerWidth;
  }

  ngAfterContentChecked(){
    this.muestro = false;
    this.url = document.location.pathname;
    if (sessionStorage.getItem('admao.@uhg523sodhfgvin')){
      this.perfiladmin = true;
      this.muestro = true;
      this.entidad = '';
      this.tipoentidad = ''
    } else if (sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh') && sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj')){
      this.perfiladmin = false;
      this.entidad = sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh');
      this.tipoentidad = sessionStorage.getItem('598tuw9tuq4a8gnsigaisdgañdsigjadsflkgj');
      this.muestro = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }


  toggleSidenav()
  {
    this.sidenav.toggle();
  }

  nuevo() {
    this.openDialog2();
  }
  
  openDialog(prueba?: Prueba): void {
    const config = {
      data: {
        message: prueba ? 'Editar Prueba' : 'Nueva Prueba',
        content: prueba
      }
    };
    const dialogRef = this.dialog.open(ModalComponent, config);
    dialogRef.afterClosed().subscribe(
      res => { }
    );
  }

  openDialog2(pedido?: Pedido) {
    const config = {
      data: {
        message: pedido ? 'Editar Oferta Laboral' : 'Nueva Oferta Laboral',
        content: pedido
      }
    };
    const dialogRef = this.dialog.open(DialogComponent, config);
    dialogRef.afterClosed().subscribe(
      res => { }
    );
  }

  salir(){
    this.authSvc.signOut();
    this.muestro = false;
    this.home();
  }

  home(){
    // this.muestro = false;
    this.router.navigateByUrl('/home');
  }

  login(){
    this.router.navigateByUrl('/login');
  }
}
