<ng-container matColumnDef="Filtro-Botones">
    <button color="secondary" (click)="nuevo()">
        <mat-icon>add_circle</mat-icon><br>Agregar un Club
    </button>
    <br>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filtrar Clubes">
    </mat-form-field>
</ng-container>


<div class="mat-elevation-z8">
    <!-- <button mat-fab class="fab-bottom-right"><mat-icon>add</mat-icon></button> -->
    <table mat-table [dataSource]="dataSource" matSort>

        <!-- Fecha Column -->
        <ng-container matColumnDef="Club">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Club</th>
            <td mat-cell *matCellDef="let row">{{row.Club}}</td>
        </ng-container>

        <!-- Club Column -->
        <ng-container matColumnDef="Fundacion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fundacion</th>
            <td mat-cell *matCellDef="let row">{{row.Fundacion}}</td>
        </ng-container>

        <!-- PhotoURL Column -->
        <ng-container matColumnDef="Escudo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Escudo</th>
            <td mat-cell *matCellDef="let row">
                <mat-icon mat-card-avatar>
                    <img [src]="row.photoURL" width="75%" height="75%" />
                </mat-icon>
            </td>
        </ng-container>

        <!-- Acciones Column -->
        <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Acciones</th>
            <td mat-cell *matCellDef="let row">
                <button mat-stroked-button (click)="editar(row)">
                  <mat-icon style="color:gold">edit</mat-icon>
                </button>
                <button mat-stroked-button (click)="borrar(row)">
                  <mat-icon style="color: red;">delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
    </mat-card>

    <mat-paginator [pageSizeOptions]="[150, 300]" showFirstLastButtons></mat-paginator>

</div>