import { Beneficio } from 'src/app/models/interfaces.model';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { PruebaService } from 'src/app/services/abm.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nuevobeneficio',
  templateUrl: './nuevobeneficio.component.html',
  styleUrls: ['./nuevobeneficio.component.scss']
})
export class NuevobeneficioComponent implements OnInit {

  @Input() beneficio: Beneficio; // Recibimos un beneficio por parámetro

  beneficiosForm = this.fb.group(
    {
      id: [''],
      activo:[true],
      nombre: [''],
      tipo: ['']
    });

  constructor(
    public pruebaSvc: PruebaService,
    public fb: UntypedFormBuilder
    ) { }

  ngOnInit() {
    this.iniciarForm();
  }

  iniciarForm() {
    this.beneficiosForm.patchValue({ ...this.beneficio});
  }

  addBeneficio(beneficio: Beneficio) {
    const FormCompleto = Boolean(beneficio.activo && beneficio.nombre && beneficio.tipo);
    if (!FormCompleto) {
      this.MensajePantallaError('Faltan Datos','Existen Errores en la carga de la información. Vuelva a cargarlo');
    } else {
        if (this.beneficiosForm.get('id').value === '') {
          this.pruebaSvc.addBeneficio(beneficio);
        } else {
          this.pruebaSvc.editBeneficios(beneficio);
        }
      }
    }

  async MensajePantallaError(titulo: string, texto: string){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'error',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { });
  }
}
