import { Component } from '@angular/core';
import { AccountService } from '../../services/account.service'; // Asegúrate de que la ruta sea correcta

@Component({
  selector: 'app-borrarcuenta',
  templateUrl: './borrarcuenta.component.html',
  styleUrls: ['./borrarcuenta.component.scss']
})

export class BorrarCuentaComponent {
  
  public email: string = '';
  public validationCode: string = '';
  errorMessage: string = '';
  successMessage: string = '';

  constructor(private accountService: AccountService) {}

  onSubmit() {
    this.errorMessage = '';
    this.successMessage = '';

    this.accountService.verifyValidationCode(this.email, this.validationCode).subscribe(
      response => {
        if (response.success) {
          this.successMessage = 'Cuenta eliminada exitosamente.';
          this.runControl();
        } else {
          this.errorMessage = response.message || 'Código de validación incorrecto o expirado.';
        }
      },
      error => {
        this.errorMessage = 'Error al eliminar la cuenta. Por favor, intente de nuevo.';
      }
    );
  }

  runControl() {
    this.accountService.runControl().subscribe(
      response => {
        console.log('Control ejecutado correctamente:', response);
      },
      error => {
        console.error('Error al ejecutar el control:', error);
      }
    );
  }
}

