import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertasService } from 'src/app/services/alertas.service';
import { Mensaje } from 'src/app/models/chat.model';
import Swal from 'sweetalert2';
import { PruebaService } from 'src/app/services/abm.service';
import * as moment from 'moment';


@Component({
  selector: 'app-tabla-inscriptos',
  templateUrl: './tabla-inscriptos.component.html',
  styleUrls: ['./tabla-inscriptos.component.scss']
})
export class TablaInscriptosComponent implements OnInit {

    private items: AngularFirestoreCollection<any>;
    public itemchat: AngularFirestoreCollection<Mensaje>;
    public itemusu: AngularFirestoreCollection<any>
    tabla: any;
    tablausu: any;
    displayedColumns: string[] = ['fecha','usuario','nombre','apellido', 'tipodoc','nrodoc', 'nacionalidad','telefono', 'fechanac', 'actividad', 'posicion', 'pierna', 'pportecomun', 'antecedentes', 'Acciones', 'presente'];
    dataSource = new MatTableDataSource();
    isLoading = true;
    public id: string;
    mensaje: string;
  
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    perfilActividad: string;
    perfilPuesto: string;
    Titulo: string;
    perfilDeporte: any;
    categoria: any;
    perfilsexo: any;
    total: number;
    public i: number;
    public porcentaje: number;
    public club: string;
    public predio: any;
    
    constructor  ( private afs: AngularFirestore,
                   private router: Router,
                   public alertaSvc: AlertasService,
                   private route: ActivatedRoute,
                   private svcabm: PruebaService
                  ) {}
                
  
    ngAfterViewInit() {
      this.paginator._intl.itemsPerPageLabel = 'Usuarios por página';
      this.dataSource.paginator = this.paginator;
      this.dataSource.data.splice(0);
      this.dataSource.sort = this.sort;
    }
  
    ngOnInit() {
      this.id = this.route.snapshot.paramMap.get('id');
      this.items = this.afs.collection('Inscripciones', ref => ref
                                                  .where('id','==',this.id)
                                                  .orderBy('fecha', 'desc'));
      this.items.valueChanges().subscribe(
        usuarios => {
          this.club = usuarios[0].club;
          this.predio = usuarios[0].predio;
          this.total = usuarios.length;
          usuarios.forEach(usuario => {
            this.itemusu = this.afs.collection<Mensaje>('Usuarios', ref => ref
                                                .where('correoelectronico','==', usuario.usuario));
            this.tablausu = this.itemusu.valueChanges().subscribe(
              respusu => {
                if (respusu.length > 0) {
                  this.itemchat = this.afs.collection<Mensaje>('chats', ref => ref
                                                      .where('uid','==', usuario.usuario)
                                                      .where('chat','==', usuario.usuario)
                                                      .where('leidoreceptor','==',0 ));
                  this.tabla = this.itemchat.valueChanges().subscribe(
                    resp => {
                      if (resp.length > 0) {
                        usuario.sinleer = true;
                      } else { 
                        usuario.sinleer = false; 
                      }
                    }
                  );
                  let tiposDeportes = {'M':'Masculino','A':'Ambos','F':'Femenino'};
                  this.perfilsexo = respusu[0].sexo.replace(/[A-Z]/g, m => tiposDeportes[m]);

                  let tiposDep = {'F11':'Fútbol 11','FP':'Fútbol Playa','FS':'Fútbol Sala', 'ES':'ESports'};
                  this.perfilDeporte = respusu[0].deporte.replace(/[A-Z][A-Z0-9][0-9]?/g, m => tiposDep[m]);

                  const fechanac = moment(respusu[0].fechanac).format('YYYY');
                  this.categoria = fechanac.toString();

                  this.Titulo = `${this.categoria} - ${this.predio}`;
                  usuario.antecedentes = respusu[0].antecedentes;
                  usuario.pportecomun = respusu[0].pportecomun; 
                  usuario.usuario = respusu[0].correoelectronico;
                  usuario.nombre = respusu[0].nombre;
                  usuario.apellido = respusu[0].apellido;
                  usuario.tipodoc = respusu[0].tipodoc;
                  usuario.nrodoc = respusu[0].nrodoc;
                  usuario.nacionalidad = respusu[0].nacionalidad;
                  usuario.telefono = respusu[0].telefono;
                  usuario.fechanac = respusu[0].fechanac;
                  usuario.actividad = respusu[0].actividad;
                  usuario.posicion = respusu[0].posicion;
                  usuario.pierna = respusu[0].pierna;
                  usuarios.splice(0 , 1);
                  usuarios.push(usuario);
                } 
              }
            );                           
          });
          
          if (this.tabla){
            this.tabla.unsubscribe();
            this.tablausu.unsubscribe();
          }
          this.isLoading = false;
          this.dataSource.data = usuarios;
        });
        
    }
  
    applyFilter(filterValue: string) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  
    chat( usuario: string ) {
      if (!sessionStorage.getItem('paighpy32ifhadsiajdfakldsjfaerwrasdvaoigh')){
        this.router.navigateByUrl(`/chatting/${usuario}`);
      } else {
        this.MensajePantallaInfo('MUY PRONTO', 'Esta función está en desarrollo')
      }
    }

    verperfil( usuario: string ){
      this.router.navigateByUrl(`/perfil/${usuario}`);
    }

    async MensajePantallaInfo(titulo: string, texto: string){
      Swal.fire({
        title: titulo,
        text: texto,
        icon: 'info',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
      }).then(() => { });
    }
  
    exportexcel(){
      this.svcabm.exportexcel(this.Titulo,[10,11]);
    }

    enviarmensaje(){
      if  (this.mensaje.length !== 0) {
        this.agregarMensaje(this.mensaje);
      }
    }

    enviarmensajeausentes(){
      if  (this.mensaje.length !== 0) {
        this.agregarMensaje(this.mensaje, false);
      }
    }
  
    enviarmensajepresentes(){
      if  (this.mensaje.length !== 0) {
        this.agregarMensaje(this.mensaje, true);
      }
    }

    async agregarMensaje(texto: string, estado?:boolean) {
      if (estado !== undefined){
        if (estado){
          this.itemusu = this.afs.collection('Inscripciones', ref => ref
          .where('id','==',this.id)
          .where('presente','==',true)
          .orderBy('usuario', 'desc'));
        } else {
          this.itemusu = this.afs.collection('Inscripciones', ref => ref
          .where('id','==',this.id)
          .where('presente','==',false)
          .orderBy('usuario', 'desc'));
        }
      } else {
        this.itemusu = this.afs.collection('Inscripciones', ref => ref
                          .where('id','==',this.id)
                          .orderBy('usuario', 'desc'));
      }
      this.tabla = this.itemusu.valueChanges().subscribe(
        usuarios => {
          let cant = 0;
          usuarios.forEach(usuario => {
            cant = cant + 1;
            this.sleep(1);
            const mensaje: Mensaje = {
              nombre: 'MercadoDePases.Club',
              mensaje: texto,
              fecha: new Date().getTime(),
              uid: 'contacto@mercadodepases.club',
              chat: usuario.usuario,
              leidoreceptor: 0
            };
            this.afs.doc(`chats/${mensaje.fecha}`).set(mensaje);
          });
          if (cant === usuarios.length) {
            this.MensajePantalla('Mensajes enviados', `Se enviaron ${cant} los mensajes masivos`);
            this.tabla.unsubscribe();
            this.mensaje = '';  
          }
        }
      );
    }
  
    sleep(milliseconds: number) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    }
  
    async MensajePantalla(titulo: string, texto: string){
      Swal.fire({
        title: titulo,
        text: texto,
        icon: 'success',
        showConfirmButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Aceptar',
      }).then(() => { this.mensaje = '' });
    }
  
    DarPresente(row: any){
      console.log(row);
      const insc = this.afs.collection('Inscripciones', ref => ref.where('id','==',row.id)
                                                     .where('usuario','==',row.usuario));
      const a = insc.snapshotChanges().subscribe( info => {
        this.afs.doc(`Inscripciones/${info[0].payload.doc.id}`).update({ presente: !row.presente });
        a.unsubscribe();
      });
      
    }

    putTodosAusentes(){
      this.i = 0;
      const a = this.afs.collection('Inscripciones', ref => ref
      .where('id','==',this.id)).snapshotChanges().subscribe(
        info => {
          info.forEach( data => {
            this.i = this.i + 1;
            this.porcentaje = this.i / this.total * 100;
            this.afs.doc(`Inscripciones/${data.payload.doc.id}`).update({presente: false});
            a.unsubscribe();
          });
        } 
      )
      this.i = 0;
      this.porcentaje = 0;
    }

    putTodosPresentes(){
      this.i = 0;
      this.porcentaje = 0;
      const a = this.afs.collection('Inscripciones', ref => ref
      .where('id','==',this.id)).snapshotChanges().subscribe(
        info => {
          info.forEach( data => {
            this.i = this.i + 1;
            this.porcentaje = this.i / this.total * 100;
            this.afs.doc(`Inscripciones/${data.payload.doc.id}`).update({presente: true});
            a.unsubscribe();
          });
        } 
      )
      this.i = 0;
      this.porcentaje = 0;
    }
  }
