import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Club, Predio, Prueba } from '../../models/interfaces.model';
import { PruebaService } from '../../services/abm.service';
import { Observable } from 'rxjs';
import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-nueva-prueba',
  templateUrl: './nueva-prueba.component.html',
  styleUrls: ['./nueva-prueba.component.scss']
})
export class NuevaPruebaComponent implements OnInit {

  @Input() prueba: Prueba; // Recibimos una prueba por parámetro
  
  Tipos: string [] = ['Simplemente Inscribirse','Llamar por Teléfono','Comunicarse por WhatsApp','Enviar Correo Electrónico'];
  public Elegido: string;
  public clubes: Observable<Club[]>;
  predios: Observable<Predio[]>;
  predio: Predio;
  minDate = new Date();
  PhotoURL: string;
  add: boolean
  Estado: string;

  pruebaForm = this.fb.group(
    {
      fechaCreacion: [new Date()],
      ID: [''],
      id: [''],
      Club: [],
      Categoria: [''],
      Evento: [],
      Predio: [],
      Latitud: [],
      Longitud: [],
      Provincia: [''],
      Pais: [''],
      Entrenamiento: [],
      Pasaporte: [],
      Pierna: ['CQ'],
      Posicion: ['CQ'],
      Deporte: [''],
      Sexo: [''],
      Llamar: [],
      Contacto: [''],
      Telefono: [''],
      WhatsApp: [],
      Antecedentes: [],
      PhotoURL: [''],
      viaEM: [],
      Directo: [],
      limite: [0],
      Estado: ['N']
    });
  valueDeporte: string;

  constructor(
    public pruebaSvc: PruebaService,
    public fb: UntypedFormBuilder,
    private dateAdapter: NgxMatDateAdapter<Date>
  ) {
    this.dateAdapter.setLocale('es-AR');
   }

  ngOnInit() {
    this.clubes = this.pruebaSvc.getClubes();
    if (!this.prueba){
      this.add = true;
    } else {
      this.add = false;
    }
    this.iniciarForm();
  }

  async addPrueba(prueba: Prueba) {
    const FormCompleto = Boolean(prueba.Categoria && prueba.Club && prueba.Evento && prueba.Sexo && prueba.Pierna && prueba.Posicion && this.Elegido);
    if (!FormCompleto) {
      this.MensajePantallaError('Faltan Datos','Existen Errores en la carga de la información. Vuelva a cargarlo');
    } else {
        console.log(prueba.Predio);
        this.predio = (await this.pruebaSvc.buscarPredio(prueba.Predio)).data();
        console.log('PREDIO: ', this.predio);
        if (this.Elegido === 'Simplemente Inscribirse') {
          prueba.Llamar = false;
          prueba.WhatsApp = false;
          prueba.viaEM = false;
          prueba.Directo = true;
        } else if (this.Elegido === 'Llamar por Teléfono'){
          prueba.Llamar = true;
          prueba.WhatsApp = false;
          prueba.viaEM = false;
          prueba.Directo = false;
        } else if (this.Elegido === 'Comunicarse por WhatsApp'){
          prueba.Llamar = false;
          prueba.WhatsApp = true;
          prueba.viaEM = false;
          prueba.Directo = false;
        } else if (this.Elegido === 'Enviar Correo Electrónico'){
          prueba.Llamar = false;
          prueba.viaEM = true;
          prueba.WhatsApp = false;
          prueba.Directo = false;
        }
        prueba.Latitud = this.predio.Latitude;
        prueba.Longitud = this.predio.Longitude;
        prueba.Provincia = this.predio.Provincia;
        prueba.Pais = this.predio.Pais;
        if (this.pruebaForm.get('id').value === '') {
          prueba.PhotoURL = prueba.Club.photoURL;
          prueba.Club = prueba.Club.Club;
          prueba.Fecha = prueba.Evento;
          prueba.Estado = 'N';
          console.log('PRUEBA NUEVA: ', prueba);
          this.pruebaSvc.addPrueba(prueba);
        } else {
          prueba.Estado = 'E';
          prueba.Evento = prueba.Fecha;
          console.log('PRUEBA EDITADA: ', prueba);
          this.pruebaSvc.editPrueba(prueba);
        }
      }
    }

  iniciarForm() {
    // this.prueba.Evento = moment(this.prueba.Evento).toDate();
    if (this.prueba){
      const today = moment(this.prueba.Evento.toDate()).format('MM/DD/YYYY HH:mm');
      this.prueba.Fecha = new Date(today);  
    }
    this.pruebaForm.patchValue({ ...this.prueba});
  }

  async MensajePantallaError(titulo: string, texto: string){
    Swal.fire({
      title: titulo,
      text: texto,
      icon: 'error',
      showConfirmButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Aceptar',
    }).then(() => { });
  }

  get DeportesDatos() {
    if (this.pruebaForm.get('Deporte').touched && this.valueDeporte !== this.pruebaForm.get('Deporte').value){
      this.predios = this.pruebaSvc.getPredios(this.pruebaForm.get('Deporte').value);
      this.valueDeporte = this.pruebaForm.get('Deporte').value;
    } 
    return;
  }
  
}
