<div class="main-container row">
    <h3 style="text-align: center;">ALMACENAMIENTO DE ARCHIVOS DE IMAGENES A UTILIZAR</h3>
    <hr>
    <!-- <div class="input-group">
        <span class="input-group-addon">
            <h4>Sitio donde se utilizarán los archivos</h4>
            <select class="form-control" placeholder="Carpeta de Carga" [(ngModel)]="this.carpeta">
                <option value=""></option>
                <option value="Clubes">Club</option>
                <option value="Equipos">Equipo</option>
                <option value="Organizadores">Organizador</option>
            </select>
        </span>
    </div> -->
    <div class="col-md-4">
        <h4>Método de selección de archivos</h4>
        <div appNdDropFiles [archivos]="archivos" (mouseSobre)="estaSobreElemento = $event" [ngClass]="{'file-over': estaSobreElemento }" class="well drop-zone file-over">
            <h4>Deje caer aquí sus archivos</h4>
            <img src="../../../assets/images/drop-images.png">
        </div>
    </div>
    <div class="col-md-8">
        <h4>Cargar archivos</h4>
        <button (click)="cargarImagenes()" [disabled]="archivos.length === 0" class="btn btn-success">Enviar a la Base de Datos</button>
        <button (click)="limpiarArchivos()" class="btn btn-danger">Limpiar</button>

        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th>Nombre Archivo</th>
                    <th>Tamaño</th>
                    <th>Progreso</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let archivo of archivos">
                    <td [ngClass]="{ 'text-green': archivo.progreso >= 100}">{{ archivo.nombreArchivo }}</td>
                    <td>{{ archivo.archivo.size / 1024 / 1024 | number: '.2-2'}} MB</td>
                    <td>
                        <div class="progress">
                            <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': archivo.progreso + '%' }">
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>